import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const updateCodingAssignmentStatus = (cid, chid, fid) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.UPDATE_CODING_ASSIGNMENT_STATUS, {
        crs_id: cid,
        cp_id: chid,
        cdng_asgn_id: fid,
        cl: "W",
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const submitTestRunCodingAssignment = (
  cid,
  chid,
  fid,
  code,
  language_id,
  custom_input
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.SUBMIT_CODING_ASSIGNMENT_TEST, {
        crs_id: cid,
        cp_id: chid,
        cdng_asgn_id: fid,
        source_code: code,
        language_id,
        custom_input: custom_input || undefined,
        cl: "W",
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const checkTestRunCodingAssignmentSubmission = (
  cid,
  chid,
  fid,
  subm_id
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.CHECK_CODING_ASSIGNMENT_TEST, {
        crs_id: cid,
        cp_id: chid,
        cdng_asgn_id: fid,
        subm_id,
        cl: "W",
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const checkCodingAssignmentSubmission = (cid, chid, fid, subm_id) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.CHECK_CODING_ASSIGNMENT, {
        crs_id: cid,
        cp_id: chid,
        cdng_asgn_id: fid,
        subm_id,
        cl: "W",
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const submitCodingAssignment = (cid, chid, fid, code, language_id) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.SUBMIT_CODING_ASSIGNMENT, {
        crs_id: cid,
        cp_id: chid,
        cdng_asgn_id: fid,
        source_code: code,
        language_id,
        cl: "W",
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

import React, { useEffect, useState } from "react";
import "./course.scss";
import SkillTagTooltipItem from "../utils/SkillTagToolTip";
const EmojiScaleRating = (props) => {
  const [rating, setRating] = useState(-1); // Initial rating
  const [ratingLength, setRatingLength] = useState(5);
  const [answerIndex, setAnswerIndex] = useState(-1);
  const [answerSetOptionList, setAnswerSetOptionList] = useState([]);

  useEffect(() => {
    if (props && props.answerSetOptionList) {
      // setRatingLength(props.answerSetOptionList.length)
      setAnswerSetOptionList([...props.answerSetOptionList]);
    }
  }, []);

  const handleOnChange = (event) => {
    const newRating = parseFloat(event.target.value);
    setRating(Math.min(Math.max(newRating, 1), 5)); // Clamp value between 1-10
    props.handleSelectOption(
      props.ques,
      Math.min(Math.max(newRating, 1), 5),
      Math.min(Math.max(newRating, 1), 5) - 1
    );
  };

  const handleOnCick = (value) => {
    const newRating = value;
    setRating(Math.min(Math.max(newRating, 1), 5)); // Clamp value between 1-10
    props.handleSelectOption(
      props.ques,
      Math.min(Math.max(newRating, 1), 5),
      Math.min(Math.max(newRating, 1), 5) - 1
    );
  };

  const getColor = (value) => {
    const ratio = (value - 1) / 9; // Normalize value between 0-1
    return `hsl(${120 + ratio * 120}, 100%, ${50 + ratio * 50}%)`; // Generate color from red to green
  };

  const trackStyle = {
    width: "100%",
    height: 14, // Adjust track height as needed
    backgroundColor:
      "linear-gradient(90deg, #CD2335 0.03%, #F04B3F 25.03%, #F08036 55.23%, #EFB026 76.57%, #28A745 100%)", //'#ddd'
    // backgroundImage: 'url("https://cdn.pegasus.imarticus.org/feedback/BarSlider.svg")', // Replace with the actual path to your image
    backgroundImage:
      'url("https://cdn.pegasus.imarticus.org/feedback/Bar.png")', // Replace with the actual path to your image

    backgroundSize: "cover", // Ensures the image covers the entire area
    backgroundPosition: "center", // Centers the image
    backgroundRepeat: "no-repeat", // Prevents the image from repeating
  };
  const star_rating_length = Array.from({ length: ratingLength });

  const thumbStyle = {
    position: "relative",
    width: 20,
    height: 20,
    backgroundColor: getColor(rating),
    borderRadius: "50%",
    cursor: "pointer",
  };

  let image_arr = [
    {
      selected_image: "https://cdn.pegasus.imarticus.org/feedback/GroupNew.png",
      normal_image: "https://cdn.pegasus.imarticus.org/feedback/Group-5.png",
    },
    {
      selected_image: "https://cdn.pegasus.imarticus.org/feedback/Group-11.png",
      normal_image: "https://cdn.pegasus.imarticus.org/feedback/Group-6.png",
    },
    {
      selected_image: "https://cdn.pegasus.imarticus.org/feedback/Group-22.png",
      normal_image: "https://cdn.pegasus.imarticus.org/feedback/Group-7.png",
    },
    {
      selected_image: "https://cdn.pegasus.imarticus.org/feedback/Group-44.png",
      normal_image: "https://cdn.pegasus.imarticus.org/feedback/Group-9.png",
    },
    {
      selected_image: "https://cdn.pegasus.imarticus.org/feedback/Group-33.png",
      normal_image: "https://cdn.pegasus.imarticus.org/feedback/Group-8.png",
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {star_rating_length.map((_, index) => {
          return (
            <div
              style={{
                margin: "2px",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                minWidth: "60px",
              }}
            >
              <div className="rating-container">
                <img
                  src={
                    index == rating - 1
                      ? image_arr[index].normal_image
                      : image_arr[index].selected_image
                  }
                  onClick={() => handleOnCick(index + 1)}
                  alt="png"
                  className="rating-img"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <br></br>
              <div>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: 600,
                    color: index <= rating - 1 ? "#035642" : "black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleOnCick(index + 1)}
                >
                  {answerSetOptionList[index]?.opt_txt ? (
                    answerSetOptionList[index].opt_txt.length > 10 ? (
                      <SkillTagTooltipItem
                        id={"skillnamr1" + index + props.index}
                        text={
                          answerSetOptionList[index]?.opt_txt
                            ? answerSetOptionList[index].opt_txt.substring(0, 7)
                            : "-"
                        }
                        batch={
                          answerSetOptionList[index]
                            ? answerSetOptionList[index].opt_txt
                            : "-"
                        }
                        contentFeedback={props.contentFeedback}
                      />
                    ) : (
                      answerSetOptionList[index].opt_txt
                    )
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <br></br>

        <input
          type="range"
          min={1}
          max={5}
          value={rating}
          step={1}
          onChange={handleOnChange}
          style={{
            ...trackStyle,
            appearance: "none",
            cursor: "pointer",
            minWidth: "312px",
          }} // Hide default slider style
          className="custom-slider"
        />
      </div>

      {/* <div style={thumbStyle} /> */}
      {/* <span style={{ marginLeft: 10 }}>{rating}</span> */}
    </div>
  );
};

export default EmojiScaleRating;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./codingAssignment.scss";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import Cookies from "universal-cookie";
import Loader from "../utils/Loader.js";
import moment from "moment-timezone";
import { CONFIGS } from "../../constants/constants.js";
import {
  updateCodingAssignmentStatus,
  submitTestRunCodingAssignment,
  checkTestRunCodingAssignmentSubmission,
  submitCodingAssignment,
  checkCodingAssignmentSubmission,
} from "../../actions/codingAssignmentAction.js";

import { Editor } from "@monaco-editor/react";

const CodingAssignment = ({
  course,
  getIndex,
  prevFid,
  setContentLoading,
  ...props
}) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];

  const [loading, setLoading] = useState();
  const [codingAssignment, setCodingAssignment] = useState();
  const [code, setCode] = useState("");
  const [languages, setLanguages] = useState([]);
  const [publicTestCases, setPublicTestCases] = useState([]);
  const [languageSelected, setLanguageSelected] = useState();
  const [customTestRun, setCustomTestRun] = useState(false);
  const [customTestRunInput, setCustomTestRunInput] = useState("");
  const [showTestRunResponse, setShowTestRunResponse] = useState(false);
  const [testRunResponse, setTestRunResponse] = useState();
  const [showAutomaticSubmissionResponse, setShowAutomaticSubmissionResponse] =
    useState(false);
  const [automaticSubmissionResponse, setAutomaticSubmissionResponse] =
    useState();
  const [isDeadlineEnabled, setIsDeadlineEnabled] = useState(false);
  const [isDeadlineExceeded, setIsDeadlineExceeded] = useState(false);
  const [deadline, setDeadline] = useState();
  const [tz, setTz] = useState();
  const [isLocked, setIsLocked] = useState(false);
  const [evaluationType, setEvaluationType] = useState(1);
  const [isLockingEnabled, setIsLockingEnabled] = useState(false);
  const [dependencyName, setDependencyName] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState();
  const [submissionTime, setSubsmissionTime] = useState();
  const [submittedData, setSubmittedData] = useState();
  const [languageAttempted, setLanguageAttempted] = useState();
  const [submissionEvaluated, setSubmissionEvaluated] = useState();
  const [marksObtained, setMarksObtained] = useState();
  const [reviewStatus, setReviewStatus] = useState();
  const [isForcedZeroed, setIsForceZeroed] = useState();
  const [remarks, setRemarks] = useState();
  const [startSubmission, setStartSubmission] = useState(false);
  const [maxAttempts, setMaxAttempts] = useState(1);
  const [currentUserAttempts, setCurrentUserAttempts] = useState(0);
  const [publicTestCaseSelected, setPublicTestCaseSelected] = useState(0);
  const [privateTestCaseSelected, setPrivateTestCaseSelected] = useState(0);
  const [height, setHeight] = useState(350); // Initial height
  const minHeight = 300; // Minimum height
  const maxHeight = window.innerHeight * 0.75; // Maximum height (75% of the screen)

  useEffect(() => {
    start();
  }, [prevFid]);

  useEffect(() => {
    console.log("codingAssignment loaded", { codingAssignment });
    if (codingAssignment) {
      setLoading(false);
      setContentLoading(false);
    }
  }, [codingAssignment]);

  useEffect(() => {
    if (customTestRun) {
      var objDiv = document.getElementById("custom-box");
      if (objDiv) {
        objDiv.scrollIntoView();
      }
    }
    if (showTestRunResponse) {
      var objDiv = document.getElementById("testrunresponse");
      if (objDiv) {
        objDiv.scrollIntoView();
      }
    }
    if (showAutomaticSubmissionResponse) {
      var objDiv = document.getElementById("automaticresponse");
      if (objDiv) {
        objDiv.scrollIntoView();
      }
    }
  }, [customTestRun, showTestRunResponse, showAutomaticSubmissionResponse]);

  const handleMouseDown = (e) => {
    const startY = e.clientY;
    const startHeight = height;

    const onMouseMove = (e) => {
      const newHeight = Math.min(
        Math.max(startHeight + (startY - e.clientY), minHeight),
        maxHeight
      ); // Clamp height between minHeight and maxHeight
      setHeight(newHeight);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const start = async () => {
    console.log({ course, getIndex, prevFid, setContentLoading });
    if (course) {
      setContentLoading(true);
      setLoading(true);
      getIndex();

      let codingAssignment = course.cdng_assignments[fid];
      setLanguages(codingAssignment.languages);
      setLanguageSelected(codingAssignment.languages[0].name);
      setCode(codingAssignment.languages[0].default_code);
      setPublicTestCases(codingAssignment.public);
      setCodingAssignment(codingAssignment);
      setTestRunResponse();
      setShowTestRunResponse(false);
      setAutomaticSubmissionResponse();
      setShowAutomaticSubmissionResponse(false);
      setCustomTestRunInput("");
      setCustomTestRun(false);

      handleUpdateStatus(true);
    }
  };

  const handleUpdateStatus = async (scroll) => {
    const response = await updateCodingAssignmentStatus(cid, chid, fid);

    if (response.success && response.data) {
      let data = response.data;
      setIsDeadlineEnabled(data.isDeadlineEnabled);
      setIsDeadlineExceeded(data.isDeadLineExceeded);
      setDeadline(data.deadline);
      setIsLocked(data.isLocked);
      setIsLockingEnabled(data.isLockingEnabled);
      setTz(data.tz);
      setDependencyName(data.dependencyName);
      setSubmissionStatus(data.submission_status);
      setSubsmissionTime(data.submission_time);
      setSubmittedData(data.submitted_data);
      setSubmissionEvaluated(data.submission_is_evluated);
      setMarksObtained(data.marks_obtained);
      setReviewStatus(data.review_status);
      setIsForceZeroed(data.is_force_zeroed);
      setRemarks(data.remarks);
      setLanguageAttempted(data.languageAttempted);
      setMaxAttempts(data.maxAttempts);
      setCurrentUserAttempts(data.totalUserAttempts);
      setEvaluationType(data.evaluationType);

      if (data.isLocked || data.review_status || data.submission_status == 3) {
        setStartSubmission(false);
      } else {
        setStartSubmission(true);
      }
    } else {
      console.log("/o_cdng_asgn Failed !!!");
    }
    if (scroll) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      setAutomaticSubmissionResponse();
      setShowAutomaticSubmissionResponse(false);
    }
    setTestRunResponse();
    setShowTestRunResponse(false);
    setCustomTestRunInput("");
    setCustomTestRun(false);
    setLoading(false);
    setContentLoading(false);
  };

  const submitTestRun = async () => {
    setLoading(true);
    setTestRunResponse();
    setShowTestRunResponse(false);
    setAutomaticSubmissionResponse();
    setShowAutomaticSubmissionResponse(false);
    setCustomTestRunInput("");
    setCustomTestRun(false);

    const response = await submitTestRunCodingAssignment(
      cid,
      chid,
      fid,
      code,
      languages.find((lg) => lg.name == languageSelected)._id
    );

    let counter = 0;

    const interval = setInterval(async () => {
      console.log("Checking submission status");

      counter++;

      const response2 = await checkTestRunCodingAssignmentSubmission(
        cid,
        chid,
        fid,
        response.data.submission_id
      );

      if (response2.data.done || response2.data.failed) {
        if (response2.data.done) {
          setTestRunResponse(response2.data.response);
        } else {
          setTestRunResponse("Compilation failed, please try again!");
        }
        clearInterval(interval);
        setShowTestRunResponse(true);
        setLoading(false);
      }
    }, 2000);
  };

  const submitCustomTestRun = async () => {
    if (!customTestRunInput) {
      return alert("Please provide a valid Custom Input");
    }

    setLoading(true);
    setTestRunResponse();
    setShowTestRunResponse(false);
    setAutomaticSubmissionResponse();
    setShowAutomaticSubmissionResponse(false);

    const response = await submitTestRunCodingAssignment(
      cid,
      chid,
      fid,
      code,
      languages.find((lg) => lg.name == languageSelected)._id,
      customTestRunInput
    );

    let counter = 0;

    const interval = setInterval(async () => {
      console.log("Checking submission status");

      counter++;

      const response2 = await checkTestRunCodingAssignmentSubmission(
        cid,
        chid,
        fid,
        response.data.submission_id
      );

      if (response2.data.done || response2.data.failed) {
        if (response2.data.done) {
          setTestRunResponse(response2.data.response);
        } else {
          setTestRunResponse("Compilation failed, please try again!");
        }
        clearInterval(interval);
        setShowTestRunResponse(true);
        setLoading(false);
      }
    }, 2000);
  };

  const submitTest = async () => {
    setLoading(true);
    setTestRunResponse();
    setShowTestRunResponse(false);
    setAutomaticSubmissionResponse();
    setShowAutomaticSubmissionResponse(false);
    setCustomTestRunInput("");
    setCustomTestRun(false);

    const response = await submitCodingAssignment(
      cid,
      chid,
      fid,
      code,
      languages.find((lg) => lg.name == languageSelected)._id
    );

    if (response.data.submission_id) {
      let counter = 0;

      const interval = setInterval(async () => {
        console.log("Checking submission status");

        counter++;

        const response2 = await checkCodingAssignmentSubmission(
          cid,
          chid,
          fid,
          response.data.submission_id
        );

        if (response2.data.done || response2.data.failed) {
          if (response2.data.done) {
            setAutomaticSubmissionResponse(response2.data.response);
          } else {
            setAutomaticSubmissionResponse(
              "Compilation failed, please try again!"
            );
          }
          clearInterval(interval);
          setShowAutomaticSubmissionResponse(true);
          setCurrentUserAttempts(response2.data.totalUserAttempts);
          setSubmittedData({});
          handleUpdateStatus(false);
        }
      }, 2000);
    } else {
      handleUpdateStatus(false);
    }
  };

  const getEventDate = function (event_date) {
    var d = new Date(event_date);

    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }

    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();

    return (
      day +
      " " +
      date +
      " " +
      month +
      " " +
      year +
      " " +
      hr +
      ":" +
      min +
      " " +
      ampm
    );
  };

  const startDownloading = (url, id) => {
    fetch(url)
      .then((res) => res.blob())
      .then((res) => {
        console.log({ res });
        let blobUrl = URL.createObjectURL(res);
        console.log({ blobUrl });
        let linkSource = blobUrl;
        let downloadLink = document.getElementById(id);
        downloadLink.href = linkSource;
        let fileName = `Submission_${props.p_name}_${codingAssignment.nm}`;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  };

  const resubmit = () => {
    setTestRunResponse();
    setShowTestRunResponse(false);
    setCustomTestRunInput("");
    setCustomTestRun(false);
    setAutomaticSubmissionResponse();
    setShowAutomaticSubmissionResponse(false);
    setStartSubmission(true);
  };

  return (
    <div
      className="codingAssignmentScss"
      style={{ position: "relative !important" }}
    >
      <div
        className="content"
        style={{
          marginBottom:
            startSubmission && currentUserAttempts < maxAttempts
              ? `${height}px`
              : "48px",
        }}
      >
        {loading && <Loader />}
        {codingAssignment ? (
          <>
            {isLocked ? (
              <div>
                {isDeadlineExceeded ? (
                  <div>
                    <div className="as-dl-msg">
                      The Content deadline is exceeded!
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "200px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          position: "relative",
                          top: "-80px",
                        }}
                      >
                        <img
                          src="https://cdn.pegasus.imarticus.org/chapter-controller/deadlineExpired.svg"
                          alt="Deadline Expired"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#e94b35",
                        fontSize: "40px",
                        fontWeight: "800",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "-20px",
                        textAlign: "center",
                      }}
                    >
                      The assignment submission date has been expired on{" "}
                      {getEventDate(deadline)}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="as-dl-msg">The Content is locked!</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "200px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          position: "relative",
                          top: "-80px",
                        }}
                      >
                        <img
                          src="https://cdn.pegasus.imarticus.org/chapter-controller/deadlineExpired.svg"
                          alt="Deadline Expired"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#e94b35",
                        fontSize: "40px",
                        fontWeight: "800",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "-20px",
                        textAlign: "center",
                      }}
                    >
                      The assignment is locked because you haven't completed the
                      content - {dependencyName}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {submissionEvaluated && reviewStatus == 2 ? (
                  <>
                    <div className="assignmentName">My Submission</div>
                    <div className="reviewed-cont">
                      <div className="reviewed-cont-header">
                        <div className="reviewed-cont-text">Grades</div>
                        <div
                          className="reviewed-cont-status"
                          style={{ background: "#28a745" }}
                        >
                          <div>Approved</div>
                          <img src="https://webcdn.imarticus.org/demo/mdi_tick-all.svg" />
                        </div>
                      </div>
                      <div className="reviewed-cont-marks">
                        <span className="reviewed-cont-marks-text">
                          Total Received Marks :
                        </span>
                        <span className="reviewed-cont-marks-mark">
                          {marksObtained}
                        </span>
                      </div>
                      {remarks && (
                        <>
                          <div className="reviewed-cont-instrutor-remarks-header">
                            Instructor Remarks
                          </div>
                          <div className="reviewed-cont-instrutor-remark">
                            {remarks || "-"}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {isDeadlineEnabled && !submittedData && (
                      <div className="as-tt-msg">
                        Your assignment deadline is approaching. Kindly submit
                        before the deadline. Due Date : {getEventDate(deadline)}
                      </div>
                    )}
                    {submissionEvaluated && reviewStatus == 1 ? (
                      <>
                        <div className="assignmentName">My Submission</div>
                        <div className="reviewed-cont">
                          <div className="reviewed-cont-header">
                            <div className="reviewed-cont-text">Grades</div>
                            <div
                              className="reviewed-cont-status"
                              style={{ background: "rgba(220, 53, 69, 0.80)" }}
                            >
                              <div>Rejected</div>
                              <img src="https://webcdn.imarticus.org/demo/mdi_tick-all.svg" />
                            </div>
                          </div>
                          <div className="reviewed-cont-marks">
                            <span className="reviewed-cont-marks-text">
                              Total Received Marks :
                            </span>
                            <span className="reviewed-cont-marks-mark">
                              {marksObtained}
                            </span>
                          </div>
                          {remarks && (
                            <>
                              <div className="reviewed-cont-instrutor-remarks-header">
                                Instructor Remarks
                              </div>
                              <div className="reviewed-cont-instrutor-remark">
                                {remarks || "-"}
                              </div>
                            </>
                          )}
                          {!startSubmission &&
                          currentUserAttempts < maxAttempts ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className="desktop"
                            >
                              <div className="resubmit" onClick={resubmit}>
                                Re-submit
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : !isForcedZeroed &&
                      submittedData &&
                      languageAttempted &&
                      submittedData.source_code ? (
                      <>
                        <div className="assignmentName">My Submission</div>
                        <div className="submitted-cont">
                          <div className="submitted-title">
                            Latest Submission
                          </div>
                          <div className="submitted-code-time">
                            <div className="submitted-time">
                              <div style={{ marginRight: "8px" }}>
                                {languageAttempted.display_name}
                              </div>
                              {" | "}
                              <img
                                src="https://webcdn.imarticus.org/demo/formkit_date.svg"
                                alt="img-1"
                                style={{
                                  marginLeft: "8px",
                                }}
                              />
                              <div
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "8px",
                                }}
                              >
                                {moment(submissionTime).format("l")}
                              </div>
                              {" | "}
                              <img
                                src="https://webcdn.imarticus.org/demo/mingcute_time-line.svg"
                                alt="img-2"
                                style={{
                                  marginLeft: "8px",
                                }}
                              />
                              {moment(submissionTime).format("LT")}
                            </div>
                            <div>
                              <div
                                onClick={() => {
                                  startDownloading(
                                    submittedData.source_code,
                                    "download_code_button_3"
                                  );
                                }}
                                className="view-submission"
                              >
                                View Submission
                              </div>
                              <a id={`download_code_button_3`}></a>
                            </div>
                          </div>
                          <div className="submitted-data-action">
                            {submissionStatus == 3 && evaluationType == 2 ? (
                              <div className="submitted-data-action-text">
                                Your submission is under compliation, please
                                check again in sometime.
                              </div>
                            ) : (
                              <div className="submitted-data-action-text">
                                Your assignment is being reviewed by our
                                mentors. You can check the status within a day.
                              </div>
                            )}
                            {!startSubmission &&
                            currentUserAttempts < maxAttempts &&
                            ((submissionStatus == 4 && evaluationType == 2) ||
                              evaluationType == 1) ? (
                              <div
                                className="resubmit desktop"
                                onClick={resubmit}
                              >
                                Re-submit
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : isForcedZeroed ? (
                      <>
                        <div className="assignmentName">My Submission</div>
                        <div className="reviewed-cont">
                          <div className="reviewed-cont-header">
                            <div className="reviewed-cont-text">Grades</div>
                            <div
                              className="reviewed-cont-status"
                              style={{ background: "rgba(220, 53, 69, 0.80)" }}
                            >
                              <div>Rejected</div>
                              <img src="https://webcdn.imarticus.org/demo/mdi_tick-all.svg" />
                            </div>
                          </div>
                          <div className="reviewed-cont-marks">
                            <span className="reviewed-cont-marks-text">
                              Total Received Marks :
                            </span>
                            <span className="reviewed-cont-marks-mark">
                              {marksObtained}
                            </span>
                          </div>
                          {remarks && (
                            <>
                              <div className="reviewed-cont-instrutor-remarks-header">
                                Instructor Remarks
                              </div>
                              <div className="reviewed-cont-instrutor-remark">
                                {remarks || "-"}
                              </div>
                            </>
                          )}

                          {!startSubmission &&
                          currentUserAttempts < maxAttempts ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className="desktop"
                            >
                              <div className="resubmit" onClick={resubmit}>
                                Re-submit
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </>
                )}

                <div className="assignmentName">{codingAssignment.nm}</div>
                <div className="line"></div>
                <div className="body">
                  <div className="body-title">{codingAssignment.title}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: codingAssignment.body,
                    }}
                    style={{ listStyleType: "inherit" }}
                  ></div>
                </div>
                <div className="desktop">
                  <div className="instructions-cont">
                    <div className="instructions">Rules And Instructions</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: codingAssignment.instructions,
                      }}
                      style={{ listStyleType: "inherit" }}
                      className="instructions-text"
                    ></div>
                  </div>
                  <div className="sample-cont">
                    {publicTestCases.map((tc, idx) => {
                      return (
                        <>
                          <div key={idx} className="sample">
                            <div className="sample-title">
                              <span>Sample Test Case {idx + 1}</span>
                            </div>
                            <div className="sample-body">
                              {/* {tc.data && (
                                  <>
                                    <span className="sample-body-heading">
                                      Data :
                                    </span>
                                    <div
                                      style={{ marginBottom: "16px" }}
                                      className="sample-body-text"
                                    >
                                      {tc.dataText}
                                    </div>
                                  </>
                                )} */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "50%",
                                  marginRight: "16px",
                                }}
                                className="sample-body-child"
                              >
                                {" "}
                                <span className="sample-body-heading">
                                  Test Input :
                                </span>
                                <div
                                  style={{ margin: "16px 0" }}
                                  className="sample-body-text"
                                >
                                  {" "}
                                  {tc.inputText}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "50%",
                                }}
                                className="sample-body-child"
                              >
                                {" "}
                                <span className="sample-body-heading">
                                  Test Output :
                                </span>
                                <div
                                  style={{ margin: "16px 0" }}
                                  className="sample-body-text"
                                >
                                  {" "}
                                  {tc.outputText}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {startSubmission && currentUserAttempts < maxAttempts ? (
                    <>
                      {/* <div className="rule">
                        Assignment will be submitted only after all the test
                        cases are passed.
                      </div> */}
                      <div className="line"></div>
                      <div>
                        <div className="action">
                          <div className="custom-input-cont">
                            <div className="custom-input">
                              <input
                                type="checkbox"
                                checked={customTestRun}
                                onChange={() => {
                                  setTestRunResponse();
                                  setShowTestRunResponse(false);
                                  setCustomTestRunInput("");
                                  setCustomTestRun(!customTestRun);
                                  setAutomaticSubmissionResponse();
                                  setShowAutomaticSubmissionResponse(false);
                                }}
                              />
                              <div style={{ marginLeft: "16px" }}>
                                Test run against custom input
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={submitTestRun}
                              className="test-run-button"
                            >
                              Test Run
                            </button>
                            <button
                              onClick={submitTest}
                              className="submit-button"
                            >
                              Submit Assignment
                            </button>
                          </div>
                        </div>
                      </div>
                      <div id="custom-box">
                        {customTestRun && (
                          <div className="custom-input-container">
                            <div className="custom-input-header">
                              Enter Custom Input Here
                            </div>
                            <textarea
                              placeholder="Enter Custom Input Here"
                              value={customTestRunInput}
                              onChange={(e) =>
                                setCustomTestRunInput(e.target.value)
                              }
                              className="custom-input-field"
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                className={`custom-input-run ${
                                  !customTestRunInput ? "disable" : ""
                                }`}
                                onClick={() => {
                                  if (customTestRunInput) {
                                    submitCustomTestRun();
                                  }
                                }}
                              >
                                Test Run Custom Input
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                  <div id="testrunresponse">
                    {showTestRunResponse && (
                      <div className="result-cont">
                        {customTestRun ? (
                          <div>
                            {testRunResponse.result ? (
                              testRunResponse.result.stderr ||
                              testRunResponse.result.error ? (
                                <>
                                  <div>
                                    <div
                                      className={`test-cases-cont test-case-no test-case-no-failed test-case-no-selected-failed`}
                                    >
                                      Custom Test Case
                                    </div>
                                    <div className="test-case-details-cont">
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Exit Code
                                        </div>
                                        <div
                                          className={`test-case-details-cont-text test-case-no-failed`}
                                        >
                                          {testRunResponse.result.exit !=
                                          undefined
                                            ? testRunResponse.result.exit
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Status
                                        </div>
                                        <div
                                          className={`test-case-details-cont-text test-case-no-failed`}
                                        >
                                          Error
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Memory
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.result.memory !=
                                          undefined
                                            ? `${Math.round(
                                                testRunResponse.result.memory /
                                                  1024
                                              )} KB`
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Time
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.result.time !=
                                          undefined
                                            ? `${testRunResponse.result.time}s`
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="test-case-details-more-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Stderr
                                        </div>
                                        <div className="test-case-no-failed test-case-details-cont-text">
                                          {testRunResponse.result.stderr ||
                                            testRunResponse.result.error}
                                        </div>
                                      </div>
                                      <div className="test-case-details-more-cont-item">
                                        <div className="test-case-details-cont-header">
                                          StdIn
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {customTestRunInput}
                                        </div>
                                      </div>
                                      <div className="test-case-details-more-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Stdout
                                        </div>
                                        <div className="test-case-details-cont-text"></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <>
                                    <div
                                      className={`test-cases-cont test-case-no test-case-no-passed test-case-no-selected-passed`}
                                    >
                                      Custom Test Case
                                    </div>
                                    <div className="test-case-details-cont">
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Exit Code
                                        </div>
                                        <div
                                          className={`test-case-details-cont-text test-case-no-passed`}
                                        >
                                          {testRunResponse.result.exit !=
                                          undefined
                                            ? testRunResponse.result.exit
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Status
                                        </div>
                                        <div
                                          className={`test-case-details-cont-text test-case-no-passed`}
                                        >
                                          Compiled
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Memory
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.result.memory !=
                                          undefined
                                            ? `${Math.round(
                                                testRunResponse.result.memory /
                                                  1024
                                              )} KB`
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Time
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.result.time !=
                                          undefined
                                            ? `${testRunResponse.result.time}s`
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="test-case-details-more-cont">
                                      <div className="test-case-details-more-cont-item">
                                        <div className="test-case-details-cont-header">
                                          StdIn
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {customTestRunInput}
                                        </div>
                                      </div>
                                      <div className="test-case-details-more-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Stdout
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.result.stdout || ""}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </>
                              )
                            ) : (
                              <div
                                className={`test-case-details-cont-text test-case-no-failed`}
                              >
                                {testRunResponse}
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            {testRunResponse.test_cases ? (
                              <>
                                <div className="test-cases-cont">
                                  {testRunResponse.test_cases.map((dt, idx) => {
                                    return (
                                      <div
                                        key={idx}
                                        onClick={() => {
                                          setPublicTestCaseSelected(idx);
                                        }}
                                        className={`test-case-no ${
                                          publicTestCaseSelected == idx
                                            ? `test-case-no-selected ${
                                                testRunResponse.test_cases[idx]
                                                  .passed
                                                  ? "test-case-no-selected-passed"
                                                  : "test-case-no-selected-failed"
                                              }`
                                            : ""
                                        } ${
                                          testRunResponse.test_cases[idx].passed
                                            ? "test-case-no-passed"
                                            : "test-case-no-failed"
                                        }`}
                                      >
                                        Test Case #{idx + 1}
                                      </div>
                                    );
                                  })}
                                </div>

                                {testRunResponse.test_cases[
                                  publicTestCaseSelected
                                ] && (
                                  <>
                                    <div className="test-case-details-cont">
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Exit Code
                                        </div>
                                        <div
                                          className={`test-case-details-cont-text ${
                                            testRunResponse.test_cases[
                                              publicTestCaseSelected
                                            ].passed
                                              ? "test-case-no-passed"
                                              : "test-case-no-failed"
                                          }`}
                                        >
                                          {testRunResponse.test_cases[
                                            publicTestCaseSelected
                                          ].response.data.result.exit !=
                                          undefined
                                            ? testRunResponse.test_cases[
                                                publicTestCaseSelected
                                              ].response.data.result.exit
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Passed
                                        </div>
                                        <div
                                          className={`test-case-details-cont-text ${
                                            testRunResponse.test_cases[
                                              publicTestCaseSelected
                                            ].passed
                                              ? "test-case-no-passed"
                                              : "test-case-no-failed"
                                          }`}
                                        >
                                          {testRunResponse.test_cases[
                                            publicTestCaseSelected
                                          ].passed
                                            ? "True"
                                            : "False"}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Memory
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.test_cases[
                                            publicTestCaseSelected
                                          ].response.data.result.memory !=
                                          undefined
                                            ? `${Math.round(
                                                testRunResponse.test_cases[
                                                  publicTestCaseSelected
                                                ].response.data.result.memory /
                                                  1024
                                              )} KB`
                                            : "-"}{" "}
                                        </div>
                                      </div>
                                      <div className="test-case-details-cont-item">
                                        <div className="test-case-details-cont-header">
                                          Time
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {testRunResponse.test_cases[
                                            publicTestCaseSelected
                                          ].response.data.result.time !=
                                          undefined
                                            ? `${testRunResponse.test_cases[publicTestCaseSelected].response.data.result.time}s`
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="test-case-details-more-cont">
                                      {testRunResponse.test_cases[
                                        publicTestCaseSelected
                                      ].response.data.result.stderr ||
                                      testRunResponse.test_cases[
                                        publicTestCaseSelected
                                      ].response.data.result.error ? (
                                        <div className="test-case-details-more-cont-item">
                                          <div className="test-case-details-cont-header">
                                            Stderr
                                          </div>
                                          <div className="test-case-no-failed">
                                            {testRunResponse.test_cases[
                                              publicTestCaseSelected
                                            ].response.data.result.stderr ||
                                              testRunResponse.test_cases[
                                                publicTestCaseSelected
                                              ].response.data.result.error}
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="test-case-details-more-cont-item">
                                        <div className="test-case-details-cont-header">
                                          StdIn
                                        </div>
                                        <div className="test-case-details-cont-text">
                                          {
                                            testRunResponse.test_cases[
                                              publicTestCaseSelected
                                            ].stdin
                                          }
                                        </div>
                                      </div>
                                      <div className="test-case-details-more-output-cont">
                                        {!testRunResponse.test_cases[
                                          publicTestCaseSelected
                                        ].passed ? (
                                          <div
                                            style={{ paddingRight: "24px" }}
                                            className="test-case-details-more-cont-item"
                                          >
                                            <div className="test-case-details-cont-header">
                                              Expected Stdout
                                            </div>
                                            <div className="test-case-details-cont-text">
                                              {
                                                testRunResponse.test_cases[
                                                  publicTestCaseSelected
                                                ].stdout
                                              }
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="test-case-details-more-cont-item">
                                          <div className="test-case-details-cont-header">
                                            Run Stdout
                                          </div>
                                          <div className="test-case-details-cont-text">
                                            {testRunResponse.test_cases[
                                              publicTestCaseSelected
                                            ].response.data.result.stdout || ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <div
                                className={`test-case-details-cont-text test-case-no-failed`}
                              >
                                {testRunResponse}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div id="automaticresponse">
                    {showAutomaticSubmissionResponse && (
                      <div className="result-cont">
                        {automaticSubmissionResponse.test_cases ? (
                          <>
                            <div className="test-cases-cont">
                              {automaticSubmissionResponse.test_cases.map(
                                (dt, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setPrivateTestCaseSelected(idx);
                                      }}
                                      className={`test-case-no ${
                                        privateTestCaseSelected == idx
                                          ? `test-case-no-selected ${
                                              automaticSubmissionResponse
                                                .test_cases[idx].passed
                                                ? "test-case-no-selected-passed"
                                                : "test-case-no-selected-failed"
                                            }`
                                          : ""
                                      } ${
                                        automaticSubmissionResponse.test_cases[
                                          idx
                                        ].passed
                                          ? "test-case-no-passed"
                                          : "test-case-no-failed"
                                      }`}
                                    >
                                      Test Case #{idx + 1}
                                    </div>
                                  );
                                }
                              )}
                            </div>

                            {automaticSubmissionResponse.test_cases[
                              privateTestCaseSelected
                            ] && (
                              <>
                                <div className="test-case-details-cont">
                                  <div className="test-case-details-cont-item">
                                    <div className="test-case-details-cont-header">
                                      Exit Code
                                    </div>
                                    <div
                                      className={`test-case-details-cont-text ${
                                        automaticSubmissionResponse.test_cases[
                                          privateTestCaseSelected
                                        ].passed
                                          ? "test-case-no-passed"
                                          : "test-case-no-failed"
                                      }`}
                                    >
                                      {automaticSubmissionResponse.test_cases[
                                        privateTestCaseSelected
                                      ].response.data.result.exit != undefined
                                        ? automaticSubmissionResponse
                                            .test_cases[privateTestCaseSelected]
                                            .response.data.result.exit
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="test-case-details-cont-item">
                                    <div className="test-case-details-cont-header">
                                      Passed
                                    </div>
                                    <div
                                      className={`test-case-details-cont-text ${
                                        automaticSubmissionResponse.test_cases[
                                          privateTestCaseSelected
                                        ].passed
                                          ? "test-case-no-passed"
                                          : "test-case-no-failed"
                                      }`}
                                    >
                                      {automaticSubmissionResponse.test_cases[
                                        privateTestCaseSelected
                                      ].passed
                                        ? "True"
                                        : "False"}
                                    </div>
                                  </div>
                                  <div className="test-case-details-cont-item">
                                    <div className="test-case-details-cont-header">
                                      Memory
                                    </div>
                                    <div className="test-case-details-cont-text">
                                      {automaticSubmissionResponse.test_cases[
                                        privateTestCaseSelected
                                      ].response.data.result.memory != undefined
                                        ? `${Math.round(
                                            automaticSubmissionResponse
                                              .test_cases[
                                              privateTestCaseSelected
                                            ].response.data.result.memory / 1024
                                          )} KB`
                                        : "-"}{" "}
                                    </div>
                                  </div>
                                  <div className="test-case-details-cont-item">
                                    <div className="test-case-details-cont-header">
                                      Time
                                    </div>
                                    <div className="test-case-details-cont-text">
                                      {automaticSubmissionResponse.test_cases[
                                        privateTestCaseSelected
                                      ].response.data.result.time != undefined
                                        ? `${automaticSubmissionResponse.test_cases[privateTestCaseSelected].response.data.result.time}s`
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="test-case-details-cont-item">
                                    <div className="test-case-details-cont-header">
                                      Total
                                    </div>
                                    <div className="test-case-details-cont-text">
                                      {automaticSubmissionResponse.test_cases[
                                        privateTestCaseSelected
                                      ].total < 0
                                        ? 0
                                        : Math.round(
                                            automaticSubmissionResponse
                                              .test_cases[
                                              privateTestCaseSelected
                                            ].total *
                                              100 *
                                              100
                                          ) / 100}{" "}
                                      Out of 100
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            className={`test-case-details-cont-text test-case-no-failed`}
                          >
                            {automaticSubmissionResponse}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="nonDesktop desktop-alert">
                  <div className="desktop-alert-title">ATTENTION!</div>
                  <div className="desktop-alert-body">
                    You can not attempt this assignment on a mobile device.
                    Please open this assignment on a desktop for attempting and
                    solving it.
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
      <div className="desktop">
        {startSubmission && currentUserAttempts < maxAttempts ? (
          <div
            className="resizable-div"
            style={{ height, marginLeft: "250px" }}
          >
            <div className="resize-handle" onMouseDown={handleMouseDown}></div>
            <div style={{ width: "100%" }}>
              <div className="reset-lang">
                <button
                  onClick={() =>
                    setCode(
                      languages.find((lg) => lg.name == languageSelected)
                        .default_code
                    )
                  }
                  className="reset-btn"
                >
                  Reset Code
                </button>
                <select
                  value={languageSelected}
                  onChange={(e) => {
                    setLanguageSelected(e.target.value);
                    setCode(
                      languages.find((lg) => lg.name == e.target.value)
                        .default_code
                    );
                  }}
                  className="lang-change"
                >
                  {languages.map((dt) => {
                    return (
                      <option style={{ cursor: "pointer" }} value={dt.name}>
                        {dt.display_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <Editor
                  height={`${height - 70}px`}
                  language={languageSelected}
                  theme="vs-dark"
                  value={code}
                  onChange={(e) => setCode(e)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
  p_name: state.profile.p_name,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CodingAssignment)
);

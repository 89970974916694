import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const LectureModal = ({
  lectureData,
  toggleLecModal,
  setToggleLecModal,
  handleClickOnJoinSession,
}) => {
  return (
    <Modal
      isOpen={toggleLecModal}
      toggle={(e) => setToggleLecModal(false)}
      size="lg"
      centered={true}
    >
      <ModalHeader>
        <h5 style={{ display: "flex", alignItems: "center", marginBottom: 0 }}>
          <div
            style={{
              width: "16px",
              height: "16px",
              background: "#055646",
              borderRadius: "4px",
            }}
          ></div>
          &nbsp;&nbsp;{lectureData.nm}
        </h5>
      </ModalHeader>
      <ModalBody style={{ padding: "40px" }}>
        <div className="row justify-content-between">
          <div
            className="col-lg-6"
            style={{ fontSize: "16px", margin: 0, padding: 0 }}
          >
            <p>
              {lectureData.stm} - {lectureData.etm}
            </p>
          </div>
          <div
            className="col-lg-5"
            style={{ fontSize: "16px", margin: 0, padding: 0 }}
          >
            {lectureData.type == 2 ? "ONLINE" : "OFFLINE"}(
            {lectureData.className})
          </div>
        </div>
        <div
          className="row"
          style={{
            borderRadius: "4px",
            background: "rgba(5, 86, 70, 0.04)",
            padding: "12px",
          }}
        >
          <p style={{ fontSize: "16px" }}>
            Trainer : <b>{lectureData.trainerName}</b>
            <br />
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        {lectureData.type == 2 && (
          <button
            onClick={(e) => handleClickOnJoinSession(lectureData._id)}
            className="btn"
            style={{
              color: "white",
              padding: "12px 36px",
              borderRadius: "4px",
              background: "#055646",
            }}
          >
            Join Session
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};
export default LectureModal;

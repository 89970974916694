import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import axios from "axios";
import { CONFIGS } from "../../constants/constants";
import { ROUTES } from "../../constants/routes.js";
import {
  getProfileInfoOnly,
  snoozeProfilePopup,
} from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import {
  checkAgreementsStatus,
  setAttendance,
} from "../../actions/courseAction.js";
import { setBrandData } from "../../actions/brandAction.js";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import SidebarCourse from "../../images/sidebar_course.svg";
import SidebarDiscussion from "../../images/sidebar_discussion.svg";
import SidebarGradebook from "../../images/sidebar_gradebook.svg";
import SidebarAttendance from "../../images/sidebar_attendance.svg";
import SidebarCalendar from "../../images/sidebar_calendar.svg";
import SidebarCareer from "../../images/sidebar_career.svg";
import CourseDummy from "../../images/course_dummy_image.svg";
import SettingProfile from "../../images/settings_profile.svg";
import SettingApplication from "../../images/settings_applications.svg";
import SettingLogout from "../../images/settings_logout.svg";
import SettingTransaction from "../../images/settings_transaction.svg";
import Setting from "../../images/settings.svg";
import course_default from "../../images/course-default.svg";
import EmojiRating from "./EmojiRating.js";
import { StartRatingScale } from "./StartRatingScale.js";
import NumericRatingScale from "./NumericRatingScale.js";
import EmojiScaleRating from "./EmojiScaleRating.js";

import NumericScale from "./NumericScale.js";

import QualityScale from "./QualityScale.js";
import {
  atCrs,
  certStatus,
  chapStatus,
  courseContent,
  courseStatus,
  downloadCert,
  endUserQuiz,
  feedbackUserStatus,
  impartusUserLogin,
  initializeUserQuiz,
  levelContent,
  // placementRedirect,
  pluginIdentifier,
  registerUserQuizIdnt,
  testQuestion,
  testToken,
  userLogout,
  zoomSignature,
} from "../../actions/batchAction.js";

import {
  checkNSDCDetails,
  submitNSDCDetails,
} from "../../actions/courseAction.js";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import "./course.scss";
import ReactHtmlParser from "react-html-parser";
import Loader from "../utils/Loader.js";
import SupportIcon from "../../images/Support.svg";
import gradebook from "../gradebook/gradebook.js";
import NotificationDropdown from "../notifications/NotificationDropdown.js";
import { getAllNotifications } from "../../actions/primaryEmailActions.js";
import Agreement from "../agreements/agreements.js";
import BlockCertificate from "../agreements/BlockCerti.js";
import BlockCourse from "../agreements/BlockCrs.js";
import BlockPlacement from "../agreements/BlockPlacements.js";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PercentDetailTooltip from "../utils/PercentDetailTooltip.js";
import {
  AssignmentPerc,
  DocLecturePerc,
  lecturePerc,
  MMSSToDuration,
  NoSubAssignmentPerc,
  perCalc,
  QuizPerc,
  ScromPerc,
  CodingAssignmentPerc,
  CodingAssignmentPercAuto,
} from "../../constants/percentConstants.js";
import { setLoading } from "../../actions/utilAction.js";

const cookies = new Cookies();
const monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;
const Course = (props) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  console.log({ props });

  console.log({ query });
  const [courseCerti, setCourseCerti] = useState(false);
  const [plcmntEnable, setPlcmntEnable] = useState(false);
  const [submitCodingAssignmentCounter, setSubmitCodingAssignmentCounter] =
    useState(false);
  const [showDetailEmiTrack, setShowDetailEmiTrack] = useState(false);
  const [showModules, setShowModules] = useState(false);
  const [liveLectures, setLiveLectures] = useState({});
  const [crsThumbUrl, setCrsThumbUrl] = useState(
    "https://cdn.pegasus.imarticus.org/images/Mechanical.png"
  );
  const [ongoingClass, setOngoingClass] = useState([]);
  const [soonToBeLive, setSoonToBeLive] = useState([]);
  const [hideGradebook, setHideGradebook] = useState(true);
  const [showAttendance, setShowAttendance] = useState(true);
  const [isPlacementEnabled, setIsPlacementEnabled] = useState(false);
  const [learnerAccess, setLearnerAccess] = useState(false);
  const [profileCompletedPerc, setProfileCompletedPerc] = useState(0);
  const [preWaitTime, setPreWaitTime] = useState(0);
  const [endBufferTime, setEndBufferTime] = useState(0);
  const [certiEnabled, setCertiEnabled] = useState(false);
  const [certiAutoPublish, setCertiAutoPublish] = useState(false);
  const [certiPublished, setCertiPublished] = useState(false);
  const [certiGenerated, setCertiGenerated] = useState(false);
  const [crtfEligible, setCrtfEligible] = useState(false);
  const [toBeLive, setToBeLive] = useState(false);
  const [levelsArr, setLevelsArr] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [uIdntFeedback, setUIdntFeedback] = useState("");
  const [idnt_id, setIdnt_id] = useState("");
  const [srvId, setSrvId] = useState("");
  const [quizUserId, setQuizUserId] = useState("");
  const [testAid, setTestAid] = useState(null);
  const [quizToken, setQuizToken] = useState(null);
  const [userTestId, setUserTestId] = useState(null);
  const [feedbackSections, setFeedbackSections] = useState([]);
  const [fullFeedbackAttempted, setFullFeedbackAttempted] = useState(false);
  const [feedbackQuizSubmissions, setFeedbackQuizSubmissions] = useState({});
  const [lastFeedbackIndex, setLastFeedbackIndex] = useState(null);
  const [currentFeedbackQuiz, setCurrentFeedbackQuiz] = useState(null);
  const [showAttempted, setShowAttempted] = useState(false);
  const [showFeedbackLocked, setShowFeedbackLocked] = useState(false);
  const [batchObj, setBatchObj] = useState({});
  const [feedbackError, setFeedbackError] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [pendingInstallment, setPendingInstallment] = useState({});
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [brand, setBrand] = useState({});
  const [status, setStatus] = useState({});
  const [feedbackStatus, setFeedbackStatus] = useState({});
  const [latestContent, setLatestContent] = useState({});
  const [course, setCourse] = useState({});
  const [resumeChapName, setResumeChapName] = useState(null);
  const [resumeChapProg, setResumeChapProg] = useState(0);
  const [resumeChapIdx, setResumeChapIdx] = useState(0);
  const [resumeChapId, setResumeChapId] = useState(null);
  const [isResume, setIsResume] = useState(false);
  const [chapterLocking, setChapterLocking] = useState({});
  const [contentLocking, setContentLocking] = useState({});
  const [batchName, setBatchName] = useState("");
  const [profileName, setProfileName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [courseLocation, setCourseLocation] = useState("");
  const [impartusEnabled, setImpartusEnabled] = useState(false);
  const [trxnTypeEmi, setTrxnTypeEmi] = useState();
  const [hideCourseContent, setHideCourseContent] = useState();
  const [noOfVisibleChapter, setNoOfVisibleChapter] = useState();
  const [totaleEmiArr, setTotalEmiArr] = useState([]);
  const [emiTilesWidth, setEmiTilesWidth] = useState();
  const [resumeChapFid, setResumeChapFid] = useState(null);
  const [resumeType, setResumeType] = useState();
  const [feedbackGCode, setFeedbackGCode] = useState();
  const [uIdntFeedback2, setUIdntFeedback2] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [crtfFile, setCrtfFile] = useState();
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [profileDropDownVisible, setProfileDropDownVisible] = useState(false);
  const [showPopupChapter, setShowPopUpChapter] = useState(false);
  const [showPopupChapterDeadlineMap, setShowPopUpChapterDeadlineMap] =
    useState(false);
  const [chapterCollapseMap, setChapterCollapseMap] = useState({});
  const [atCrsLoaded, setAtCrsLoaded] = useState(false);
  const [statusLoaded, setStatusLoaded] = useState(false);
  const [feedbackLoaded, setFeedbackLoaded] = useState(false);
  const [subjectiveFeedbackAnswer, setSubjectiveFeedbackAnswer] = useState();
  const [feedbackAnswerMap, setFeedbackAnswerMap] = useState({});
  const [showPopupContentMap, setShowPopUpContentMap] = useState({});
  const [showPopupChapterMap, setShowPopUpChapterMap] = useState({});
  const [showPopupBatchName, setShowPopUpBatchName] = useState(false);
  const [elecId, setElecId] = useState();
  const [applyCert, setApplyCert] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const [certiGeneratedStatus, setCertiGeneratedStatus] = useState({});
  const [elecLevelIndex, setElecLevelIndex] = useState();
  const [isElec, setIsElec] = useState(false);
  const [certiElectivePublishStatus, setCertiElectivePublishStatus] = useState(
    {}
  );
  const [crtfEligibleMap, setCrtfEligibleMap] = useState({});
  const [gradebookType, setGradebookType] = useState(1);
  const [crsPgId, setCrsPgId] = useState("");
  const [levelsExist, setLevelsExist] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [snoozePopup, setSnoozePopup] = useState(false);
  const [nsdcEnabled, setNsdcEnabled] = useState(false);
  const [nsdcVerified, setNsdcVerified] = useState(false);
  const [openNSDCModal, setOpenNSDCModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [nsdcMobile, setNsdcMobile] = useState(undefined);
  const [nsdcUrl, setNsdcUrl] = useState();
  const [nsdcMobileError, setNsdcMobileError] = useState("");
  const [nsdcUrlError, setNsdcUrlError] = useState("");
  const [showAgreements, setShowAgreements] = useState(false);
  const [blockCrs, setBlockCrs] = useState(false);
  const [pendingfFeedback, setPendingFeedback] = useState(0);
  const [currentOpenFeedbackIndex, setCurrentOpenFeedbackIndex] = useState(0);

  useEffect(() => {
    setIsLoadingImage(() => {
      return true;
    });
    props.getAllNotifications();
    props.getProfileInfoOnly();
    window.FreshworksWidget?.("hide", "launcher");
    authenticateCourse(function () {});
  }, []);
  useEffect(() => {
    console.log({ hideGradebook });
    if (atCrsLoaded) {
      getLevelContent(function () {
        getCourseContent();
      });
    }
  }, [atCrsLoaded]);

  useEffect(() => {
    console.log({ props });
    console.log({
      courseLoaded,
      ongoingClass,
      pendingInstallment,
      soonToBeLive,
      feedbackStatus,
      feedback,
      feedbackOpen,
      feedbackQuizSubmissions,
      liveLectures,
    });
    if (courseLoaded) {
      getCourseStatus(async function () {
        await getCertificateStatus(function () {});
        await downloadCertificate();

        const resp1 = await checkAgreementsStatus({
          crs_id: cid,
          key: "block_crs",
        });

        if (!resp1.data?.data?.checkAgreements?.success) {
          setBlockCrs(true);
        }
      });
    }
  }, [courseLoaded]);
  useEffect(() => {
    console.log({
      brand,
      "!Object.keys(brand).length": !Object.keys(brand).length,
    });
  }, [brand]);
  const goToProfile = () => {
    window.location.href = `/dashboard/edit`;
  };

  const authenticateCourse = async function (cb) {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }

    atCrs({
      pid,
      gid,
      cid,
      levelId,
      dispatchIndividualBrand: props.dispatchIndividualBrand,
    })
      .then(function (res) {
        console.log(res);

        if (res.data.data.course.agreement_publish_status?.length) {
          const agreements = res.data.data.course.agreement_publish_status;
          agreements?.forEach((elem) => {
            if (!elem.isResponse) {
              setShowAgreements(true);
            }
          });
        }

        if (res.data?.data?.brand) {
          console.log(`brand found in at_crs`);
          setBrand(res.data?.data?.brand);
        }
        setBatchObj(() => {
          return res.data.data.course;
        });
        setLevelsArr(() => {
          return res.data.data.course.level_labels;
        });
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });
        setShowAttendance(res?.data?.data?.showAttendance);
        props.setAttendance(res?.data?.data?.showAttendance);
        setHideGradebook(() => {
          return res.data.data.hide_gradebook;
        });
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        let placementStatus = res.data.data.isPlacementEnabled
          ? res.data.data.isPlacementEnabled
          : false;
        setIsPlacementEnabled(placementStatus);
        if (placementStatus == true) {
          if (props.profile) {
            console.log("CHECKING POPUP SNOOZE");
            if (props.profile.popup_snooze + TWO_HOURS_IN_MS > Date.now()) {
              console.log("SNOOZE POPUP TRUE");
              setSnoozePopup(true);
            }
          }

          console.log({ learnerAccess: res.data.data.learnerAccess });
          setProfileCompletedPerc(res.data.data.perc_completed);
          setLearnerAccess(res.data.data.learnerAccess);
          if (
            res.data.data.perc_completed === 0 &&
            !res.data.data.courseLevelExist
          ) {
            setOpenProfileModal(true);
          }
        }
        setNsdcEnabled(res.data.data.nsdcEnabled);
        setNsdcVerified(res.data.data.nsdcVerified);
        setIsElec(res.data.data.elec_enable);
        if ("elec_certi_publish_status" in res.data.data.course) {
          setCertiElectivePublishStatus(
            res.data.data.course["elec_certi_publish_status"]
          );
        }
        if ("elec_level_index" in res.data.data.course) {
          setElecLevelIndex(res.data.data.course["elec_level_index"]);
        }
        if (res.data.data.course.nextInstallmentTime) {
          let object = {
            _id: res.data.data.course._id,
            nextInstallmentTime: res.data.data.course.nextInstallmentTime,
            installmentPlan: res.data.data.course.installmentPlan,
            installmentIndex: res.data.data.course.installmentIndex,
            name: res.data.data.course.name,
            currSymbol: res.data.data.course.currSymbol,
            crs_pg_id: res.data.data.course.crs_pg_id,
            endDate: res.data.data.course.endDate,
            "1st Line": res.data.data.course.installmentPlan["1st Line"],
            "2nd Line": res.data.data.course.installmentPlan["2nd Line"],
            showPaymentButton: res.data.data.course.showPaymentButton,
          };

          setPendingInstallment(object);
          console.log("pendingInstallment::", pendingInstallment, { object });
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }

        console.log({ abc: res.data.data });
        if (res.data.data && res.data.data.course.crs_pg_id) {
          setCrsPgId(res.data.data.course.crs_pg_id);
        }
        if (res.data.data && res.data.data.courseLevelExist) {
          setLevelsExist(res.data.data.courseLevelExist);
        }
        console.log({ hideGradebook: hideGradebook });
        setAtCrsLoaded(() => {
          return true;
        });
        cb();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleNSDCConfirm = () => {
    setIsLoading(true);
    setNsdcMobileError("");
    setNsdcUrlError("");
    checkNSDCDetails(nsdcMobile, nsdcUrl)
      .then((res) => {
        setOpenNSDCModal(false);
        setOpenConfirmModal(true);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.code && err.message) {
          setIsLoading(false);
          // alert(err.message);
          if (err.code === 204045 || err.code === 204043) {
            setNsdcMobileError(err.message);
          }
          if (err.code === 204046 || err.code === 204044) {
            setNsdcUrlError(err.message);
          }
        } else {
          setIsLoading(false);
          alert("Something went wrong. Please try again!");
        }
      });
  };

  const handleNSDCSubmit = () => {
    setIsLoading(true);
    submitNSDCDetails(nsdcMobile, nsdcUrl)
      .then((res) => {
        // window.open(joinUrl, "_self");
        setIsLoading(false);
        setOpenConfirmModal(false);
        setOpenSuccessModal(true);
      })
      .catch((err) => {
        if (err.code && err.message) {
          setIsLoading(false);
          alert(err.message);
        } else {
          setIsLoading(false);
          alert("Something went wrong. Please try again!");
        }
      });
  };

  const getLevelContent = async function (cb) {
    levelContent(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(res);
        console.log("dashboard_url", CONFIGS.DASHBOARD_BASE_URL);
        setBreadCrumbs(() => {
          let tempBreadCrumb = res.data.data.breadCrumbs;
          tempBreadCrumb.unshift({
            name: "My Courses",
            link: CONFIGS.DASHBOARD_BASE_URL,
          });
          return tempBreadCrumb;
        }); //set here on redux

        console.log("breadCrumbs::", breadCrumbs);
        // if (res.data?.data?.brand) {
        //   setBrand(res.data?.data?.brand);
        // }
        // props.dispatchIndividualBrand(res.data?.data?.brand);
        setIsLoadingImage(false);
        cb();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCourseContent = async function () {
    courseContent(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(
          res,
          ":LLllllllllllllllLLLLLLLLLLLLLLLllllllllllllllLLLLLLLLLLLLLLLLllllllllllllllLLLLLLLLLLLLLlll"
        );
        console.log("COURSE", res.data.data);
        setCourse(() => {
          return res.data.data;
        });
        console.log(course);
        setBatchName(() => {
          return res.data.data.batch_name;
        });
        setProfileName(() => {
          return res.data.data.profilename;
        });
        setProfilePic(() => {
          const pic =
            res.data.data.profilepic ||
            "https://cdn.pegasus.imarticus.org/index/user_neutral.svg";
          return pic;
        });

        setPrimaryEmail(() => {
          return res.data.data.email;
        });
        setCourseLocation(() => {
          return res.data.data.location;
        });
        setCourseCerti(() => {
          return res.data.data.crtf_enable;
        });
        setPlcmntEnable(() => {
          return res.data.data.plcmnt_enable;
        });
        setImpartusEnabled(() => {
          return res.data.data.impartus_enabled;
        });
        setPreWaitTime(() => {
          return res.data.data.pre_wait_live_class_time;
        });
        if ("end_live_buffer_class_time" in res.data.data) {
          setEndBufferTime(() => {
            return res.data.data.end_live_buffer_class_time;
          });
        }
        if (
          res.data.data.emistatus.trxn_typ_emi &&
          res.data.data.emistatus.emi_paid <
            res.data.data.emistatus.emi_needed_to_pay
        ) {
          setTrxnTypeEmi(() => {
            return res.data.data.emistatus.trxn_typ_emi;
          });
          setHideCourseContent(() => {
            return res.data.data.emistatus.hide_course_content;
          });
          if ("no_of_visible_chapters" in res.data.data) {
            setNoOfVisibleChapter(() => {
              return res.data.data.emistatus.no_of_visible_chapters;
            });
          }
        } else {
          setTrxnTypeEmi(() => {
            return false;
          });
          setHideCourseContent(() => {
            return res.data.data.emistatus.hide_course_content;
          });
          setNoOfVisibleChapter(() => {
            return res.data.data.emistatus.no_of_visible_chapters
              ? res.data.data.emistatus.no_of_visible_chapters
              : res.data.data.chapters.length;
          });
        }
        if (
          res.data.data &&
          Object.prototype.toString.call(res.data.data.livlec) ===
            "[object Object]"
        ) {
          setLiveLectures(() => {
            return {
              ...JSON.parse(JSON.stringify(res.data.data.livlec)),
            };
          });
        }
        if (
          res.data.data &&
          typeof res.data.data.crs_thumb_url === "string" &&
          res.data.data.crs_thumb_url.length
        ) {
          setCrsThumbUrl(() => {
            return res.data.data.crs_thumb_url;
          });
        }
        let tempOgClass = [];
        let tempSoonToBeLiveClass = [];
        for (var key in res.data.data.liv_lec_id_map) {
          var arOb = res.data.data.liv_lec_id_map[key];
          console.log(arOb, "arOIbj");
          if (arOb.type === 3 || arOb.type === 4) {
            continue;
          }
          var d = new Date(arOb.stm);
          var ee = arOb.etm ? new Date(arOb.etm) : null;
          if (ee) {
            var current_time = new Date().getTime();
            var start_time = d.getTime();
            var end_time = ee.getTime();
            console.log(parseInt(start_time - current_time), "time_difference");
            console.log(
              "current_time:",
              current_time,
              "start_time:",
              start_time,
              "end_time:",
              end_time,
              "end_buffer_time:",
              endBufferTime
            );
            if (
              current_time < start_time &&
              parseInt(start_time - current_time) <
                (res.data &&
                res.data.data &&
                res.data.data.pre_wait_live_class_time
                  ? res.data.data.pre_wait_live_class_time
                  : 0) &&
              parseInt(start_time - current_time) > 0
            ) {
              arOb.live_status = 1;
              arOb.to_be_live = true;
              setToBeLive(() => {
                return true;
              });
            } else if (
              current_time >
                start_time -
                  (res.data &&
                  res.data.data &&
                  res.data.data.pre_wait_live_class_time
                    ? res.data.data.pre_wait_live_class_time
                    : 0) &&
              current_time <
                end_time +
                  (res.data &&
                  res.data.data &&
                  res.data.data.end_live_buffer_class_time
                    ? res.data.data.end_live_buffer_class_time
                    : 0)
            ) {
              arOb.live_status = 2;
            } else if (
              current_time > start_time &&
              current_time >
                end_time +
                  (res.data &&
                  res.data.data &&
                  res.data.data.end_live_buffer_class_time
                    ? res.data.data.end_live_buffer_class_time
                    : 0)
            ) {
              arOb.live_status = 3;
            } else if (
              current_time < start_time &&
              current_time <
                end_time +
                  (res.data &&
                  res.data.data &&
                  res.data.data.end_live_buffer_class_time
                    ? res.data.data.end_live_buffer_class_time
                    : 0)
            ) {
              arOb.live_status = 1;
              arOb.to_be_live = false;
              setToBeLive(() => {
                return false;
              });
            }
            console.log({ status: arOb.live_status });
            var ed = ee.getDate();
            var em = monthsArr[ee.getMonth()];
            var ey = ee.getFullYear();
            arOb.lcedt = ed + " " + em + " " + ey;

            var amPm = "AM";
            var ehh = ee.getHours();
            var emin = ee.getMinutes();
            if (ehh >= 12) {
              ehh = ehh > 12 ? ehh - 12 : ehh;
              amPm = "PM";
            }
            if (ehh < 10) {
              ehh = "0" + String(ehh);
            }
            if (emin < 10) {
              emin = "0" + String(emin);
            }

            arOb.lcetm = ehh + ":" + emin + " " + amPm;
          } else {
            var tim = new Date();
            var tdiff = tim.getTime() - d.getTime();
            /**
             * live_status
             * 1 -> in future or upcoming
             * 2 -> going on
             * 3 -> was live
             */
            arOb.live_status = 1;
            arOb.tdiff = tdiff;
            if (tdiff > 0 && tdiff <= 7200000) {
              arOb.live_status = 2;
            } else if (tdiff > 7200000) {
              arOb.live_status = 3;
            }
          }

          var dd = d.getDate();
          var mm = monthsArr[d.getMonth()];
          var yy = d.getFullYear();
          arOb.lcdt = dd + " " + mm + " " + yy;

          var amPm = "AM";
          var hh = d.getHours();
          var min = d.getMinutes();
          if (hh >= 12) {
            hh = hh > 12 ? hh - 12 : hh;
            amPm = "PM";
          }
          if (hh < 10) {
            hh = "0" + String(hh);
          }
          if (min < 10) {
            min = "0" + String(min);
          }

          arOb.lctm = hh + ":" + min + " " + amPm;
          console.log(key, ":+========++++++++++++++==============||||||||", d);
          // ar[i] = arOb;
          console.log("ogClass1", arOb);
          if (arOb.live_status == 2) {
            console.log("ogClass", arOb);
            tempOgClass = [...tempOgClass, arOb];
          }
          console.log({ ongoingClass });
          if (arOb.live_status == 1 && toBeLive) {
            tempSoonToBeLiveClass = [...tempSoonToBeLiveClass, arOb];
          }
          setCourse(() => {
            var temp = res.data.data;
            temp.liv_lec_id_map[key] = JSON.parse(JSON.stringify(arOb));
            return temp;
          });
        }
        console.log({ tempOgClass, tempSoonToBeLiveClass });
        setOngoingClass((prevState) => {
          return [...prevState, ...tempOgClass];
        });
        setSoonToBeLive((prevState) => {
          return [...prevState, ...tempSoonToBeLiveClass];
        });
        // console.log($scope.soon_to_be_live,":$scope.soon_to_be_live/??????????????////////////////?????????????////////////////?????????????//");
        // console.log($scope.live_lectures,":/??????????????////////////////?????????????////////////////?????????????//");
        // console.log($scope.ongoing_class,":/??????????????////////////////?????????????////////////////?????????????//");

        if (res.data.data.emistatus.emi_needed_to_pay > 0) {
          let total_emi_arr = [];
          for (var i = 0; i < res.data.data.emistatus.emi_needed_to_pay; i++) {
            total_emi_arr.push(i);
          }
          setTotalEmiArr(() => {
            return total_emi_arr;
          });
          var screen_width = 88;
          if (Screen.width < 576) {
            screen_width = 88;
          }
          console.log(Screen.width);
          var width_val = Math.floor(
            screen_width / totaleEmiArr.length
          ).toString();
          console.log(width_val);
          setEmiTilesWidth(() => {
            return {
              width: width_val + "%",
            };
          });
          setNoOfVisibleChapter(() => {
            return res.data.data.emistatus.no_of_visible_chapters;
          });
        }
        var feedbacks = [];

        if (res.data.data.chapters) {
          res.data.data.chapters.map(function (chap) {
            if (chap.feedback && chap.feedbackObj && chap.feedback_publish) {
              var obj = chap.feedbackObj;
              obj.chap = chap;
              feedbacks.push(obj);
            }
          });
          console.log({ feedbacks: feedbacks });
          setFeedback(() => {
            return [...feedbacks];
          }); //save this on redux
          console.log({ feedbacksSet: feedback });
        }
        if (
          res.data.data.survey_feedbacks &&
          res.data.data.survey_feedbacks.length > 0
        ) {
          res.data.data.survey_feedbacks.map(function (fdbk) {
            var obj = fdbk;
            obj.chap = {};
            obj.chap.feedback_publish = true;
            obj.chap.feedback = fdbk._id;
            feedbacks.push(obj);
          });
          console.log({ feedbacks: feedbacks });
          setFeedback(() => {
            return [...feedbacks];
          }); //save this on redux
          console.log({ feedbacksSet: feedback });
        }
        console.log("hello", { courseLoaded });
        setCourseLoaded(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const getCourseStatus = async function (cb) {
    courseStatus(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(res);
        setStatus(() => {
          return res.data.data;
        }); //set course status in redux
        setFeedbackStatus(() => {
          console.log("feedbackStatus", res.data.data.feedbackStatus);
          return { ...res.data.data.feedbackStatus };
        });
        setLatestContent(() => {
          return res.data.data.latestContent;
        });
        console.log(status, ":::::::::::::::::::::::::::1");
        console.log(course, "+++++++++++++++++++++++++++2");
        setCrtfEligible(() => {
          return res.data.data.crtf_eligible;
        });
        setChapterLocking(() => {
          return res.data.data.chapterLocking;
        });
        setContentLocking(() => {
          return res.data.data.contentLocking;
        });
        setCrtfEligibleMap(res.data.data.crtf_eligibleMap);

        var chapterIds = Object.keys(res.data.data.chapterLocking);
        for (var i = 0; i < chapterIds.length; i++) {
          if (
            res.data.data.chapterLocking[chapterIds[i]].etim &&
            res.data.data.chapterLocking[chapterIds[i]].etim != ""
          ) {
            console.log(
              "etim chapter",
              res.data.data.chapterLocking[chapterIds[i]].etim
            );
            var dateObject = moment(
              parseInt(res.data.data.chapterLocking[chapterIds[i]].etim)
            );
            let formattedDate = dateObject.format("MMMM Do YYYY h:mm a");
            setChapterLocking(() => {
              let temp = { ...res.data.data.chapterLocking };
              temp[chapterIds[i]].formattedDate = formattedDate;
              return temp;
            });
          }
        }
        for (var i = 0; i < chapterIds.length; i++) {
          if (res.data.data.contentLocking[chapterIds[i]]) {
            for (
              var j = 0;
              j < res.data.data.contentLocking[chapterIds[i]].length;
              j++
            ) {
              if (
                res.data.data.contentLocking[chapterIds[i]][j].etim &&
                res.data.data.contentLocking[chapterIds[i]][j].etim != ""
              ) {
                const dateObject = moment(
                  parseInt(res.data.data.contentLocking[chapterIds[i]][j].etim)
                );
                const contentFormattedDate = dateObject.format(
                  "MMMM Do YYYY h:mm a"
                );
                setContentLocking(() => {
                  let temp = { ...res.data.data.contentLocking };
                  temp[chapterIds[i]][j].formattedDate = contentFormattedDate;
                  return temp;
                });
              }
            }
          }
        }
        var tempOngoingClass = [...ongoingClass];
        console.log("status", { tempOngoingClass, ongoingClass });
        if (Object.keys(contentLocking).length > 0) {
          for (var i = 0; i < ongoingClass.length; i++) {
            tempOngoingClass[i]["isLocked"] = false;
            for (
              var j = 0;
              j < contentLocking[ongoingClass[i].chpid].length;
              j++
            ) {
              if (
                contentLocking[ongoingClass[i].chpid][j].fid ==
                  ongoingClass[i]._id &&
                contentLocking[ongoingClass[i].chpid][j].isLocked
              ) {
                tempOngoingClass[i]["isLocked"] = true;
              }
            }
          }
          setOngoingClass((prevState) => {
            return [...tempOngoingClass];
          });

          var tempSoonToBeLive = soonToBeLive;
          for (var i = 0; i < soonToBeLive.length; i++) {
            tempSoonToBeLive[i]["isLocked"] = false;
            for (
              var j = 0;
              j < contentLocking[soonToBeLive[i].chpid].length;
              j++
            ) {
              if (
                contentLocking[soonToBeLive[i].chpid][j].fid ==
                  soonToBeLive[i]._id &&
                contentLocking[soonToBeLive[i].chpid][j].isLocked
              ) {
                tempSoonToBeLive[i]["isLocked"] = true;
              }
            }
          }
          setSoonToBeLive((prevState) => {
            return [...tempSoonToBeLive];
          });
        }
        var crprg = 0;
        var seq = null;
        console.log(course);
        for (var i = 0; i < course.chapters.length; i++) {
          var chapter = course.chapters[i];
          var chap = null;
          try {
            if (
              !isNaN(res.data.data.cperc[chapter._id]) &&
              res.data.data.cperc[chapter._id] !== null
            ) {
              if (res.data.data.cperc[chapter._id] > crprg) {
                crprg = res.data.data.cperc[chapter._id];
                chap = chapter.nm;
              }
              if (chap) {
                setResumeChapName(() => {
                  return chap;
                });
                setResumeChapProg(() => {
                  return crprg;
                });
                setResumeChapIdx(() => {
                  return i + 1;
                });
                seq = course.sequence[chapter._id];
                setResumeChapId(() => {
                  return chapter._id;
                });
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
        if (seq) {
          console.log(seq);
          var force = true;
          var force2 = true;
          var force3 = true;
          var fid = null;
          var cstatus = status.status;
          var cfid = null; //compleated fid
          console.log(seq, ":============================3", cstatus);
          for (var j = 0; j < seq.length; j++) {
            try {
              if (seq[j].fid) {
                if (cstatus[seq[j].fid]) {
                  if (
                    cstatus[seq[j].fid] &&
                    (cstatus[seq[j].fid].stts === 2 ||
                      cstatus[seq[j].fid].stts === 3)
                  ) {
                    fid = seq[j].fid;
                    setResumeType(() => {
                      return seq[j].type;
                    });
                    break;
                  } else if (
                    force === true &&
                    cstatus[seq[j].fid] &&
                    cstatus[seq[j].fid].stts === 4
                  ) {
                    setIsResume(() => {
                      return true;
                    });
                    cfid = seq[j].fid;
                  } else if (
                    force2 &&
                    cstatus[seq[j].fid] &&
                    cstatus[seq[j].fid].stts === 1
                  ) {
                    fid = seq[j].fid;
                    force2 = false;
                    setIsResume(() => {
                      return true;
                    });
                  }
                } else if (force3) {
                  fid = seq[j].fid;
                  cfid = seq[j].fid;
                  force3 = false;
                }
              }
            } catch (err) {
              console.log(err);
            }
          }
          fid = fid ? fid : cfid;
          if (fid) {
            setResumeChapFid(() => {
              return fid;
            });
          }
        }
        console.log({ feedback });
        if (feedback.length > 0) {
          setFullFeedbackAttempted(false);
          var index = 0;
          var statusMap = res.data.data.status;
          console.log({ statusMap: statusMap });
          let feedback_uncompleted = 0;
          for (var i = 0; i < feedback.length; i++) {
            var quiz = feedback[i];
            console.log({ quiz, feedback });
            var feedbackCompletionStatus = statusMap[quiz._id];
            if (
              feedbackCompletionStatus &&
              feedbackCompletionStatus.stts == 4 &&
              (feedbackCompletionStatus.cpid == quiz.chap._id ||
                quiz.is_surveyfdbk)
            ) {
              quiz["completion"] = true;
              console.log("quiz completion", { quiz });
            } else {
              if (res.data.data.feedbackStatus[quiz._id]?.publish_type == 1) {
                // Module Completion
                if (!res.data.data.feedbackStatus[quiz._id]?.isLocked) {
                  feedback_uncompleted++;
                }
              } else {
                feedback_uncompleted++;
              }
            }
          }
          setPendingFeedback(feedback_uncompleted);
          var feedbacks = feedback;
          feedbacks = feedbacks.sort(function (a, b) {
            // console.log({acom:a.completion, bcom:b.completion})
            var x = a.completion != undefined ? a.completion : false;
            var y = b.completion != undefined ? b.completion : false;
            // console.log({a:a, b:b})
            // console.log({x:x, y:y})
            // console.log(x - y)
            return x - y;
            // return Number(b.completion) - Number(a.completion)
          });
          console.log({ feedbacks: feedbacks });
          console.log({ feedbacksAfterCompleteCheck: feedbacks });
          // $scope.lastFeedbackIndex = 0
          for (var i = 0; i < feedbacks.length; i++) {
            var quiz = feedbacks[i];
            console.log({ currentQuiz: quiz });
            var flag = false;
            if (batchObj.gradebook_auto_publish) {
              flag = true;
            } else if (quiz.chap.feedback_publish) {
              flag = true;
            }
            if (!quiz["completion"] && flag) {
              var chapPerc = res.data.data.cperc[quiz.chap._id];
              // if (chapPerc != 100) {
              //   continue
              // }
              if (res.data.data.feedbackStatus[quiz._id]?.publish_type == 1) {
                // Module Completion
                if (res.data.data.feedbackStatus[quiz._id]?.isLocked) {
                  continue;
                }
              }

              if (
                res.data &&
                res.data.data &&
                res.data.data.feedbackStatus &&
                res.data.data.feedbackStatus[quiz._id] &&
                res.data.data.feedbackStatus[quiz._id]?.publish_type == 2
              ) {
                // pre schedule
                if (!res.data.data.feedbackStatus[quiz._id]?.isvisble) {
                  setFeedbackOpen(() => {
                    return true;
                  });
                }
              }
              setLastFeedbackIndex(() => {
                return i;
              });
              console.log({ lastFeedbackIndex: lastFeedbackIndex });
              setFeedbackOpen(() => {
                return true;
              });
              setCurrentFeedbackQuiz(() => {
                return feedbacks[i];
              });
              setCurrentOpenFeedbackIndex(1);
              get_plugin_identifier(quiz, gid);
              break;
            }
          }
        }
        setStatusLoaded(true);

        cb();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const get_plugin_identifier = async function (quiz, gid) {
    setStatusLoaded(false);

    console.log({ batchObj: batchObj });
    setIsLoading(() => {
      return true;
    });

    pluginIdentifier(quiz, gid, batchObj)
      .then(function (res) {
        // console.log({get_plugin_identifierres: res})
        if (res.data && res.data.success && res.data.data) {
          setUIdntFeedback(() => {
            return res.data.data.u_idnt;
          });
          setIdnt_id(res.data.data.idnt_id);
          console.log({ u_idntFeedback: uIdntFeedback });
          registerUserQuiz(quiz, res.data.data.u_idnt, res.data.data.idnt_id);
        } else {
          console.log("user doesn't have access for this quiz");
          setStatusLoaded(true);
        }
      })
      .catch(function (err) {
        console.log(err);
        // if (err.status && err.status == 498 && err.data.code == 4100) {
        //   api.getAccessToken().then(function (res) {
        //     $scope.init()
        //   })
        // }
        setStatusLoaded(true);
      });
  };

  const registerUserQuiz = async function (quiz, uidntFeedback, idntId) {
    console.log({ quiz: quiz });
    registerUserQuizIdnt(quiz, uidntFeedback, idntId).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
          setStatusLoaded(true);
        }
        console.log({ registerUserQuizdata: data });
        if (data.data.data.srv_id && data.data.data.id) {
          setSrvId(() => {
            return data.data.data.srv_id;
          });
          setQuizUserId(() => {
            return data.data.data.id;
          });
          console.log({ srv_id: srvId });
          setFeedbackGCode(() => {
            return data.data.data.gid;
          });
          initializeQuiz(
            quiz,
            uidntFeedback,
            data.data.data.srv_id,
            data.data.data.id,
            data.data.data.gid,
            idntId
          );
        }

        // deferred.resolve(data);
      },
      function (error) {
        // deferred.reject(error);
        console.log(error);
        console.log("user doesn't have access for this quiz");
        setStatusLoaded(true);
      }
    );
  };

  const initializeQuiz = async function (
    quiz,
    uidntFeedback,
    srvId,
    quizUserId,
    feedbackGCode,
    idntId
  ) {
    console.log({ quiz: quiz });
    initializeUserQuiz(
      quiz,
      uidntFeedback,
      srvId,
      quizUserId,
      feedbackGCode,
      batchObj,
      idntId
    ).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
          setStatusLoaded(true);
        }
        console.log({ initializeQuizdata: data });
        if (data.data.success && data.data.data.at)
          setUIdntFeedback2(() => {
            return data.data.data.at;
          });
        getTestToken(
          quiz,
          data.data.data.at,
          quizUserId,
          feedbackGCode,
          idntId
        );
      },
      function (error) {
        console.log(error);
        console.log("user doesn't have access for this quiz");
        setStatusLoaded(true);
      }
    );
  };

  const getTestToken = async function (
    quiz,
    uIdntFeedback2,
    quizUserId,
    feedbackGCode,
    idntId
  ) {
    console.log({ quiz: quiz });
    testToken(
      quiz,
      uIdntFeedback2,
      quizUserId,
      feedbackGCode,
      batchObj,
      idntId
    ).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
          setStatusLoaded(true);
        }
        console.log({ getTestToken: data });
        if (data.data.data.test_at) {
          setQuizToken(() => {
            return data.data.data.test_at;
          });
          console.log({ quizToken: data.data.data.test_at });
        }
        if (data.data.data.id) {
          setUserTestId(() => {
            return data.data.data.id;
          });
        }
        getTestQuestions(
          quiz,
          feedbackGCode,
          data.data.data.test_at,
          uIdntFeedback2,
          quizUserId,
          data.data.data.id,
          idntId
        );
      },
      function (error) {
        console.log(error);
        console.log("user doesn't have access for this quiz");
        setStatusLoaded(true);
      }
    );
  };

  const getTestQuestions = async function (
    quiz,
    feedbackGCode,
    quizToken,
    uIdntFeedback2,
    quizUserId,
    userTestId,
    idntId
  ) {
    console.log({ quiz: quiz });
    testQuestion(
      quiz,
      feedbackGCode,
      quizToken,
      uIdntFeedback2,
      quizUserId,
      userTestId,
      batchObj,
      idntId,
      cid
    ).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
          setStatusLoaded(true);
        }
        console.log({ getTestQuestions: data });
        if (data.data.data.sectionsFound) {
          setFeedbackSections(() => {
            return [...data.data.data.sectionsFound];
          });
          console.log({ feedbackSections: feedbackSections });
          // angular.element('#feedbacksContainer')[0].scrollTop = 0
          // console.log({
          //     scroll: angular.element('#feedbacksContainer')[0].scrollTop,
          // })
          setIsLoading(() => {
            return false;
          });

          setFeedbackLoaded(true);
          setStatusLoaded(true);
        }
        setStatusLoaded(true);
      },
      function (error) {
        console.log(error);
        console.log("user doesn't have access for this quiz");
        setStatusLoaded(true);
      }
    );
  };

  const getCertificateStatus = async function (cb) {
    certStatus(pid, gid, cid, levelId)
      .then(function (res) {
        console.log(res);
        setCertiEnabled(() => {
          return res.data.data.certiEnabled;
        });
        setCertiAutoPublish(() => {
          return res.data.data.auto_publish;
        });
        setCertiPublished(() => {
          return res.data.data.published;
        });
        setCertiGenerated(() => {
          return res.data.data.generated;
        });
        if (res.data.data.levelElecMap && res.data.data.levelElecMap[levelId]) {
          setElecId(res.data.data.levelElecMap[levelId]);
        }

        setCertiGeneratedStatus(res.data.data.certgeneratedMap);
        setCertiPublished(res.data.data.published);

        console.log({
          certiEnabled: certiEnabled,
          certiAutoPublish: certiAutoPublish,
          certiPublished: certiPublished,
          certiGenerated: certiGenerated,
          crtfEligible: crtfEligible,
        });
        cb();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  useEffect(() => {
    if (elecId && applyCert[elecId] && applyCert[elecId].status) {
      let obj = applyCert[elecId];
      console.log({ obj });
      setCrtfFile(() => {
        return obj.data.crtf_file;
      });
    }
    if (applyCert["FOUNDATION"] && applyCert["FOUNDATION"].status) {
      let obj = applyCert["FOUNDATION"];
      setCrtfFile(() => {
        return obj.data.crtf_file;
      });
    }
  }, [applyCert, elecId]);

  const downloadCertificate = async function () {
    downloadCert(pid, gid, cid, levelId, elecId)
      .then(function (res) {
        console.log(res);
        if (res.data.data) {
          let cert = res.data.data;
          setApplyCert({ ...cert });
          // if (elecId && cert[elecId] && cert[elecId].status) {
          //   let obj = cert[elecId];
          //   console.log({obj})
          //   setCrtfFile(() => {
          //     return obj.data.crtf_file;
          //   });
          // }
          // if (cert["FOUNDATION"] && cert["FOUNDATION"].status) {
          //   let obj = cert["FOUNDATION"];
          //   setCrtfFile(() => {
          //     return obj.data.crtf_file;
          //   });
          // }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const redirectToDashboard = async function () {
    window.location.replace(CONFIGS.DASHBOARD_BASE_URL);
  };

  const impartusLogin = async function (email) {
    setIsLoading(true);
    impartusUserLogin(cid)
      .then(function (response) {
        setIsLoading(true);

        const token = response.data.data.token;
        var newWin = window.open(
          "https://a.impartus.com/gateway/index.html?token=" + token,
          "_blank"
        );
        if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
          alert("Please allow browser popups to view className recordings");
        }
      })
      .catch(function (err) {
        setIsLoading(false);
        alert(err.response.data.message);
        console.log(err);
      });
  };

  const redirectTo = async function (type) {
    if (type == 1) {
      // window.location.replace($scope.my_course_url);
      // window.history.back();
      var url = CONFIGS.WEB_DASHBOARD_ENDPOINT + "view" + "?cid=" + cid;
      console.log("url:::", url);
      window.location.href = url;
    } else if (type == 2) {
      console.log(
        "URL :: ",
        CONFIGS.MY_COURSE_DISCUSSIONURL +
          pid +
          "/" +
          gid +
          "/" +
          cid +
          "?pid=" +
          pid
      );
      window.location.replace(
        CONFIGS.MY_COURSE_DISCUSSIONURL +
          pid +
          "/" +
          gid +
          "/" +
          cid +
          "/discussion?pid=" +
          pid
      );
    } else if (type == 3) {
      console.log("level_labels:::", levelsArr);
      if (levelsArr && levelsArr.length > 1) {
        window.location.replace(
          CONFIGS.WEB_DASHBOARD_ENDPOINT + "view" + "?cid=" + cid
        );
      } else {
        console.log("Only Chapters");
        return;
      }
    } else if (type == 4) {
      if (levelsArr && levelsArr.length == 1) {
        console.log("Only Chapters");
        return;
      } else {
        window.location.replace(
          CONFIGS.WEB_DASHBOARD_ENDPOINT + "view" + "?cid=" + cid
        );
      }
    }

    // return false
  };

  const hideFreshDesk = async function () {
    window.FreshworksWidget?.("hide");
  };

  const toggleProfileDropDown = async function () {
    setProfileDropDownVisible(() => {
      return !profileDropDownVisible;
    });
  };

  const logOut = async function () {
    console.log("logging out");
    userLogout()
      .then(function (res) {
        cookies.remove("at", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("secret", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("aid", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("rt", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("isLoggedIn", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("pid", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("pid", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("crs_tkn", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });

        window.open(CONFIGS.ECKOVATION_WEB_ENDPOINT, "_self");
      })
      .catch(function (err) {
        console.log(err);
        alert("Something went wrong");
      });
  };

  const convertPriceToLocalString = function (amount) {
    // console.log({amount:amount})
    return parseFloat(String(amount)).toLocaleString();
  };

  const joinZoomMeeting = async function (liv_lec_id) {
    console.log("inside joinZoomMeeting");
    console.log(liv_lec_id, " >>>> liv_lec_id");

    if (!navigator.onLine) {
      return alert("You are offline. Please check your internet connection.");
    }
    // loadZoomCss('https://source.zoom.us/1.9.5/css/bootstrap.css', function() {
    // 	loadZoomCss('https://source.zoom.us/1.9.5/css/react-select.css', function() {
    // loadScript('https://source.zoom.us/1.9.5/lib/vendor/react.min.js', function() {
    // 	loadScript('https://source.zoom.us/1.9.5/lib/vendor/react-dom.min.js', function() {
    // 		loadScript('https://source.zoom.us/1.9.5/lib/vendor/redux.min.js', function() {
    // 			loadScript('https://source.zoom.us/1.9.5/lib/vendor/redux-thunk.min.js', function() {
    // 				loadScript('https://source.zoom.us/1.9.5/lib/vendor/lodash.min.js', function() {
    // 					loadScript('https://source.zoom.us/zoom-meeting-1.9.5.min.js', function() {
    setIsLoading(true);
    zoomSignature(liv_lec_id, pid)
      .then(function (res) {
        console.log(res, "signature response");
        const authArr = res.data.data.auth.split(",");
        const meeting_number = atob(authArr[0]);
        const meeting_password = atob(authArr[1]);

        var meetingConfig = {};
        meetingConfig["signature"] = res.data.data.signature;
        meetingConfig["meetingNumber"] = meeting_number;
        meetingConfig["password"] = meeting_password;
        meetingConfig["apiKey"] = res.data.data.api_key;
        meetingConfig["userName"] = res.data.data.user_name;
        meetingConfig["userEmail"] = res.data.data.user_email;
        meetingConfig["leaveUrl"] = window.location.href;
        meetingConfig["isSupportAV"] = true;

        // meetingConfig.signature = res.result;
        // meetingConfig.apiKey = API_KEY;
        var joinUrl =
          CONFIGS.WEB_DASHBOARD_ENDPOINT +
          "zoom_meeting?" +
          serialize(meetingConfig);
        console.log(joinUrl);
        // $scope.isLoading = false
        window.open(joinUrl, "_self");

        // console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
        // ZoomMtg.preLoadWasm();
        // ZoomMtg.prepareJssdk();
        // ZoomMtg.init({
        // 	leaveUrl: $scope.dashboard_url,//window.location.href,//"https://pegasus.imarticus.org",//window.location.href,
        // 	isSupportAV: true,
        // 	success: function() {
        // 		ZoomMtg.join({
        // 			signature: signature,
        // 			meetingNumber: meeting_number,
        // 			userName: user_name,
        // 			apiKey: api_key,
        // 			userEmail: user_email,
        // 			passWord: meeting_password,
        // 			success: function(success) {
        // 			// document.getElementById("zmmtg-root").style.display = 'block !important';
        // 			console.log(success)
        // 			},
        // 			error: function(error) {
        // 			console.log(error)
        // 			}
        // 		})
        // 	}
        // })
      })
      .catch(function (err) {
        console.log(err);
        if (err.status && err.status == 498 && err.data.code == 4100) {
          authenticateCourse(function () {
            joinZoomMeeting(liv_lec_id);
          });
        } else {
          if (err.response.data && err.response.data.code == 204035) {
            alert(err.response.data.message);
          } else if (err.response.data && err.response.data.code == 204053) {
            alert(`${err.response.data.message}`);
          } else {
            alert("Something went wrong. Please try again!");
          }
        }
        setIsLoading(false);
      });
    // 					})
    // 				})
    // 			})
    // 		})
    // 	})
    // });
    // 	})
    // })
  };

  const serialize = function (obj) {
    // eslint-disable-next-line no-shadow
    var keyOrderArr = [
      "name",
      "mn",
      "email",
      "pwd",
      "role",
      "lang",
      "signature",
      "china",
    ];

    Array.intersect = function () {
      var result = new Array();
      var obj = {};
      for (var i = 0; i < arguments.length; i++) {
        for (var j = 0; j < arguments[i].length; j++) {
          var str = arguments[i][j];
          if (!obj[str]) {
            obj[str] = 1;
          } else {
            obj[str]++;
            if (obj[str] == arguments.length) {
              result.push(str);
            }
          }
        }
      }
      return result;
    };

    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        value: function (obj) {
          var newArr = this.filter(function (el) {
            return el === obj;
          });
          return newArr.length > 0;
        },
      });
    }

    var tmpInterArr = Array.intersect(keyOrderArr, Object.keys(obj));
    var sortedObj = [];
    keyOrderArr.forEach(function (key) {
      if (tmpInterArr.includes(key)) {
        sortedObj.push([key, obj[key]]);
      }
    });
    Object.keys(obj)
      .sort()
      .forEach(function (key) {
        if (!tmpInterArr.includes(key)) {
          sortedObj.push([key, obj[key]]);
        }
      });
    var tmpSortResult = (function (sortedObj) {
      var str = [];
      for (var p in sortedObj) {
        if (typeof sortedObj[p][1] !== "undefined") {
          str.push(
            encodeURIComponent(sortedObj[p][0]) +
              "=" +
              encodeURIComponent(sortedObj[p][1])
          );
        }
      }
      return str.join("&");
    })(sortedObj);
    return tmpSortResult;
  };

  // const redirectToPlacements = function () {
  //   placementRedirect(cid)
  //     .then(function (res) {
  //       if (res.data.success) {
  //         var urlToRedirect = res.data.data.urlToRedirect;
  //         window.open(urlToRedirect, "_blank");
  //       } else {
  //         window.alert("Something went wrong");
  //       }
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  const openSideNav = () => {
    document
      .getElementById("sidebar")
      .classList.add("rvm-v1-main-nav-ctnr-show");
    document
      .getElementById("rvm-v1-main-nav-sidebar-otr-ctnr")
      .classList.add("rvm-v1-main-nav-otr-ctnr");
    setTimeout(() => {
      document
        .getElementById("rvm-v1-main-nav-sidebar-otr-ctnr")
        .classList.add("rvm-v1-main-nav-otr-ctnr-show");
    }, 200);
  };

  const clsoeSideNav = () => {
    document
      .getElementById("sidebar")
      .classList.remove("rvm-v1-main-nav-ctnr-show");
    document.getElementById("content").style.paddingLeft = 0;
    document
      .getElementById("rvm-v1-main-nav-sidebar-otr-ctnr")
      .classList.remove("rvm-v1-main-nav-otr-ctnr");
    setTimeout(() => {
      document
        .getElementById("rvm-v1-main-nav-sidebar-otr-ctnr")
        .classList.remove("rvm-v1-main-nav-otr-ctnr-show");
    }, 200);
  };

  const redirectToInstallmentPayment = function () {
    console.log("redirectToInstallmentPayment");
    var payInstallmentsUrl =
      CONFIGS.PAYMENT_INSTALLMENT_URL + pendingInstallment.crs_pg_id;
    console.log({ payInstallmentsUrl: payInstallmentsUrl });
    window.open(payInstallmentsUrl);
  };

  const updateChapterStatusAndToggle = async function (chapter, chapter_no) {
    if (hideCourseContent && chapter_no > noOfVisibleChapter) {
      console.log("hidden");
    } else {
      if (
        chapterCollapseMap[chapter._id] == undefined ||
        chapterCollapseMap[chapter._id] == null
      ) {
        var tempMap = { ...chapterCollapseMap };
        tempMap[chapter._id] = true;
        setChapterCollapseMap(tempMap);
      } else {
        var tempMap = { ...chapterCollapseMap };
        tempMap[chapter._id] = !tempMap[chapter._id];
        setChapterCollapseMap(tempMap);
      }
      console.log({ chapterCollapseMap });

      chapStatus(pid, cid, chapter)
        .then(function (res) {})
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  const openFeedback = async function (quiz, chapId) {
    console.log({ quiz: quiz });

    console.log({ chapterStatus: status.cperc[chapId] });
    var chapPerc = status.cperc[chapId];
    // if (chapPerc != 100) {
    //   return ($scope.showFeedbackLocked = true)
    // }
    console.log({ quiz: quiz });
    console.log(feedbackStatus);
    if (feedbackStatus[quiz._id]?.publish_type == 1) {
      // Module Completion
      if (feedbackStatus[quiz._id]?.isLocked) {
        setShowFeedbackLocked(true);
        return showFeedbackLocked;
      }
    }

    if (feedbackStatus[quiz._id]?.publish_type == 2) {
      // pre schedule
      if (feedbackStatus[quiz._id]?.isvisble) {
        setShowFeedbackLocked(true);
        return showFeedbackLocked;
      }
    }

    console.log({ feedback });
    for (var i = 0; i < feedback.length; i++) {
      console.log({ currentQuiz1: feedback[i] });
      if (quiz._id != feedback[i]._id) continue;
      var currentQuiz = feedback[i];

      console.log({ currentSelectedQuiz: currentQuiz });
      if (!currentQuiz["completion"]) {
        setLastFeedbackIndex(i);
        console.log({ lastFeedbackIndex: lastFeedbackIndex });
        setFeedbackOpen(true);
        setCurrentFeedbackQuiz(feedback[i]);
        await get_plugin_identifier(quiz, gid);
        break;
      } else {
        setShowAttempted(true);
      }
    }
  };

  const getModuleName = function () {
    var currentFeedback = currentFeedbackQuiz;
    if (!currentFeedback) return null;
    var moduleName = currentFeedback.chap.nm;
    // console.log({moduleName: moduleName})
    return moduleName;
  };

  const checkAllFilled = async function () {
    console.log("trying to check:::");
    console.log({ feedbackSections: feedbackSections });
    var questions = [];
    var filled = true;

    for (var i = 0; i < feedbackSections.length; i++) {
      var section = feedbackSections[i];
      // console.log({section:section})
      for (var j = 0; j < section.ques.length; j++) {
        var ques = section.ques[j];
        console.log({ ques: ques });
        if (
          !ques ||
          !ques.question ||
          (ques.question.compulsory &&
            (feedbackAnswerMap[ques.question._id] == undefined ||
              feedbackAnswerMap[ques.question._id] == null))
        ) {
          filled = false;
        }
      }
    }
    // console.log({ filled: filled })
    setFullFeedbackAttempted(filled);
    console.log({ feedbackAnswerMap, filled });
    return filled;
  };

  const handleSelectOption = async function (question, ans, ansIndex) {
    let tempAnswerMap = { ...feedbackAnswerMap };

    tempAnswerMap[question._id] = ansIndex;
    setFeedbackAnswerMap(tempAnswerMap);
  };
  useEffect(() => {
    const checkDisabledState = async () => {
      const allFilled = await checkAllFilled();
      setIsDisabled(!allFilled); // Disable button if not all fields are filled
    };

    if (feedbackAnswerMap && Object.keys(feedbackAnswerMap).length === 0) {
      setIsDisabled(true);
    } else {
      checkDisabledState();
    }
  }, [feedbackAnswerMap]);

  const handleSubjective = async function (e, question) {
    let tempAnswerMap = { ...feedbackAnswerMap };
    tempAnswerMap[question._id] = e.target.value;
    console.log(tempAnswerMap[question._id]);
    setSubjectiveFeedbackAnswer(e.target.value);
    setFeedbackAnswerMap(() => {
      return { ...tempAnswerMap };
    });
  };

  const submitFeedback = async function () {
    const check = await checkAllFilled();
    if (check) {
      console.log(feedbackAnswerMap);
      console.log("trying to submitFeedback:::");
      setIsLoading(true);

      var answers = [];

      for (var i = 0; i < feedbackSections.length; i++) {
        var section = feedbackSections[i];
        console.log({ section: section });
        for (var j = 0; j < section.ques.length; j++) {
          var ques = section.ques[j];
          console.log({ ques: ques });

          if (feedbackAnswerMap[ques.question._id] != undefined) {
            var ans = { q: ques.question._id, t: 1 };
            ans["a"] = feedbackAnswerMap[ques.question._id];
            answers.push(ans);
          }
        }
      }
      console.log({ answers: answers });

      console.log({ feedbackSections: feedbackSections });
      console.log({ feedbacks: feedback });
      var testId = feedbackSections[0].testId;

      var feedbackQuiz = currentFeedbackQuiz;
      console.log({ feedbackQuiz: feedbackQuiz });

      endUserQuiz(
        answers,
        feedbackGCode,
        batchObj,
        quizToken,
        feedbackQuiz,
        uIdntFeedback2,
        quizUserId,
        userTestId,
        idnt_id
      ).then(
        function (data) {
          //$scope.isLoading = false
          if (!data.config) {
            console.log("Server error occured.");
          }
          console.log({ submitFeedback: data });
          if (data.data.success) {
            console.log("Submitted successFully");
            console.log({
              feedbackQuizSubmissions: feedbackQuizSubmissions,
            });
            feedbackQuiz["completion"] = true;
            completeFeedback(feedbackQuiz, testId);
            setFeedbackQuizSubmissions(() => {
              let obj = {};
              obj[currentFeedbackQuiz._id] = true;
              return { ...feedbackQuizSubmissions, ...obj };
            });
            setFeedbackAnswerMap({});
          }
        },
        function (error) {
          setIsLoading(false);
          console.log(error);
          console.log("user doesn't have access for this quiz");
        }
      );
    } else {
      window.alert("Fill all the answer for the feedback");
      return;
    }
  };

  const completeFeedback = async function (quiz, testId) {
    console.log("updating user feedback completion status");
    var chapter = quiz.chap;
    console.log({ chapter: chapter });

    feedbackUserStatus(quiz, cid, chapter, quizUserId, userTestId)
      .then(function (res) {
        console.log({ completeFeedback: res });
        setIsLoading(false);
      })
      .catch(function (err) {
        setFeedbackError(true);
        setIsLoading(false);
        //alert('Something went wrong')
      });
  };

  const nextFeedback = function () {
    setFeedbackLoaded(false);

    console.log(
      "checking for next feedback",
      feedbackOpen,
      feedbackLoaded,
      feedback
    );
    console.log({ lastFeedbackIndex: lastFeedbackIndex });
    var lastIndex = lastFeedbackIndex == null ? 0 : lastFeedbackIndex;
    var nextfeedback = lastIndex;

    for (var i = lastFeedbackIndex; i < feedback.length; i++) {
      console.log("index", i);
      var quiz = feedback[i];
      var flag = false;
      if (batchObj.gradebook_auto_publish) {
        flag = true;
      } else if (quiz.chap.feedback_publish) {
        flag = true;
      }
      if (!quiz["completion"] && flag) {
        var chapPerc = status.cperc[quiz.chap._id];

        if (feedbackStatus[quiz._id]?.publish_type == 1) {
          // Module Completion
          if (feedbackStatus[quiz._id]?.isLocked) {
            continue;
          }
        }
        setFullFeedbackAttempted(false);
        setLastFeedbackIndex(i);
        nextfeedback = i;
        console.log({ newFeedbackIndex: lastFeedbackIndex });
        setCurrentFeedbackQuiz({ ...quiz });
        console.log({ currentFeedbackQuiz: currentFeedbackQuiz });
        get_plugin_identifier(quiz, gid);
        setFeedbackAnswerMap({});

        console.log({
          lastIndex: lastIndex,
          lastFeedbackIndex: lastFeedbackIndex,
        });

        break;
      }
    }
    if (lastIndex == nextfeedback) {
      setFeedbackOpen(false);
      return;
    }
  };

  const nextFeedbackWithoutSubmitting = function () {
    setFeedbackLoaded(false);
    console.log(
      "checking for next feedback",
      feedbackOpen,
      feedbackLoaded,
      feedback
    );
    console.log({ lastFeedbackIndex: lastFeedbackIndex });
    var lastIndex = lastFeedbackIndex == null ? 0 : lastFeedbackIndex;
    var nextfeedback = lastIndex;

    for (var i = lastFeedbackIndex + 1; i < feedback.length; i++) {
      console.log("index", i);
      var quiz = feedback[i];
      var flag = false;
      if (batchObj.gradebook_auto_publish) {
        flag = true;
      } else if (quiz.chap.feedback_publish) {
        flag = true;
      }
      if (!quiz["completion"] && flag) {
        var chapPerc = status.cperc[quiz.chap._id];

        if (feedbackStatus[quiz._id]?.publish_type == 1) {
          // Module Completion
          if (feedbackStatus[quiz._id]?.isLocked) {
            continue;
          }
        }
        setFullFeedbackAttempted(false);
        setLastFeedbackIndex(i);
        nextfeedback = i;

        console.log({ newFeedbackIndex: lastFeedbackIndex });
        setCurrentFeedbackQuiz({ ...quiz });
        console.log({ currentFeedbackQuiz: currentFeedbackQuiz });
        setCurrentOpenFeedbackIndex(currentOpenFeedbackIndex + 1);
        get_plugin_identifier(quiz, gid);
        setFeedbackAnswerMap({});

        console.log({
          lastIndex: lastIndex,
          lastFeedbackIndex: lastFeedbackIndex,
        });

        break;
      }
    }
    if (lastIndex == nextfeedback) {
      setFeedbackOpen(false);
      setStatusLoaded(true);
      return;
    }
  };

  const handleShowPopUpContent = function (fid) {
    let tempMap = { ...showPopupContentMap };
    if (tempMap[fid] == undefined) {
      tempMap[fid] = true;
      setShowPopUpContentMap(() => {
        return { ...tempMap };
      });
    } else {
      tempMap[fid] = !tempMap[fid];
      setShowPopUpContentMap(() => {
        return { ...tempMap };
      });
    }
  };

  const handleShowPopUpChapter = function (chpid) {
    let tempMap = { ...showPopupChapterMap };
    if (tempMap[chpid] == undefined) {
      tempMap[chpid] = true;
      setShowPopUpChapterMap(() => {
        return { ...tempMap };
      });
    } else {
      tempMap[chpid] = !tempMap[chpid];
      setShowPopUpChapterMap(() => {
        return { ...tempMap };
      });
    }
  };

  const handleShowPopUpChapterDeadline = function (chpid) {
    let tempMap = { ...showPopupChapterDeadlineMap };
    if (tempMap[chpid] == undefined) {
      tempMap[chpid] = true;
      setShowPopUpChapterDeadlineMap(() => {
        return { ...tempMap };
      });
    } else {
      tempMap[chpid] = !tempMap[chpid];
      setShowPopUpChapterDeadlineMap(() => {
        return { ...tempMap };
      });
    }
  };

  const changeProfileSnooze = async () => {
    snoozeProfilePopup()
      .then(function (res) {
        console.log(res, ":response data");
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return statusLoaded ? (
    statusLoaded && (
      <div className="course-page batchPage body ">
        <div className="width-100" id="rvm-v1-main-nav-sidebar-otr-ctnr">
          <nav className="rvm-v1 rvm-v1-main-nav-ctnr" id="sidebar" sidebar>
            <div id="dismiss-mobile" sidebar-closer onClick={clsoeSideNav}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
              >
                <line
                  x1="5"
                  y1="5"
                  x2="35"
                  y2="35"
                  stroke="#fff"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                ></line>
                <line
                  x1="35"
                  y1="5"
                  x2="5"
                  y2="35"
                  stroke="#fff"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                ></line>
              </svg>
            </div>
            <div
              className="rvm-v1-sidebar-logo-ctnr center-all"
              style={{
                backgroundColor: brand && brand.whiteBackground ? "white" : "",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                onClick={redirectToDashboard}
                src={
                  brand?.pic ||
                  props.brand?.pic ||
                  "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                }
                alt="Imarticus"
              />
            </div>
            <ul className="rvm-v1-sidebar-item-ctnr">
              <a
                onClick={() => {
                  redirectTo(3);
                }}
              >
                <li className="rvm-v1-sidebar-item active center-all">
                  <div className="width-100 sidebar">
                    <img src={SidebarCourse} alt="" />
                    &nbsp;&nbsp;&nbsp;Course
                  </div>
                </li>
              </a>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirectTo(2);
                }}
              >
                <li className="rvm-v1-sidebar-item center-all">
                  <div className="width-100 sidebar">
                    <img src={SidebarDiscussion} alt="" />
                    &nbsp;&nbsp;&nbsp;Discussion
                  </div>
                </li>
              </div>
              {!hideGradebook && (
                <a
                  href={
                    CONFIGS.WEB_DASHBOARD_BASE_URL +
                    "gradebook" +
                    "/" +
                    pid +
                    "/" +
                    gid +
                    "/" +
                    cid
                  }
                  onClick={hideFreshDesk}
                >
                  <li className="rvm-v1-sidebar-item center-all">
                    <div className="width-100 sidebar">
                      <img src={SidebarGradebook} alt="" />
                      &nbsp;&nbsp;&nbsp;Gradebook
                    </div>
                  </li>
                </a>
              )}

              {showAttendance && (
                <a
                  href={
                    CONFIGS.WEB_DASHBOARD_BASE_URL +
                    "calendar" +
                    "/" +
                    "user" +
                    "/" +
                    pid +
                    "/" +
                    gid +
                    "/" +
                    cid
                  }
                  onClick={hideFreshDesk}
                >
                  <li className="rvm-v1-sidebar-item center-all">
                    <div className="width-100 sidebar">
                      <img src={SidebarCalendar} alt="" />
                      &nbsp;&nbsp;&nbsp;Calendar
                    </div>
                  </li>
                </a>
              )}
              {showAttendance && (
                <a
                  href={
                    CONFIGS.WEB_DASHBOARD_BASE_URL +
                    "attendance" +
                    "/" +
                    pid +
                    "/" +
                    gid +
                    "/" +
                    cid
                  }
                  onClick={hideFreshDesk}
                >
                  <li className="rvm-v1-sidebar-item center-all">
                    <div className="width-100 sidebar">
                      <img src={SidebarAttendance} alt="" />
                      &nbsp;&nbsp;&nbsp;Attendance
                    </div>
                  </li>
                </a>
              )}

              {isPlacementEnabled && learnerAccess && (
                <a
                  href={
                    CONFIGS.WEB_DASHBOARD_BASE_URL +
                    "placement-portal" +
                    "/" +
                    pid +
                    "/" +
                    gid +
                    "/" +
                    cid
                  }
                  onClick={hideFreshDesk}
                >
                  <li className="rvm-v1-sidebar-item center-all">
                    <div className="width-100 sidebar">
                      <img src={SidebarCareer} alt="" />
                      &nbsp;&nbsp;&nbsp;Career Services
                    </div>
                  </li>
                </a>
              )}
            </ul>
            {impartusEnabled && (
              <button className="impartus-button" onClick={impartusLogin}>
                <img
                  className="recording-icon-image"
                  src="http://cdn.pegasus.imarticus.org/index/recording_neutral.svg"
                  alt=""
                />
                Class Recordings
              </button>
            )}
            {(!brand || !Object.keys(brand).length || brand.getHelpEnabled) && (
              <div className="side-bar-freshdesk-container">
                <p className="side-bar-freshdesk-heading">Facing problems?</p>
                <button
                  className="side-bar-freshdesk-button"
                  onClick={() => {
                    showFreshDesk(
                      [batchObj],
                      phoneNo,
                      profileName,
                      primaryEmail
                    );
                  }}
                >
                  Get help
                </button>
              </div>
            )}
          </nav>
        </div>

        <div className="col-xs-12 rvm-v1" id="content">
          <div className="rvm-v1-header center-all">
            <div className="rvm-v1-header-pc-itm-ctnr">
              <div className="rvm-v1-header-pc-itm-ctnr-1">
                <span className="rvm-v1-header-brdsc-active">
                  {course.course_name}
                </span>
                <div className="profile-btns" ng-show="profileName">
                  <ul className="navbar-right-top">
                    {/* {(!brand ||
                      !Object.keys(brand).length ||
                      brand.getHelpEnabled) && (
                      <li className="nav-item header-get-help-container">
                        <button
                          className="header-get-help"
                          onClick={() => {
                            showFreshDesk(
                              [batchObj],
                              phoneNo,
                              profileName,
                              primaryEmail
                            );
                          }}
                        >
                          <img
                            src={
                              "https://cdn.pegasus.imarticus.org/index/help_neutral.svg"
                            }
                            alt=""
                          />
                          <span>Get help 1</span>
                        </button>
                      </li>
                    )} */}
                    {/* <li style={{ zIndex: 1000 }}>
                      <NotificationDropdown />
                    </li> */}
                    <li style={{ zIndex: 1 }}>
                      <NotificationDropdown />
                    </li>
                    <li
                      className="nav-item dropdown profileDropDownButton"
                      onClick={toggleProfileDropDown}
                    >
                      <button
                        className="profile-pic"
                        href="#"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={profilePic}
                          alt="user"
                          className="profile-img"
                        />
                        <span className="profile-dropdown-menu-profile-name">
                          <div className="user_name_fix">{profileName} </div>
                          <i
                            className="icon fa fa-chevron-down"
                            style={{ fontSize: "small" }}
                          ></i>
                        </span>
                      </button>

                      {profileDropDownVisible && (
                        <div className="profile-dropdown-menu">
                          {(!brand ||
                            (brand && brand.editProfileEnabled) ||
                            Object.keys(brand).length === 0) && (
                            <a
                              href={CONFIGS.DASHBOARD_URL + "/edit"}
                              className="profile-dropdown-list-item text-none"
                            >
                              <img src={SettingProfile} alt="" />
                              &nbsp;&nbsp;&nbsp;My Profile
                            </a>
                          )}
                          {(!brand || Object.keys(brand).length === 0) && (
                            <a
                              href={CONFIGS.MY_APPLICATIONS}
                              className="profile-dropdown-list-item text-none"
                            >
                              <img src={SettingApplication} alt="" />
                              &nbsp;&nbsp;&nbsp;My Applications
                            </a>
                          )}
                          {(!brand || Object.keys(brand).length === 0) && (
                            <a
                              href={CONFIGS.MY_TRANSACTIONS}
                              className="profile-dropdown-list-item text-none"
                            >
                              <img src={SettingTransaction} alt="" />
                              &nbsp;&nbsp;&nbsp;My Transactions
                            </a>
                          )}
                          {(!brand ||
                            (brand && brand.editProfileEnabled) ||
                            Object.keys(brand).length === 0) && (
                            <a
                              href={CONFIGS.DASHBOARD_URL + "/pwd_edit"}
                              className="profile-dropdown-list-item text-none"
                            >
                              <img src={Setting} alt="" />
                              &nbsp;&nbsp;&nbsp;Settings
                            </a>
                          )}
                          <span
                            className="profile-dropdown-list-item"
                            onClick={logOut}
                          >
                            <img src={SettingLogout} alt="" />
                            &nbsp;&nbsp;&nbsp;Logout
                          </span>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="width-100 center rvm-v1-header-mbl-menu-icn-ctnr">
                <img
                  className="rvm-v1-header-mbl-menu-icn"
                  src="http://cdn.pegasus.imarticus.org/homepage/homepagesidebaricon.svg"
                  onClick={openSideNav}
                  alt=""
                />
                {isLoadingImage == false && (
                  <img
                    onClick={redirectToDashboard}
                    className="rvm-v1-header-mbl-img"
                    src={
                      brand?.pic ||
                      "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
                    }
                    alt=""
                  />
                )}
                {profileName && (
                  <div className="profile-btns">
                    <ul className="navbar-right-top">
                      <li>
                        <NotificationDropdown />
                      </li>
                      {(!brand || brand.getHelpEnabled) && (
                        <li className="nav-item header-get-help-container">
                          <button
                            className="header-get-help"
                            onClick={() => {
                              showFreshDesk(
                                [batchObj],
                                phoneNo,
                                profileName,
                                primaryEmail
                              );
                            }}
                          >
                            <img
                              src={
                                "https://cdn.pegasus.imarticus.org/index/help_neutral.svg"
                              }
                            />
                            <span className="header-get-help-text">
                              Get help
                            </span>
                          </button>
                        </li>
                      )}
                      <li
                        className="nav-item dropdown profileDropDownButton"
                        onClick={toggleProfileDropDown}
                      >
                        <button
                          className="profile-pic"
                          href="#"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={profilePic}
                            alt="user"
                            className="profile-img"
                          />
                          <span className="profile-dropdown-menu-profile-name">
                            {profileName}{" "}
                            <i
                              className="icon fa fa-chevron-down"
                              style={{ fontSize: "small" }}
                            ></i>
                          </span>
                        </button>

                        {profileDropDownVisible && (
                          <div className="profile-dropdown-menu">
                            {(!brand ||
                              (brand && brand.editProfileEnabled) ||
                              Object.keys(brand).length === 0) && (
                              <a
                                href={CONFIGS.DASHBOARD_URL + "/edit"}
                                className="profile-dropdown-list-item text-none"
                              >
                                My Profile
                              </a>
                            )}
                            {(!brand || Object.keys(brand).length === 0) && (
                              <a
                                href={CONFIGS.MY_APPLICATIONS}
                                className="profile-dropdown-list-item text-none"
                              >
                                My Applications
                              </a>
                            )}
                            {(!brand || Object.keys(brand).length === 0) && (
                              <a
                                href={CONFIGS.MY_TRANSACTIONS}
                                className="profile-dropdown-list-item text-none"
                              >
                                My Transactions
                              </a>
                            )}
                            {(!brand ||
                              (brand && brand.editProfileEnabled) ||
                              Object.keys(brand).length === 0) && (
                              <a
                                href={CONFIGS.DASHBOARD_URL + "/pwd_edit"}
                                className="profile-dropdown-list-item text-none"
                              >
                                Settings
                              </a>
                            )}
                            <span
                              className="profile-dropdown-list-item"
                              onClick={logOut}
                            >
                              Logout
                            </span>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="new_main">
            <div className="breadCrumbsContainer">
              {breadCrumbs.length > 0 && (
                <div className="rvm-v1-header-brdsc-ctnr breadCrumbs-row hiddenScroll">
                  <div className="breadCrumbs">
                    {breadCrumbs &&
                      breadCrumbs.map((entry, index) => (
                        <React.Fragment key={index}>
                          {index === 0 && (
                            <a href={entry.link}>
                              <span>{entry.name}</span>
                            </a>
                          )}
                          {index === 0 && (
                            <span>
                              <i className="fa fa-chevron-right"></i>
                            </span>
                          )}
                          {index === 1 && (
                            <div
                              onClick={() => redirectTo(4)}
                              style={{ cursor: "pointer" }}
                              className={
                                index === breadCrumbs.length - 1
                                  ? "rvm-v1-header-brdsc-active"
                                  : ""
                              }
                            >
                              <span>{entry.name}</span>
                            </div>
                          )}
                          {entry._id && !entry.chap && (
                            <span>
                              <i className="fa fa-chevron-right"></i>
                            </span>
                          )}
                          {entry._id &&
                            !entry.chap &&
                            index < breadCrumbs.length - 1 && (
                              <a
                                href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}view?cid=${cid}&levelId=${entry._id}`}
                                style={{ cursor: "pointer" }}
                              >
                                <span>{entry.name}</span>
                              </a>
                            )}
                          {entry._id &&
                            !entry.chap &&
                            index === breadCrumbs.length - 1 && (
                              <a
                                className="rvm-v1-header-brdsc-active"
                                style={{ cursor: "pointer" }}
                              >
                                <span>{entry.name}</span>
                              </a>
                            )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {isPlacementEnabled && profileCompletedPerc < 100 && (
              <div className="profileComplete">
                <div className="profile-popup">
                  <div className="profile-popup-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-left profile-bar">
                      <p className="profile-popup-heading col-md-12">
                        Your Profile is incomplete
                      </p>
                      <div className="profile-main-div">
                        <div className="profile-bar-detail col-md-9 col-sm-12">
                          <p>Update your details & complete your profile</p>
                          <div
                            className="profile-bar-total"
                            style={{ height: "%", marginLeft: "4px" }}
                          >
                            <div className="profile-bar-progress col-9 col-sm-11">
                              <div
                                className="profile-popup-completed"
                                style={{ width: `${profileCompletedPerc}%` }}
                              ></div>
                            </div>
                            <div
                              className="col-3 col-sm-1"
                              style={{ marginTop: "-5px" }}
                            >
                              {profileCompletedPerc}%
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 goto-profile-btn col-sm-12">
                          <button
                            className="btn rvm-rsm-start-crs-btn"
                            onClick={goToProfile}
                          >
                            Go to My Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Object.keys(pendingInstallment).length > 0 && (
              <div
                className="installmentContainerBatch"
                style={{ marginBottom: "1rem" }}
              >
                <div className="statement">
                  {pendingInstallment["1st Line"]}
                </div>
                <p className="secondaryStatement">
                  {pendingInstallment["2nd Line"]}
                </p>
                <div className="actionRow">
                  <div
                    className="installmentRow"
                    style={{ overflowX: "scroll" }}
                  >
                    <p className="installmentHeader">Payments:</p>
                    <div className="installmentRow vertical">
                      <div
                        className="installment"
                        style={{
                          position: "relative",
                          top: "-5px",
                          width: "100%",
                        }}
                      >
                        {pendingInstallment.installmentPlan &&
                          pendingInstallment.installmentPlan.installments.map(
                            (installment, index) => (
                              <React.Fragment key={index}>
                                {pendingInstallment.installmentIndex >
                                  index && (
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/payments/done.svg"
                                    alt="right"
                                    height="14"
                                    width="18"
                                    style={{
                                      background: "white",
                                      padding: "0 2px",
                                    }}
                                  />
                                )}
                                {pendingInstallment.installmentIndex <=
                                  index && (
                                  <span
                                    style={{
                                      height: "14px",
                                      width: "14px",
                                      borderRadius: "50%",
                                      display: "inline-block",
                                      border: "1px solid black",
                                      background: "white",
                                      padding: "2px",
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                      </div>
                      <div className="installment">
                        {pendingInstallment.installmentPlan &&
                          pendingInstallment.installmentPlan.installments.map(
                            (installment, index) => (
                              <p className="amount" key={index}>
                                {pendingInstallment.currSymbol}{" "}
                                {convertPriceToLocalString(installment.amount)}
                              </p>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="installmentHeader"
                    style={{ marginLeft: "1.5rem" }}
                  >
                    Due Date:{" "}
                    <span style={{ color: "rgba(60, 72, 82, 0.7)" }}>
                      {pendingInstallment.endDate}
                    </span>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    {pendingInstallment.showPaymentButton !== false &&
                      pendingInstallment.installmentPlan && (
                        <button
                          className="pay"
                          onClick={redirectToInstallmentPayment}
                        >
                          Pay {pendingInstallment.currSymbol}{" "}
                          {convertPriceToLocalString(
                            pendingInstallment.installmentPlan.installments[
                              pendingInstallment.installmentIndex
                            ].amount
                          )}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            )}
            {ongoingClass &&
              ongoingClass.map((livLec, index) => (
                <div
                  key={index}
                  className="rvm-v1-cont-ctnr"
                  style={{ paddingTop: "10px", paddingBottom: "0px" }}
                >
                  <div className="main rvm-v1-part-2-ctnr no-padding">
                    <div className="rvm-v1-certi-ctnr no-padding">
                      <div className="rvm-v1-part-2-inr-ctnr">
                        <div
                          className="rvm-v1-part-2-info-ctnr"
                          style={{ padding: "5px 2% 2% 2%" }}
                        >
                          <div
                            className="with-100 rvm-v1-part-2-info-ttl"
                            style={{
                              color: "#828282",
                              fontWeight: "500",
                              paddingBottom: "10px",
                              fontSize: "15px",
                            }}
                          >
                            Ongoing Live Session
                          </div>
                          <div className="with-100 rvm-v1-part-2-info ad-pad row">
                            <div
                              className="col-md-2 col-sm-0 col-xs-0 col-lg-1"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/images/live_play.png"
                                alt="Live Play"
                              />
                            </div>
                            <div className="col-md-10 col-sm-10 col-xs-9 col-lg-9 mob-view-75">
                              <div>
                                {/* {livLec.nm.length > 30
                                  ? livLec.nm.substring(0, 30) 
                                  : livLec.nm} */}

                                {livLec.nm}
                              </div>
                              <div
                                style={{ color: "#828282", fontSize: "15px" }}
                              >
                                Starts at {livLec.lcdt}&nbsp;{livLec.lctm} |
                                Ends at {livLec.lcedt}&nbsp;{livLec.lcetm}
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12 col-lg-2 mob-view-100">
                              <div className="rvm-v1-part-2-need-help-ctnr">
                                <button
                                  className="btn btn-primary rvm-v1-part-2-info-btn center-all"
                                  onClick={() => joinZoomMeeting(livLec._id)}
                                  style={{ width: "100%" }}
                                >
                                  Join Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {soonToBeLive.length > 0 &&
              soonToBeLive.map((livLec, index) => (
                <div
                  key={index}
                  className="rvm-v1-cont-ctnr"
                  style={{ paddingTop: "10px", paddingBottom: "0px" }}
                >
                  <div className="main rvm-v1-part-2-ctnr no-padding">
                    <div className="rvm-v1-certi-ctnr no-padding">
                      <div className="rvm-v1-part-2-inr-ctnr">
                        <div
                          className="rvm-v1-part-2-info-ctnr"
                          style={{ padding: "5px 2% 2% 2%" }}
                        >
                          <div
                            className="with-100 rvm-v1-part-2-info-ttl"
                            style={{
                              color: "#828282",
                              fontWeight: "500",
                              paddingBottom: "10px",
                              fontSize: "15px",
                            }}
                          >
                            Soon to be Live Session
                          </div>
                          <div className="with-100 rvm-v1-part-2-info ad-pad row no-margin">
                            <div className="col-md-2 col-sm-2 col-xs-3 col-lg-1">
                              <img
                                src="https://cdn.pegasus.imarticus.org/images/live_play.png"
                                alt="Live Play"
                              />
                            </div>
                            <div className="col-md-8 col-sm-10 col-xs-9 col-lg-8">
                              <div>
                                {livLec.nm.length > 30
                                  ? livLec.nm.substring(0, 30)
                                  : livLec.nm}
                              </div>
                              <div
                                style={{ color: "#828282", fontSize: "15px" }}
                              >
                                Starts at {livLec.lcdt}&nbsp;{livLec.lctm} |
                                Ends at {livLec.lcedt}&nbsp;{livLec.lcetm}
                              </div>
                            </div>
                            <div className="col-md-2 col-sm-12 col-xs-12 col-lg-3">
                              <div className="rvm-v1-part-2-need-help-ctnr">
                                <button
                                  className="btn btn-primary rvm-v1-part-2-info-btn center-all"
                                  onClick={() => joinZoomMeeting(livLec._id)}
                                  style={{ width: "100%" }}
                                >
                                  Join Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div className="rvm-v1-cont-ctnr">
              <div className="row no-margin">
                <div
                  className="col-sm-12 rvm-v1-crs-info-ctnr"
                  style={{ padding: "0rem 0rem 24px 0rem" }}
                >
                  <div className="width-100">
                    <div className="row no-margin">
                      <div className="col-sm-12 col-md-3 col-lg-2 rvm-v1-crs-img-ctnr">
                        <div
                          className="rvm-v1-crs-img"
                          style={{
                            background: `url(${
                              course.crs_thumb_url || course_default
                            }) center center / cover`,
                          }}
                        ></div>
                      </div>
                      <div className="col-sm-12 col-md-9 col-lg-10 rvm-v1-crs-meta-ctnr">
                        <div className="batchNumber width-100">
                          {course.course_name && (
                            <div className="rvm-v1-crs-nm two-line-ellipsis">
                              {course.course_name}
                            </div>
                          )}
                          {batchName && (
                            <div
                              onMouseEnter={() => {
                                setShowPopUpBatchName(true);
                              }}
                              onMouseLeave={() => {
                                setShowPopUpBatchName(false);
                              }}
                            >
                              {showPopupBatchName && (
                                <div className="showPopupBatchName">
                                  {" "}
                                  {batchName}{" "}
                                </div>
                              )}
                              <div className="batchNumberText" container="body">
                                Batch: {batchName}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div className="with-100 rvm-crs-perc">
                          {status.perc_str}
                        </div> */}
                        <div className="width-100">
                          <div className="row no-margin rvm-rsm-inf-ctnr">
                            <div className="col-sm-12 col-xs-12 col-md-8 no-padding">
                              <div className="with-100 rvm-rsm-ttl"></div>
                              <div className="width-100 rvm-rsm-crs-nm"></div>
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-4 no-padding resume-button">
                              <div className="with-100 rvm-rsm-ttl">
                                {latestContent.msg === "" && (
                                  <div>
                                    {(latestContent.type === "2" ||
                                      latestContent.type === "10") && (
                                      <a
                                        className="btn  rvm-rsm-start-crs-btn"
                                        href={`${
                                          CONFIGS.WEB_DASHBOARD_ENDPOINT
                                        }chapter/${pid}/${gid}/${cid}/${
                                          latestContent.lvlid || "undefined"
                                        }/lecture/${latestContent.chpid}/${
                                          latestContent.fid
                                        }`}
                                      >
                                        {latestContent.isStart
                                          ? "Start"
                                          : latestContent.isResume
                                          ? "Resume"
                                          : ""}
                                      </a>
                                    )}
                                    {latestContent.type === "3" && (
                                      <a
                                        className="btn  rvm-rsm-start-crs-btn"
                                        href={`${
                                          CONFIGS.WEB_DASHBOARD_ENDPOINT
                                        }chapter/${pid}/${gid}/${cid}/${
                                          latestContent.lvlid || "undefined"
                                        }/quiz/${latestContent.chpid}/${
                                          latestContent.fid
                                        }`}
                                      >
                                        {latestContent.isStart
                                          ? "Start"
                                          : latestContent.isResume
                                          ? "Resume"
                                          : ""}
                                      </a>
                                    )}
                                    {(latestContent.type === "4" ||
                                      latestContent.type === "9") && (
                                      <a
                                        className="btn  rvm-rsm-start-crs-btn"
                                        href={`${
                                          CONFIGS.WEB_DASHBOARD_ENDPOINT
                                        }chapter/${pid}/${gid}/${cid}/${
                                          latestContent.lvlid || "undefined"
                                        }/assignment/${latestContent.chpid}/${
                                          latestContent.fid
                                        }`}
                                      >
                                        {latestContent.isStart
                                          ? "Start"
                                          : latestContent.isResume
                                          ? "Resume"
                                          : ""}
                                      </a>
                                    )}
                                    {latestContent.type === "8" && (
                                      <a
                                        className="btn  rvm-rsm-start-crs-btn"
                                        href={`${
                                          CONFIGS.WEB_DASHBOARD_ENDPOINT
                                        }chapter/${pid}/${gid}/${cid}/${
                                          latestContent.lvlid || "undefined"
                                        }/docLecture/${latestContent.chpid}/${
                                          latestContent.fid
                                        }`}
                                      >
                                        {latestContent.isStart
                                          ? "Start"
                                          : latestContent.isResume
                                          ? "Resume"
                                          : ""}
                                      </a>
                                    )}

                                    {latestContent.type === "12" && (
                                      <a
                                        className="btn  rvm-rsm-start-crs-btn"
                                        href={`${
                                          CONFIGS.WEB_DASHBOARD_ENDPOINT
                                        }chapter/${pid}/${gid}/${cid}/${
                                          latestContent.lvlid || "undefined"
                                        }/scorm/${latestContent.chpid}/${
                                          latestContent.fid
                                        }`}
                                      >
                                        {latestContent.isStart
                                          ? "Start"
                                          : latestContent.isResume
                                          ? "Resume"
                                          : ""}
                                      </a>
                                    )}

                                    {latestContent.type === "13" && (
                                      <a
                                        className="btn  rvm-rsm-start-crs-btn"
                                        href={`${
                                          CONFIGS.WEB_DASHBOARD_ENDPOINT
                                        }chapter/${pid}/${gid}/${cid}/${
                                          latestContent.lvlid || "undefined"
                                        }/coding/${latestContent.chpid}/${
                                          latestContent.fid
                                        }`}
                                      >
                                        {latestContent.isStart
                                          ? "Start"
                                          : latestContent.isResume
                                          ? "Resume"
                                          : ""}
                                      </a>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {!isNaN(status.perc) && status.perc >= 100 && (
                            <div className="row no-margin rvm-rsm-inf-ctnr">
                              <div className="col-sm-12 col-xs-12 col-md-8 no-padding">
                                <div className="with-100 rvm-rsm-ttl">
                                  Status
                                </div>
                                <div className="width-100 rvm-rsm-crs-nm">
                                  Completed
                                </div>
                              </div>
                              <div className="col-sm-12 col-xs-12 col-md-4 no-padding">
                                <div className="with-100 rvm-rsm-ttl">
                                  {/* Some stuff */}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="main grey-bg no-padding"
                    style={{ height: "auto" }}
                  >
                    <div className="row no-margin">
                      <div
                        className="chapters no-padding"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="panel-group"
                          id="accordion"
                          role="tablist"
                          aria-multiselectable="false"
                        >
                          {course &&
                            course.chapters &&
                            course.chapters.map((chapter, index) => (
                              <div className="panel panel-default" key={index}>
                                <div
                                  className={
                                    trxnTypeEmi &&
                                    hideCourseContent &&
                                    index >= noOfVisibleChapter
                                      ? "panel-heading disable-chptr-content"
                                      : "panel-heading"
                                  }
                                  onClick={() =>
                                    updateChapterStatusAndToggle(
                                      chapter,
                                      index + 1
                                    )
                                  }
                                  role="tab"
                                  style={{
                                    backgroundColor: "white",
                                    padding: "8px 24px",
                                  }}
                                >
                                  <h4 className="panel-title flex-title header_ui_fix">
                                    <div className="flex-sub-title ">
                                      {chapterLocking[chapter._id] &&
                                      (chapterLocking[chapter._id].isLocked ||
                                        chapterLocking[chapter._id]
                                          .isLockedDeadline) ? (
                                        <div
                                          className="cicularProgress"
                                          style={{
                                            marginRight: "1rem",
                                            display:
                                              chapterLocking[chapter._id]
                                                .isLocked ||
                                              chapterLocking[chapter._id]
                                                .isLockedDeadline
                                                ? "block"
                                                : "none",
                                          }}
                                          onMouseEnter={() =>
                                            handleShowPopUpChapter(chapter._id)
                                          }
                                          onMouseLeave={() =>
                                            handleShowPopUpChapter(chapter._id)
                                          }
                                        >
                                          {showPopupChapterMap[chapter._id] && (
                                            <div className="showPopupChapter">
                                              {chapterLocking[chapter._id].msg}
                                            </div>
                                          )}
                                          <img
                                            src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {(status.cperc[chapter._id] || 0) >=
                                          100 ? (
                                            <PercentDetailTooltip
                                              id={chapter._id}
                                              text={"100% Completed"}
                                              elem={
                                                <div
                                                  className="cicularProgress"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    height={"16px"}
                                                    width={"16px"}
                                                    src="https://cdn.pegasus.imarticus.org/payments/done.svg"
                                                  />
                                                </div>
                                              }
                                            />
                                          ) : (
                                            <PercentDetailTooltip
                                              id={chapter._id}
                                              text={
                                                status.cperc[chapter._id]
                                                  ? status.cperc[chapter._id] +
                                                    "% 'Partially Completed'"
                                                  : "0% 'Not Started'"
                                              }
                                              elem={
                                                <div
                                                  className="cicularProgress"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  <CircularProgressbar
                                                    value={
                                                      status.cperc[
                                                        chapter._id
                                                      ] || 0
                                                    }
                                                    strokeWidth={22}
                                                    styles={{
                                                      path: {
                                                        stroke:
                                                          "rgba(3, 86, 66,1)",
                                                      },
                                                      trail: {
                                                        stroke:
                                                          "rgba(3, 86, 66, 0.2)",
                                                      },
                                                    }}
                                                  />{" "}
                                                </div>
                                              }
                                            />
                                          )}
                                        </>
                                      )}

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flexStart",
                                          alignItems: "flexStart",
                                          gap: "8px",
                                        }}
                                      >
                                        <span>{index + 1}.</span>

                                        <span>{chapter.nm}</span>
                                      </div>
                                    </div>

                                    <div
                                      className="chapterDeadlineMarking"
                                      style={{
                                        display:
                                          !trxnTypeEmi ||
                                          index < noOfVisibleChapter
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {/* Completed */}
                                      {chapterLocking[chapter._id] &&
                                        chapterLocking[chapter._id]
                                          .formattedDate && (
                                          <span
                                            onMouseEnter={() =>
                                              handleShowPopUpChapterDeadline(
                                                chapter._id
                                              )
                                            }
                                            onMouseLeave={() =>
                                              handleShowPopUpChapterDeadline(
                                                chapter._id
                                              )
                                            }
                                            className={
                                              chapterLocking[chapter._id]
                                                .isLockedDeadline != true
                                                ? "deadlineTagChapter"
                                                : "deadlineTagExpiredChapter"
                                            }
                                          >
                                            <div
                                              className="showPopupChapterDeadline"
                                              style={{
                                                display:
                                                  showPopupChapterDeadlineMap[
                                                    chapter._id
                                                  ]
                                                    ? "block"
                                                    : "none",
                                              }}
                                            >
                                              {chapterLocking[chapter._id]
                                                .isLockedDeadline != true
                                                ? "Please complete this chapter before the deadline exceeds. Ignore if already done."
                                                : "Locked, since the deadline for this chapter is expired."}
                                            </div>
                                            Deadline expires at{" "}
                                            {
                                              chapterLocking[chapter._id]
                                                .formattedDate
                                            }
                                          </span>
                                        )}

                                      {chapterCollapseMap[chapter._id] &&
                                        ((chapterLocking[chapter._id] &&
                                          !chapterLocking[chapter._id]
                                            .isLocked &&
                                          !chapterLocking[chapter._id]
                                            .isLockedDeadline) ||
                                          !chapterLocking[chapter._id]) && (
                                          // <div className="rvm-v1-chap-icn-ctnr">
                                          //   <i
                                          //     className="rvm-v1-chap-icn fa fa-minus"
                                          //     style={{
                                          //       fontSize: "small",
                                          //       opacity: "75%",
                                          //     }}
                                          //   ></i>
                                          // </div>
                                          <img
                                            src="http://cdn.pegasus.imarticus.org/courses/images/minus.svg"
                                            alt=""
                                          />
                                        )}

                                      {!chapterCollapseMap[chapter._id] &&
                                        ((chapterLocking[chapter._id] &&
                                          !chapterLocking[chapter._id]
                                            .isLocked &&
                                          !chapterLocking[chapter._id]
                                            .isLockedDeadline) ||
                                          !chapterLocking[chapter._id]) && (
                                          // <div className="rvm-v1-chap-icn-ctnr">
                                          //   <i
                                          //     className="rvm-v1-chap-icn fa fa-plus"
                                          //     style={{
                                          //       fontSize: "small",
                                          //       opacity: "75%",
                                          //     }}
                                          //   ></i>
                                          // </div>
                                          <img
                                            src="http://cdn.pegasus.imarticus.org/courses/images/plus.svg"
                                            alt=""
                                          />
                                        )}
                                    </div>
                                  </h4>
                                  <p
                                    style={{
                                      paddingLeft: "1.5rem",

                                      width: "90%",
                                    }}
                                  >
                                    {chapter.lec.length > 0 && (
                                      <span>
                                        {chapter.lec.length} Lecture
                                        {chapter.lec.length > 1 ? "s" : ""}
                                        {chapter.scorm.length > 0 ||
                                        chapter.asgn.length > 0 ||
                                        chapter?.cdng_asgn?.length > 0 ||
                                        chapter.quiz.length > 0 ||
                                        liveLectures[chapter._id]?.length > 0 ||
                                        chapter.doc_lec.length > 0 ||
                                        (chapter.feedbackObj &&
                                          chapter.feedback &&
                                          feedbackStatus[chapter.feedback] &&
                                          feedbackStatus[chapter.feedback]
                                            .isvisble &&
                                          chapter.feedback_publish &&
                                          (batchObj.gradebook_auto_publish ||
                                            chapter.feedback_publish))
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}

                                    {chapter.quiz.length > 0 && (
                                      <span>
                                        {chapter.quiz.length} Quiz
                                        {chapter.quiz.length > 1 ? "zes" : ""}
                                        {chapter.scorm.length > 0 ||
                                        chapter.asgn.length > 0 ||
                                        chapter?.cdng_asgn?.length > 0 ||
                                        liveLectures[chapter._id]?.length > 0 ||
                                        chapter.doc_lec.length > 0 ||
                                        (chapter.feedbackObj &&
                                          chapter.feedback &&
                                          feedbackStatus[chapter.feedback] &&
                                          feedbackStatus[chapter.feedback]
                                            .isvisble &&
                                          chapter.feedback_publish &&
                                          (batchObj.gradebook_auto_publish ||
                                            chapter.feedback_publish))
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}

                                    {chapter.asgn.length > 0 && (
                                      <span>
                                        {chapter.asgn.length} Assignment
                                        {chapter.asgn.length > 1 ? "s" : ""}
                                        {chapter?.cdng_asgn?.length > 0 ||
                                        chapter.scorm.length > 0 ||
                                        liveLectures[chapter._id]?.length > 0 ||
                                        chapter.doc_lec.length > 0 ||
                                        (chapter.feedbackObj &&
                                          chapter.feedback &&
                                          feedbackStatus[chapter.feedback] &&
                                          feedbackStatus[chapter.feedback]
                                            .isvisble &&
                                          chapter.feedback_publish &&
                                          (batchObj.gradebook_auto_publish ||
                                            chapter.feedback_publish))
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}

                                    {chapter?.cdng_asgn?.length > 0 && (
                                      <span>
                                        {chapter?.cdng_asgn?.length} Coding
                                        Assignment
                                        {chapter?.cdng_asgn?.length > 1
                                          ? "s"
                                          : ""}
                                        {chapter.scorm.length > 0 ||
                                        liveLectures[chapter._id]?.length > 0 ||
                                        chapter.doc_lec.length > 0 ||
                                        (chapter.feedbackObj &&
                                          chapter.feedback &&
                                          feedbackStatus[chapter.feedback] &&
                                          feedbackStatus[chapter.feedback]
                                            .isvisble &&
                                          chapter.feedback_publish &&
                                          (batchObj.gradebook_auto_publish ||
                                            chapter.feedback_publish))
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}
                                    {chapter.scorm.length > 0 && (
                                      <span>
                                        {chapter.scorm.length} SCORM Module
                                        {chapter.scorm.length > 1 ? "s" : ""}
                                        {liveLectures[chapter._id]?.length >
                                          0 ||
                                        chapter.doc_lec.length > 0 ||
                                        (chapter.feedbackObj &&
                                          chapter.feedback &&
                                          feedbackStatus[chapter.feedback] &&
                                          feedbackStatus[chapter.feedback]
                                            .isvisble &&
                                          chapter.feedback_publish &&
                                          (batchObj.gradebook_auto_publish ||
                                            chapter.feedback_publish))
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}
                                    {liveLectures[chapter._id]?.length > 0 && (
                                      <span>
                                        {liveLectures[chapter._id].length} Live
                                        Lecture
                                        {liveLectures[chapter._id]?.length > 1
                                          ? "s"
                                          : ""}
                                        {chapter.doc_lec.length > 0 ||
                                        (chapter.feedbackObj &&
                                          chapter.feedback &&
                                          feedbackStatus[chapter.feedback] &&
                                          feedbackStatus[chapter.feedback]
                                            .isvisble &&
                                          chapter.feedback_publish &&
                                          (batchObj.gradebook_auto_publish ||
                                            chapter.feedback_publish))
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}
                                    {chapter.doc_lec.length > 0 && (
                                      <span>
                                        {chapter.doc_lec.length} Document
                                        {chapter.doc_lec.length > 1 ? "s" : ""}
                                        {chapter.feedbackObj &&
                                        chapter.feedback &&
                                        feedbackStatus[chapter.feedback] &&
                                        feedbackStatus[chapter.feedback]
                                          .isvisble &&
                                        chapter.feedback_publish &&
                                        (batchObj.gradebook_auto_publish ||
                                          chapter.feedback_publish)
                                          ? ", "
                                          : ""}
                                      </span>
                                    )}
                                    {chapter.feedbackObj &&
                                      chapter.feedback &&
                                      feedbackStatus[chapter.feedback] &&
                                      feedbackStatus[chapter.feedback]
                                        .isvisble &&
                                      chapter.feedback_publish &&
                                      (batchObj.gradebook_auto_publish ||
                                        chapter.feedback_publish) &&
                                      "1 Feedback"}
                                  </p>

                                  {trxnTypeEmi &&
                                    hideCourseContent &&
                                    index >= noOfVisibleChapter && (
                                      <div className="arc">
                                        <img
                                          src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                          alt=""
                                        />
                                      </div>
                                    )}
                                </div>
                                {!chapterCollapseMap[chapter._id] ||
                                  (!(
                                    chapterLocking[chapter._id] &&
                                    (chapterLocking[chapter._id].isLocked ||
                                      chapterLocking[chapter._id]
                                        .isLockedDeadline)
                                  ) && (
                                    <div className={`panel-collapse}`}>
                                      <div className="panel-body">
                                        <ul className="rvm-v1-chap-list-ul">
                                          {course.sequence[chapter._id] &&
                                            course.sequence[chapter._id].map(
                                              (topic, index) => (
                                                <div key={topic._id}>
                                                  {topic.type === 7 &&
                                                    course.liv_lec_id_map[
                                                      topic.fid
                                                    ] && (
                                                      <li
                                                        className="rvm-v1-chap-liv-lec-li"
                                                        style={{
                                                          padding: "8px 24px",
                                                        }}
                                                      >
                                                        <div className="width-100 rvm-v1-chap-liv-lec-ctnr">
                                                          <div className="rvm-v1-chap-liv-lec-logo">
                                                            <div className="rvm-v1-chap-liv-lec-logo-fltr center-all">
                                                              <i className="fa fa-play-circle"></i>
                                                            </div>
                                                          </div>

                                                          <div className="rvm-v1-chap-liv-lec-det-ctnr">
                                                            <div className="rvm-v1-chap-liv-lec-det-intr-ctnr">
                                                              <span>
                                                                {index + 1}.{" "}
                                                                {course
                                                                  .liv_lec_id_map[
                                                                  topic.fid
                                                                ].nm.length > 30
                                                                  ? course.liv_lec_id_map[
                                                                      topic.fid
                                                                    ].nm.substring(
                                                                      0,
                                                                      30
                                                                    ) + "..."
                                                                  : course
                                                                      .liv_lec_id_map[
                                                                      topic.fid
                                                                    ].nm}
                                                              </span>
                                                              {course
                                                                .liv_lec_id_map[
                                                                topic.fid
                                                              ].live_status ===
                                                                1 && (
                                                                <span className="rvm-v1-chap-liv-lec-det-intr-icon-ctnr">
                                                                  <i className="fa fa-lock"></i>
                                                                </span>
                                                              )}
                                                              {contentLocking[
                                                                chapter._id
                                                              ] &&
                                                                (contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked ||
                                                                  contentLocking[
                                                                    chapter._id
                                                                  ][index]
                                                                    .isLockedDeadline) && (
                                                                  <span
                                                                    className=" statusLockLivLec"
                                                                    onMouseEnter={() => {
                                                                      handleShowPopUpContent(
                                                                        topic.fid
                                                                      );
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                      handleShowPopUpContent(
                                                                        topic.fid
                                                                      );
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="showPopupChapter"
                                                                      style={{
                                                                        display:
                                                                          showPopupContentMap[
                                                                            topic
                                                                              .fid
                                                                          ]
                                                                            ? "flex"
                                                                            : "none",
                                                                        fontWeight: 400,
                                                                        fontSize:
                                                                          "14px",
                                                                      }}
                                                                    >
                                                                      {contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ]
                                                                        ? contentLocking[
                                                                            chapter
                                                                              ._id
                                                                          ][
                                                                            index
                                                                          ].msg
                                                                        : " "}
                                                                    </div>
                                                                    <div className="center_new">
                                                                      <img
                                                                        src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                        alt=""
                                                                        style={{
                                                                          width:
                                                                            "18px",
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </span>
                                                                )}
                                                            </div>

                                                            <div className="rvm-v1-chap-liv-lec-det-2-ctnr width-100">
                                                              <div className="rvm-v1-chap-liv-lec-det-2 width-100">
                                                                <span>
                                                                  Status:&nbsp;
                                                                </span>
                                                                {course
                                                                  .liv_lec_id_map[
                                                                  topic.fid
                                                                ]
                                                                  .live_status ===
                                                                  1 && (
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  >
                                                                    Scheduled
                                                                  </span>
                                                                )}
                                                                {course
                                                                  .liv_lec_id_map[
                                                                  topic.fid
                                                                ]
                                                                  .live_status ===
                                                                  2 && (
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  >
                                                                    Ongoing Live
                                                                    Session
                                                                  </span>
                                                                )}
                                                                {course
                                                                  .liv_lec_id_map[
                                                                  topic.fid
                                                                ]
                                                                  .live_status ===
                                                                  3 && (
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "#3C4852",
                                                                    }}
                                                                  >
                                                                    Was Live
                                                                  </span>
                                                                )}
                                                              </div>
                                                              <div className="rvm-v1-chap-liv-lec-det-2 width-100">
                                                                <span>
                                                                  Start:&nbsp;
                                                                </span>
                                                                <span>
                                                                  {
                                                                    course
                                                                      .liv_lec_id_map[
                                                                      topic.fid
                                                                    ].lcdt
                                                                  }
                                                                  &nbsp;
                                                                  {
                                                                    course
                                                                      .liv_lec_id_map[
                                                                      topic.fid
                                                                    ].lctm
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="rvm-v1-chap-liv-lec-det-2 width-100">
                                                                <span>
                                                                  End:&nbsp;
                                                                </span>
                                                                <span>
                                                                  {
                                                                    course
                                                                      .liv_lec_id_map[
                                                                      topic.fid
                                                                    ].lcedt
                                                                  }
                                                                  &nbsp;
                                                                  {
                                                                    course
                                                                      .liv_lec_id_map[
                                                                      topic.fid
                                                                    ].lcetm
                                                                  }
                                                                </span>

                                                                <div
                                                                  className="status"
                                                                  style={{
                                                                    margin:
                                                                      "auto 1rem auto auto",
                                                                    zIndex: 100,
                                                                    display:
                                                                      status
                                                                        .status[
                                                                        course
                                                                          .liv_lec_id_map[
                                                                          topic
                                                                            .fid
                                                                        ]._id
                                                                      ] &&
                                                                      status
                                                                        .status[
                                                                        course
                                                                          .liv_lec_id_map[
                                                                          topic
                                                                            .fid
                                                                        ]._id
                                                                      ].stts ===
                                                                        4 &&
                                                                      ((course
                                                                        .liv_lec_id_map[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        .feedback_compulsory &&
                                                                        status
                                                                          .status[
                                                                          course
                                                                            .liv_lec_id_map[
                                                                            topic
                                                                              .fid
                                                                          ]
                                                                            .feedback
                                                                        ] &&
                                                                        status
                                                                          .status[
                                                                          course
                                                                            .liv_lec_id_map[
                                                                            topic
                                                                              .fid
                                                                          ]
                                                                            .feedback
                                                                        ]
                                                                          .stts ===
                                                                          4) ||
                                                                        !course
                                                                          .liv_lec_id_map[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          .feedback_compulsory)
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  <PercentDetailTooltip
                                                                    id={
                                                                      topic.fid +
                                                                      "panhs"
                                                                    }
                                                                    text={
                                                                      lecturePerc[4]
                                                                    }
                                                                    elem={
                                                                      <div className="center_new">
                                                                        <img
                                                                          src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                          alt="check"
                                                                          style={{
                                                                            height:
                                                                              "16px",
                                                                            width:
                                                                              "16px",
                                                                          }}
                                                                        ></img>
                                                                      </div>
                                                                    }
                                                                  />
                                                                </div>
                                                                {((course
                                                                  .liv_lec_id_map[
                                                                  topic.fid
                                                                ]
                                                                  .live_status ===
                                                                  1 &&
                                                                  course
                                                                    .liv_lec_id_map[
                                                                    topic.fid
                                                                  ]
                                                                    .to_be_live &&
                                                                  (!contentLocking[
                                                                    chapter._id
                                                                  ] ||
                                                                    (contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ] &&
                                                                      !(
                                                                        contentLocking[
                                                                          chapter
                                                                            ._id
                                                                        ][index]
                                                                          .isLocked ||
                                                                        contentLocking[
                                                                          chapter
                                                                            ._id
                                                                        ][index]
                                                                          .isLockedDeadline
                                                                      )))) ||
                                                                  (course
                                                                    .liv_lec_id_map[
                                                                    topic.fid
                                                                  ]
                                                                    .live_status ===
                                                                    2 &&
                                                                    course.liv_lec_id_map[
                                                                      topic.fid
                                                                    ].url.trim()
                                                                      .length &&
                                                                    (!contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ] ||
                                                                      (contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ] &&
                                                                        !(
                                                                          contentLocking[
                                                                            chapter
                                                                              ._id
                                                                          ][
                                                                            index
                                                                          ]
                                                                            .isLocked ||
                                                                          contentLocking[
                                                                            chapter
                                                                              ._id
                                                                          ][
                                                                            index
                                                                          ]
                                                                            .isLockedDeadline
                                                                        ))))) && (
                                                                  <span className="rvm-v1-chap-liv-lec-det-join-btn">
                                                                    <div
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        joinZoomMeeting(
                                                                          course
                                                                            .liv_lec_id_map[
                                                                            topic
                                                                              .fid
                                                                          ]._id
                                                                        )
                                                                      }
                                                                    >
                                                                      Join
                                                                      <i className="fa fa-chevron-right"></i>
                                                                    </div>
                                                                  </span>
                                                                )}
                                                                {course
                                                                  .liv_lec_id_map[
                                                                  topic.fid
                                                                ]
                                                                  .live_status ===
                                                                  3 &&
                                                                  course
                                                                    .liv_lec_id_map[
                                                                    topic.fid
                                                                  ].status ===
                                                                    3 &&
                                                                  course
                                                                    .liv_lec_id_map[
                                                                    topic.fid
                                                                  ].s3_url &&
                                                                  (!contentLocking[
                                                                    chapter._id
                                                                  ] ||
                                                                    (contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ] &&
                                                                      !(
                                                                        contentLocking[
                                                                          chapter
                                                                            ._id
                                                                        ][index]
                                                                          .isLocked ||
                                                                        contentLocking[
                                                                          chapter
                                                                            ._id
                                                                        ][index]
                                                                          .isLockedDeadline
                                                                      ))) && (
                                                                    <span className="rvm-v1-chap-liv-lec-det-join-btn">
                                                                      <a
                                                                        href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${topic.chpid}/${topic.fid}/0`}
                                                                      >
                                                                        View
                                                                        <i className="fa fa-chevron-right"></i>
                                                                      </a>
                                                                    </span>
                                                                  )}
                                                              </div>
                                                              <div className="rvm-v1-chap-liv-lec-det-2 width-100">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "#3C4852",
                                                                  }}
                                                                  className="rvm-v1-chap-liv-lec-det-join-btn"
                                                                >
                                                                  {course
                                                                    .liv_lec_id_map[
                                                                    topic.fid
                                                                  ]
                                                                    .live_status ===
                                                                    3 &&
                                                                    course
                                                                      .liv_lec_id_map[
                                                                      topic.fid
                                                                    ].status !=
                                                                      3 &&
                                                                    (!contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ] ||
                                                                      (contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ] &&
                                                                        !(
                                                                          contentLocking[
                                                                            chapter
                                                                              ._id
                                                                          ][
                                                                            index
                                                                          ]
                                                                            .isLocked ||
                                                                          contentLocking[
                                                                            chapter
                                                                              ._id
                                                                          ][
                                                                            index
                                                                          ]
                                                                            .isLockedDeadline
                                                                        ))) && (
                                                                      <span>
                                                                        Recording
                                                                        Under
                                                                        Process
                                                                      </span>
                                                                    )}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}
                                                  {topic.type === 1 &&
                                                    course.lectures[
                                                      topic.fid
                                                    ] && (
                                                      <li className="rvm-v1-chap-list-li list_ui_fix">
                                                        <div
                                                          className="title "
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${topic.chpid}/${topic.fid}/0`}
                                                          >
                                                            <p className="contentList  content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/playButton.svg"
                                                                className="icon play-button"
                                                                alt=""
                                                              />
                                                              <span>
                                                                {index + 1}.{" "}
                                                              </span>
                                                              <span>
                                                                {
                                                                  course
                                                                    .lectures[
                                                                    topic.fid
                                                                  ].nm
                                                                }
                                                              </span>
                                                              {/* <span className="vlenPopup">
                                                                {
                                                                  course
                                                                    .lectures[
                                                                    topic.fid
                                                                  ].vlen
                                                                }
                                                              </span> */}
                                                            </p>
                                                          </a>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() =>
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              )
                                                            }
                                                            onMouseLeave={() =>
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              )
                                                            }
                                                          >
                                                            {showPopupContentMap[
                                                              topic.fid
                                                            ] && (
                                                              <div className="showPopup">
                                                                {contentLocking[
                                                                  chapter._id
                                                                ]
                                                                  ? contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index].msg
                                                                  : " "}
                                                              </div>
                                                            )}
                                                            <div className="center_new">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt=""
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                ((course
                                                                  .lectures[
                                                                  topic.fid
                                                                ]
                                                                  .feedback_compulsory &&
                                                                  status.status[
                                                                    course
                                                                      .lectures[
                                                                      topic.fid
                                                                    ].feedback
                                                                  ] &&
                                                                  status.status[
                                                                    course
                                                                      .lectures[
                                                                      topic.fid
                                                                    ].feedback
                                                                  ].stts ===
                                                                    4) ||
                                                                  !course
                                                                    .lectures[
                                                                    topic.fid
                                                                  ]
                                                                    .feedback_compulsory) &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                ((course
                                                                  .lectures[
                                                                  topic.fid
                                                                ]
                                                                  .feedback_compulsory &&
                                                                  status.status[
                                                                    course
                                                                      .lectures[
                                                                      topic.fid
                                                                    ].feedback
                                                                  ] &&
                                                                  status.status[
                                                                    course
                                                                      .lectures[
                                                                      topic.fid
                                                                    ].feedback
                                                                  ].stts ===
                                                                    4) ||
                                                                  !course
                                                                    .lectures[
                                                                    topic.fid
                                                                  ]
                                                                    .feedback_compulsory) &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                perCalc(
                                                                  status,
                                                                  topic,
                                                                  course,
                                                                  "lectures"
                                                                ) +
                                                                (perCalc(
                                                                  status,
                                                                  topic,
                                                                  course,
                                                                  "lectures"
                                                                ) === 100
                                                                  ? "% 'Video Completed, Feedback Pending'"
                                                                  : perCalc(
                                                                      status,
                                                                      topic,
                                                                      course,
                                                                      "lectures"
                                                                    ) >= 1
                                                                  ? "% 'Video in Progress'"
                                                                  : "% 'Not Started'")
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={perCalc(
                                                                        status,
                                                                        topic,
                                                                        course,
                                                                        "lectures"
                                                                      )}
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/playButton.svg"
                                                              className="icon play-button"
                                                              alt=""
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course.lectures[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                            {/* <span
                                                              style={{
                                                                paddingRight:
                                                                  "8px",
                                                                float: "right",
                                                              }}
                                                            >
                                                              {
                                                                course.lectures[
                                                                  topic.fid
                                                                ].vlen
                                                              }
                                                            </span> */}
                                                          </p>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                            onMouseEnter={() =>
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              )
                                                            }
                                                            onMouseLeave={() =>
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              )
                                                            }
                                                          >
                                                            {showPopupContentMap[
                                                              topic.fid
                                                            ] && (
                                                              <div className="showPopup">
                                                                {contentLocking[
                                                                  chapter._id
                                                                ]
                                                                  ? contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index].msg
                                                                  : " "}
                                                              </div>
                                                            )}
                                                            <div className="center_new">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt=""
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                ((course
                                                                  .lectures[
                                                                  topic.fid
                                                                ]
                                                                  .feedback_compulsory &&
                                                                  status.status[
                                                                    course
                                                                      .lectures[
                                                                      topic.fid
                                                                    ].feedback
                                                                  ] &&
                                                                  status.status[
                                                                    course
                                                                      .lectures[
                                                                      topic.fid
                                                                    ].feedback
                                                                  ].stts ===
                                                                    4) ||
                                                                  !course
                                                                    .lectures[
                                                                    topic.fid
                                                                  ]
                                                                    .feedback_compulsory) &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}
                                                  {topic.type === 8 &&
                                                    course.scorm[topic.fid] && (
                                                      <li className="rvm-v1-chap-list-li">
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/scorm/${topic.chpid}/${topic.fid}`}
                                                          >
                                                            <p className="contentList content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/scorm.svg"
                                                                className="icon play-button"
                                                                alt="scorm icon"
                                                              />
                                                              {index + 1}.{" "}
                                                              {
                                                                course.scorm[
                                                                  topic.fid
                                                                ].nm
                                                              }
                                                            </p>
                                                          </a>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="showPopup"
                                                              style={{
                                                                display:
                                                                  showPopupContentMap[
                                                                    topic.fid
                                                                  ]
                                                                    ? "block"
                                                                    : "none",
                                                              }}
                                                            >
                                                              {contentLocking[
                                                                chapter._id
                                                              ]
                                                                ? contentLocking[
                                                                    chapter._id
                                                                  ][index].msg
                                                                : " "}
                                                            </div>
                                                            <div className="center_new">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt="secure lock"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                ScromPerc[
                                                                  status.status[
                                                                    topic.fid
                                                                  ]?.stts || 0
                                                                ]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={
                                                                        (status
                                                                          .status[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          ?.stts /
                                                                          4) *
                                                                          100 ||
                                                                        0
                                                                      }
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/scorm.svg"
                                                              className="icon play-button"
                                                              alt="scorm icon"
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course.scorm[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                          </p>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="showPopup"
                                                              style={{
                                                                display:
                                                                  showPopupContentMap[
                                                                    topic.fid
                                                                  ]
                                                                    ? "block"
                                                                    : "none",
                                                              }}
                                                            >
                                                              {contentLocking[
                                                                chapter._id
                                                              ]
                                                                ? contentLocking[
                                                                    chapter._id
                                                                  ][index].msg
                                                                : " "}
                                                            </div>
                                                            <div className="center_new">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt="secure lock"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}
                                                  {topic.type === 5 &&
                                                    course.documentAsLectures[
                                                      topic.fid
                                                    ] && (
                                                      <li className="rvm-v1-chap-list-li">
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/docLecture/${topic.chpid}/${topic.fid}/0`}
                                                          >
                                                            <p className="contentList content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/pdf.svg"
                                                                className="icon pdfic play-button"
                                                                alt="pdf icon"
                                                              />
                                                              {index + 1}.{" "}
                                                              {
                                                                course
                                                                  .documentAsLectures[
                                                                  topic.fid
                                                                ].nm
                                                              }
                                                            </p>
                                                          </a>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="showPopup"
                                                              style={{
                                                                display:
                                                                  showPopupContentMap[
                                                                    topic.fid
                                                                  ]
                                                                    ? "block"
                                                                    : "none",
                                                                position:
                                                                  "absolute",
                                                                // right: "4%",
                                                              }}
                                                            >
                                                              {contentLocking[
                                                                chapter._id
                                                              ]
                                                                ? contentLocking[
                                                                    chapter._id
                                                                  ][index].msg
                                                                : " "}

                                                              <div className="center_new">
                                                                <img
                                                                  src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                  style={{
                                                                    width:
                                                                      "18px",
                                                                  }}
                                                                  alt="secure lock"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                DocLecturePerc[
                                                                  status.status[
                                                                    topic.fid
                                                                  ]?.stts || 0
                                                                ]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={
                                                                        (status
                                                                          .status[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          ?.stts /
                                                                          4) *
                                                                          100 ||
                                                                        0
                                                                      }
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/pdf.svg"
                                                              className="icon pdfic play-button"
                                                              alt="pdf icon"
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course
                                                                .documentAsLectures[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                          </p>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="showPopup"
                                                              style={{
                                                                display:
                                                                  showPopupContentMap[
                                                                    topic.fid
                                                                  ]
                                                                    ? "block"
                                                                    : "none",
                                                                position:
                                                                  "absolute",
                                                                // right: "4%",
                                                              }}
                                                            >
                                                              {contentLocking[
                                                                chapter._id
                                                              ]
                                                                ? contentLocking[
                                                                    chapter._id
                                                                  ][index].msg
                                                                : " "}
                                                            </div>
                                                            <div className="center_new">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt="secure lock"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}
                                                  {(topic.type === 3 ||
                                                    topic.type === 4) &&
                                                    course.quizzes[
                                                      topic.fid
                                                    ] && (
                                                      <li className="rvm-v1-chap-list-li">
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/quiz/${topic.chpid}/${topic.fid}`}
                                                          >
                                                            <p className="contentList content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/quiz.svg"
                                                                className="icon quiz"
                                                                alt="quiz icon"
                                                              />
                                                              {index + 1}.{" "}
                                                              {
                                                                course.quizzes[
                                                                  topic.fid
                                                                ].nm
                                                              }
                                                            </p>
                                                          </a>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="showPopup"
                                                              style={{
                                                                display:
                                                                  showPopupContentMap[
                                                                    topic.fid
                                                                  ]
                                                                    ? "block"
                                                                    : "none",
                                                              }}
                                                            >
                                                              {contentLocking[
                                                                chapter._id
                                                              ]
                                                                ? contentLocking[
                                                                    chapter._id
                                                                  ][index].msg
                                                                : " "}
                                                            </div>
                                                            <div
                                                              className="center_new"
                                                              style={{
                                                                border: "solid",
                                                              }}
                                                            >
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt="secure lock"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            {" "}
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                QuizPerc[
                                                                  status.status[
                                                                    topic.fid
                                                                  ]?.stts || 0
                                                                ]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={
                                                                        (status
                                                                          .status[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          ?.stts /
                                                                          4) *
                                                                          100 ||
                                                                        0
                                                                      }
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/quiz.svg"
                                                              className="icon quiz"
                                                              alt="quiz icon"
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course.quizzes[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                          </p>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                            onMouseEnter={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                            onMouseLeave={() => {
                                                              handleShowPopUpContent(
                                                                topic.fid
                                                              );
                                                            }}
                                                          >
                                                            <div
                                                              className="showPopup"
                                                              style={{
                                                                display:
                                                                  showPopupContentMap[
                                                                    topic.fid
                                                                  ]
                                                                    ? "block"
                                                                    : "none",
                                                              }}
                                                            >
                                                              {contentLocking[
                                                                chapter._id
                                                              ]
                                                                ? contentLocking[
                                                                    chapter._id
                                                                  ][index].msg
                                                                : " "}
                                                            </div>
                                                            <div className="center_new">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                style={{
                                                                  width: "18px",
                                                                }}
                                                                alt="secure lock"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}
                                                  {topic.type === 10 &&
                                                    course.cdng_assignments[
                                                      topic.fid
                                                    ] && (
                                                      <li className="rvm-v1-chap-list-li">
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/coding/${topic.chpid}/${topic.fid}`}
                                                          >
                                                            <p className="contentList content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/assignment.svg"
                                                                className="icon play-button"
                                                                alt="coding assignment icon"
                                                              />
                                                              {index + 1}.{" "}
                                                              {
                                                                course
                                                                  .cdng_assignments[
                                                                  topic.fid
                                                                ].nm
                                                              }
                                                            </p>
                                                          </a>
                                                          {contentLocking[
                                                            chapter._id
                                                          ] &&
                                                            contentLocking[
                                                              chapter._id
                                                            ][index]
                                                              .isLocked && (
                                                              <div
                                                                className="status"
                                                                onMouseEnter={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="showPopup"
                                                                  style={{
                                                                    display:
                                                                      showPopupContentMap[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  {contentLocking[
                                                                    chapter._id
                                                                  ]
                                                                    ? contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ][index]
                                                                        .msg
                                                                    : " "}
                                                                </div>
                                                                {
                                                                  <div className="center_new">
                                                                    <img
                                                                      src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                      alt="secure lock"
                                                                    />
                                                                  </div>
                                                                }
                                                              </div>
                                                            )}
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                course
                                                                  .cdng_assignments[
                                                                  topic.fid
                                                                ]
                                                                  .evaluation_type ==
                                                                2
                                                                  ? CodingAssignmentPercAuto[
                                                                      status
                                                                        .status[
                                                                        topic
                                                                          .fid
                                                                      ]?.stts ||
                                                                        0
                                                                    ]
                                                                  : CodingAssignmentPerc[
                                                                      status
                                                                        .status[
                                                                        topic
                                                                          .fid
                                                                      ]?.stts ||
                                                                        0
                                                                    ]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={
                                                                        (status
                                                                          .status[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          ?.stts /
                                                                          4) *
                                                                          100 ||
                                                                        0
                                                                      }
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p className="contentList content_ui_fix">
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/assignment.svg"
                                                              className="icon play-button"
                                                              alt="assignment icon"
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course
                                                                .cdng_assignments[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                          </p>
                                                          {contentLocking[
                                                            chapter._id
                                                          ] &&
                                                            contentLocking[
                                                              chapter._id
                                                            ][index]
                                                              .isLocked && (
                                                              <div
                                                                className="status"
                                                                onMouseEnter={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="showPopup"
                                                                  style={{
                                                                    display:
                                                                      showPopupContentMap[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  {contentLocking[
                                                                    chapter._id
                                                                  ]
                                                                    ? contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ][index]
                                                                        .msg
                                                                    : " "}
                                                                </div>
                                                                {
                                                                  <div className="center_new">
                                                                    <img
                                                                      src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                      style={{
                                                                        width:
                                                                          "18px",
                                                                      }}
                                                                      alt="secure lock"
                                                                    />
                                                                  </div>
                                                                }
                                                              </div>
                                                            )}
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}

                                                  {topic.type === 6 &&
                                                    course.assignments[
                                                      topic.fid
                                                    ] && (
                                                      <li className="rvm-v1-chap-list-li">
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/assignment/${topic.chpid}/${topic.fid}`}
                                                          >
                                                            <p className="contentList content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/assignment.svg"
                                                                className="icon play-button"
                                                                alt="assignment icon"
                                                              />
                                                              {index + 1}.{" "}
                                                              {
                                                                course
                                                                  .assignments[
                                                                  topic.fid
                                                                ].nm
                                                              }
                                                            </p>
                                                          </a>
                                                          {contentLocking[
                                                            chapter._id
                                                          ] &&
                                                            contentLocking[
                                                              chapter._id
                                                            ][index]
                                                              .isLocked && (
                                                              <div
                                                                className="status"
                                                                onMouseEnter={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="showPopup"
                                                                  style={{
                                                                    display:
                                                                      showPopupContentMap[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  {contentLocking[
                                                                    chapter._id
                                                                  ]
                                                                    ? contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ][index]
                                                                        .msg
                                                                    : " "}
                                                                </div>
                                                                {
                                                                  <div className="center_new">
                                                                    <img
                                                                      src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                      alt="secure lock"
                                                                    />
                                                                  </div>
                                                                }
                                                              </div>
                                                            )}
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                NoSubAssignmentPerc[
                                                                  status.status[
                                                                    topic.fid
                                                                  ]?.stts || 0
                                                                ]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={
                                                                        (status
                                                                          .status[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          ?.stts /
                                                                          4) *
                                                                          100 ||
                                                                        0
                                                                      }
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p className="contentList content_ui_fix">
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/assignment.svg"
                                                              className="icon play-button"
                                                              alt="assignment icon"
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course
                                                                .assignments[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                          </p>
                                                          {contentLocking[
                                                            chapter._id
                                                          ] &&
                                                            contentLocking[
                                                              chapter._id
                                                            ][index]
                                                              .isLocked && (
                                                              <div
                                                                className="status"
                                                                onMouseEnter={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="showPopup"
                                                                  style={{
                                                                    display:
                                                                      showPopupContentMap[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  {contentLocking[
                                                                    chapter._id
                                                                  ]
                                                                    ? contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ][index]
                                                                        .msg
                                                                    : " "}
                                                                </div>
                                                                {
                                                                  <div className="center_new">
                                                                    <img
                                                                      src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                      style={{
                                                                        width:
                                                                          "18px",
                                                                      }}
                                                                      alt="secure lock"
                                                                    />
                                                                  </div>
                                                                }
                                                              </div>
                                                            )}
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}

                                                  {(topic.type === 2 ||
                                                    topic.type === 9) &&
                                                    course.assignments[
                                                      topic.fid
                                                    ] && (
                                                      <li className="rvm-v1-chap-list-li">
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              !contentLocking[
                                                                chapter._id
                                                              ] ||
                                                              (contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              !contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none"),
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}chapter/${pid}/${gid}/${cid}/${levelId}/assignment/${topic.chpid}/${topic.fid}`}
                                                          >
                                                            <p className="contentList content_ui_fix">
                                                              <img
                                                                src="http://cdn.pegasus.imarticus.org/courseController/assignment.svg"
                                                                className="icon play-button"
                                                                alt="assignment icon"
                                                              />
                                                              {index + 1}.{" "}
                                                              {
                                                                course
                                                                  .assignments[
                                                                  topic.fid
                                                                ].nm
                                                              }
                                                            </p>
                                                          </a>
                                                          {contentLocking[
                                                            chapter._id
                                                          ] &&
                                                            contentLocking[
                                                              chapter._id
                                                            ][index]
                                                              .isLocked && (
                                                              <div
                                                                className="status"
                                                                onMouseEnter={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="showPopup"
                                                                  style={{
                                                                    display:
                                                                      showPopupContentMap[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  {contentLocking[
                                                                    chapter._id
                                                                  ]
                                                                    ? contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ][index]
                                                                        .msg
                                                                    : " "}
                                                                </div>
                                                                {
                                                                  <div className="center_new">
                                                                    <img
                                                                      src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                      alt="secure lock"
                                                                    />
                                                                  </div>
                                                                }
                                                              </div>
                                                            )}
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>

                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                (!contentLocking[
                                                                  chapter._id
                                                                ] ||
                                                                  (contentLocking[
                                                                    chapter._id
                                                                  ] &&
                                                                    !contentLocking[
                                                                      chapter
                                                                        ._id
                                                                    ][index]
                                                                      .isLocked))
                                                                  ? "none"
                                                                  : "block",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={topic.fid}
                                                              text={
                                                                AssignmentPerc[
                                                                  status.status[
                                                                    topic.fid
                                                                  ]?.stts || 0
                                                                ]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <div className="cicularProgress">
                                                                    <CircularProgressbar
                                                                      value={
                                                                        (status
                                                                          .status[
                                                                          topic
                                                                            .fid
                                                                        ]
                                                                          ?.stts /
                                                                          4) *
                                                                          100 ||
                                                                        0
                                                                      }
                                                                      strokeWidth={
                                                                        22
                                                                      }
                                                                      styles={{
                                                                        path: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 1)",
                                                                        },
                                                                        trail: {
                                                                          stroke:
                                                                            "rgba(130, 130, 130, 0.2)",
                                                                        },
                                                                      }}
                                                                    />{" "}
                                                                  </div>
                                                                </div>
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="title"
                                                          style={{
                                                            display:
                                                              contentLocking[
                                                                chapter._id
                                                              ] &&
                                                              contentLocking[
                                                                chapter._id
                                                              ][index].isLocked
                                                                ? "flex"
                                                                : "none",
                                                          }}
                                                        >
                                                          <p className="contentList content_ui_fix">
                                                            <img
                                                              src="http://cdn.pegasus.imarticus.org/courseController/assignment.svg"
                                                              className="icon play-button"
                                                              alt="assignment icon"
                                                            />
                                                            {index + 1}.{" "}
                                                            {
                                                              course
                                                                .assignments[
                                                                topic.fid
                                                              ].nm
                                                            }
                                                          </p>
                                                          {contentLocking[
                                                            chapter._id
                                                          ] &&
                                                            contentLocking[
                                                              chapter._id
                                                            ][index]
                                                              .isLocked && (
                                                              <div
                                                                className="status"
                                                                onMouseEnter={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  handleShowPopUpContent(
                                                                    topic.fid
                                                                  );
                                                                }}
                                                              >
                                                                <div
                                                                  className="showPopup"
                                                                  style={{
                                                                    display:
                                                                      showPopupContentMap[
                                                                        topic
                                                                          .fid
                                                                      ]
                                                                        ? "block"
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  {contentLocking[
                                                                    chapter._id
                                                                  ]
                                                                    ? contentLocking[
                                                                        chapter
                                                                          ._id
                                                                      ][index]
                                                                        .msg
                                                                    : " "}
                                                                </div>
                                                                {
                                                                  <div className="center_new">
                                                                    <img
                                                                      src="http://cdn.pegasus.imarticus.org/courses/images/locking.svg"
                                                                      style={{
                                                                        width:
                                                                          "18px",
                                                                      }}
                                                                      alt="secure lock"
                                                                    />
                                                                  </div>
                                                                }
                                                              </div>
                                                            )}
                                                          <div
                                                            className="status"
                                                            style={{
                                                              display:
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                status.status[
                                                                  topic.fid
                                                                ].stts === 4 &&
                                                                contentLocking[
                                                                  chapter._id
                                                                ] &&
                                                                !contentLocking[
                                                                  chapter._id
                                                                ][index]
                                                                  .isLocked
                                                                  ? "block"
                                                                  : "none",
                                                              position:
                                                                "absolute",
                                                              // right: "4%",
                                                            }}
                                                          >
                                                            <PercentDetailTooltip
                                                              id={
                                                                topic.fid +
                                                                "panhs"
                                                              }
                                                              text={
                                                                lecturePerc[4]
                                                              }
                                                              elem={
                                                                <div className="center_new">
                                                                  <img
                                                                    src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                                    alt="check"
                                                                    style={{
                                                                      height:
                                                                        "16px",
                                                                      width:
                                                                        "16px",
                                                                    }}
                                                                  ></img>
                                                                </div>
                                                              }
                                                            />
                                                            {/* <div
                                                              className={`s-circle ${
                                                                status.status[
                                                                  topic.fid
                                                                ] &&
                                                                (status.status[
                                                                  topic.fid
                                                                ].stts === 2 ||
                                                                  status.status[
                                                                    topic.fid
                                                                  ].stts === 3)
                                                                  ? "partial"
                                                                  : "complete"
                                                              }`}
                                                            >
                                                              <i
                                                                className="icon fa fa-check"
                                                                style={{
                                                                  fontSize:
                                                                    "x-small",
                                                                }}
                                                              ></i>
                                                            </div> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    )}
                                                </div>
                                              )
                                            )}
                                        </ul>
                                        <ul className="rvm-v1-chap-list-ul">
                                          {chapter.feedback &&
                                            chapter.feedbackObj &&
                                            chapter.feedback_publish &&
                                            (batchObj.gradebook_auto_publish ||
                                              chapter.feedback_publish) && (
                                              <li
                                                className="rvm-v1-chap-list-li"
                                                onClick={() =>
                                                  openFeedback(
                                                    chapter.feedbackObj,
                                                    chapter._id
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <div
                                                  className="title"
                                                  style={{
                                                    padding: "8xp 24px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "start",
                                                      alignItems: "center",
                                                      width: "100%",
                                                      // paddingLeft: "8px",
                                                    }}
                                                  >
                                                    <img
                                                      src="http://cdn.pegasus.imarticus.org/courseController/feedback.svg"
                                                      className="icon play-button"
                                                      alt="feedback icon"
                                                    />
                                                    <span>
                                                      {chapter.feedbackObj.nm}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  className="status"
                                                  style={{
                                                    display:
                                                      status.status[
                                                        chapter.feedbackObj._id
                                                      ] &&
                                                      status.status[
                                                        chapter.feedbackObj._id
                                                      ].stts === 4
                                                        ? "block"
                                                        : "none",
                                                  }}
                                                >
                                                  {/* <div className="s-circle complete">
                                                    <i
                                                      className="icon fa fa-check"
                                                      style={{
                                                        fontSize: "x-small",
                                                      }}
                                                    ></i>
                                                  </div> */}

                                                  <div className="center_new">
                                                    <img
                                                      src="https://cdn.pegasus.imarticus.org/klu/GreyTick.svg"
                                                      alt="check"
                                                      style={{
                                                        height: "16px",
                                                        width: "16px",
                                                      }}
                                                    ></img>
                                                  </div>
                                                </div>
                                              </li>
                                            )}
                                        </ul>
                                      </div>
                                      <div className="download-btns backcolor"></div>
                                    </div>
                                  ))}
                              </div>
                            ))}
                          {course &&
                            course.projects &&
                            course.projects.map((project, index) => (
                              <div key={index} className="panel panel-default">
                                <div className="panel-heading project-panels">
                                  <img
                                    src="images/group-3-copy.svg"
                                    className="Group-3-Copy"
                                    alt="Group 3 Copy"
                                  />
                                  <h4 className="panel-title">
                                    Project : {project.nm}
                                  </h4>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              <div
                className="rvm-v1-cont-ctnr rvm-v1-part-2-otr-ctnr"
                style={{ display: certiEnabled ? "block" : "none" }}
              >
                <div className="main rvm-v1-part-2-ctnr no-padding">
                  <div className="rvm-v1-certi-ctnr no-padding">
                    <div className="rvm-v1-part-2-inr-ctnr">
                      <div className="rvm-v1-part-2-logo-ctnr rvm-pc no-padding">
                        {/* <div className="rvm-v1-part-2-certi-logo"></div> */}
                        <img
                          src="https://cdn.pegasus.imarticus.org/index/certificate_neutral.svg"
                          alt="certi"
                          className="certi_mini_logo"
                        />
                      </div>

                      <div className="rvm-v1-part-2-info-ctnr">
                        {/* <div className="with-100 rvm-v1-part-2-info-ttl">
                          Certificate
                          <div className="rvm-v1-part-2-need-help-ctnr">
                            <div className="rvm-v1-part-2-need-help"></div>
                          </div>
                        </div> */}

                        <div className="with-100 rvm-v1-part-2-info row no-margin">
                          <div className="col-xs-12 col-sm-8 col-md-9 col-lg-6 no-padding">
                            <span className="rvm-v1-part-2-logo-ctnr rvm-mobile no-padding">
                              <span className="rvm-v1-part-2-certi-logo">
                                {" "}
                              </span>
                            </span>
                            <div className="certi_head_name">Certificate</div>
                            <div className="certi_head_desc">
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus[
                                      elecId ? elecId : "CORE_TRACK"
                                    ]
                                  : certiPublished)) &&
                                !crtfEligible &&
                                !certiGenerated && (
                                  <>
                                    <i
                                      className="icon fa fa-lock"
                                      style={{
                                        opacity: "65%",
                                        marginTop: "1%",
                                        paddingRight: "8px",
                                      }}
                                    ></i>
                                    {"    "}Complete the course to download the
                                    certificate
                                  </>
                                )}
                              {!certiAutoPublish &&
                                !(isElec && elecLevelIndex
                                  ? certiElectivePublishStatus[
                                      elecId ? elecId : "CORE_TRACK"
                                    ]
                                  : certiPublished) && (
                                  <>
                                    The Certificate for this course is not yet
                                    available
                                  </>
                                )}
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus[
                                      elecId ? elecId : "CORE_TRACK"
                                    ]
                                  : certiPublished)) &&
                                certiGenerated && (
                                  <>
                                    The Certificate for this course is now
                                    available
                                  </>
                                )}
                              {(certiAutoPublish ||
                                (isElec && elecLevelIndex
                                  ? certiElectivePublishStatus[
                                      elecId ? elecId : "CORE_TRACK"
                                    ]
                                  : certiPublished)) &&
                                crtfEligible && (
                                  <>
                                    Thanks for completing the course, your
                                    certificate is now unlocked
                                  </>
                                )}
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-6 no-padding">
                            <div className="rvm-v1-part-2-need-help-ctnr">
                              {!certiEnabled && (
                                <p>This course does not have a certificate</p>
                              )}
                              {certiEnabled &&
                                (gradebookType == 2 && !elecId
                                  ? false
                                  : true) && (
                                  <div>
                                    <div>
                                      {elecId && (
                                        //crtfEligible - part of graduation criteria
                                        <div>
                                          {(certiAutoPublish ||
                                            certiElectivePublishStatus[
                                              elecId
                                            ]) &&
                                            !certiGeneratedStatus[elecId] &&
                                            !(gradebookType == 1
                                              ? crtfEligibleMap[elecId]
                                              : crtfEligible) && (
                                              <button
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                                className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                              >
                                                Get Certificate
                                              </button>
                                            )}
                                          {(certiAutoPublish ||
                                            certiElectivePublishStatus[
                                              elecId
                                            ]) &&
                                            (gradebookType == 1
                                              ? crtfEligibleMap[elecId]
                                              : crtfEligible) &&
                                            !certiGeneratedStatus[elecId] && (
                                              <a
                                                href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${elecId}`}
                                              >
                                                <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                  Generate Certificate
                                                </button>
                                              </a>
                                            )}
                                          {(certiAutoPublish ||
                                            certiElectivePublishStatus[
                                              elecId
                                            ]) &&
                                            certiGeneratedStatus[elecId] &&
                                            !crtfFile && (
                                              <a
                                                href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}certificate/${pid}/${cid}/${elecId}`}
                                              >
                                                <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                  Get Certificate
                                                </button>
                                              </a>
                                            )}
                                          {(certiAutoPublish ||
                                            certiElectivePublishStatus[
                                              elecId
                                            ]) &&
                                            certiGeneratedStatus[elecId] &&
                                            crtfFile && (
                                              <a
                                                href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${elecId}`}
                                                className="float-right btn btn-primary rvm-v1-part-2-info-btn center-all"
                                              >
                                                View Certificate
                                              </a>
                                            )}
                                        </div>
                                      )}

                                      {!elecId && (
                                        <div>
                                          {(certiAutoPublish ||
                                            (isElec && elecLevelIndex
                                              ? certiElectivePublishStatus[
                                                  "CORE_TRACK"
                                                ]
                                              : certiPublished)) &&
                                            !certiGeneratedStatus[
                                              "FOUNDATION"
                                            ] &&
                                            !(gradebookType == 1 && isElec
                                              ? crtfEligibleMap["FOUNDATION"]
                                              : crtfEligible) && (
                                              <button
                                                style={{
                                                  cursor: "not-allowed",
                                                }}
                                                className="btn btn-primary rvm-v1-part-2-info-btn center-all disabled"
                                              >
                                                Get Certificate
                                              </button>
                                            )}
                                          {(certiAutoPublish ||
                                            (isElec && elecLevelIndex
                                              ? certiElectivePublishStatus[
                                                  "CORE_TRACK"
                                                ]
                                              : certiPublished)) &&
                                            (gradebookType == 1 && isElec
                                              ? crtfEligibleMap["FOUNDATION"]
                                              : crtfEligible) &&
                                            !certiGeneratedStatus[
                                              "FOUNDATION"
                                            ] && (
                                              <a
                                                href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificate/${pid}/${cid}/${elecId}`}
                                              >
                                                <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                  Generate Certificate
                                                </button>
                                              </a>
                                            )}
                                          {(certiAutoPublish ||
                                            (isElec && elecLevelIndex
                                              ? certiElectivePublishStatus[
                                                  "CORE_TRACK"
                                                ]
                                              : certiPublished)) &&
                                            certiGeneratedStatus[
                                              "FOUNDATION"
                                            ] &&
                                            !crtfFile && (
                                              <a
                                                href={`${CONFIGS.WEB_DASHBOARD_ENDPOINT}certificate/${pid}/${cid}/${elecId}`}
                                              >
                                                <button className="btn btn-primary rvm-v1-part-2-info-btn center-all">
                                                  Get Certificate
                                                </button>
                                              </a>
                                            )}
                                          {(certiAutoPublish ||
                                            (isElec && elecLevelIndex
                                              ? certiElectivePublishStatus[
                                                  "CORE_TRACK"
                                                ]
                                              : certiPublished)) &&
                                            certiGeneratedStatus[
                                              "FOUNDATION"
                                            ] &&
                                            crtfFile && (
                                              <a
                                                href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}certificateView/${pid}/${cid}/${elecId}`}
                                                className="float-right btn btn-primary rvm-v1-part-2-info-btn center-all"
                                              >
                                                View Certificate
                                              </a>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

          <div style={{ backgroundColor: "#f2f6f9" }}>
            {brand && brand.poweredByEnabled && (
              <div className="powered-by-imarticus">
                {brand.hyperlinkEnabled ? (
                  <a
                    href="https://www.imarticus.org/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Powered By Imarticus Learning
                  </a>
                ) : (
                  <p>Powered By Imarticus Learning</p>
                )}
              </div>
            )}
          </div>
          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            feedbackOpen && <div id="feedbacks"></div>}
          <div>
            {feedbackLoaded &&
              feedback &&
              feedback.length > 0 &&
              feedbackOpen &&
              !feedbackQuizSubmissions[currentFeedbackQuiz._id] && (
                <div id="feedbacksContainer">
                  {feedback[lastFeedbackIndex].dism && (
                    <img
                      src="https://cdn.pegasus.imarticus.org/feedback/close.svg"
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "1rem",
                        width: "1rem",
                        stroke: "black",
                        cursor: "pointer",
                      }}
                      // onClick={() => {
                      //   setFeedbackOpen(false);

                      // }}
                      onClick={() => {
                        nextFeedbackWithoutSubmitting();
                      }}
                    />
                  )}
                  <div className="header">
                    <div className="fdbk-top-header">
                      <div
                        style={{
                          margin: "18px",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            // width: "100px",
                            height: "100px",
                            width: "100px",
                            color: "white",
                          }}
                          src={
                            "https://cdn.pegasus.imarticus.org/feedback/fdbk_header.png"
                          }
                          alt="png"
                          //   style={{ width: 64, height: 64 }}
                        />
                      </div>
                      <div
                        style={{
                          margin: "2px",
                          color: "#FFFFFF",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <div style={{ marginTop: "30px" }}>
                          {" "}
                          <p
                            // style={{
                            //   fontSize: "24px",
                            //   fontWeight: 700,
                            //   margin: 0,
                            // }}
                            className="fdbk-header"
                          >
                            {feedback[lastFeedbackIndex].is_surveyfdbk
                              ? "We’d welcome your feedback!"
                              : "How was your learning experience?"}
                          </p>
                        </div>
                        <div>
                          {" "}
                          <p className="fdbk-subheader">
                            {feedback[lastFeedbackIndex].is_surveyfdbk
                              ? "Share your feedback to help us enhance your learning journey!"
                              : "Share your feedback to help us enhance your chapter journey!"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <p className="moduleLabel">
                    {levelsArr[levelsArr.length - 1]}{" "}
                    {feedback[lastFeedbackIndex].chap.seq} /{" "}
                    {course.chapters.length} : {getModuleName()}
                  </p> */}
                  <p
                    className="moduleLabel"
                    styles={{
                      color: "#303030",
                      fontSize: "14px",
                      fontStyle: "italic",
                      fontWeight: 600,
                      lineHeight: "140%",
                    }}
                  >
                    <span
                      style={{
                        color: "red",
                        fontSize: "18px",
                      }}
                    >
                      *
                    </span>
                    fields are required for submission.
                  </p>

                  <div
                    className="sectionBody "
                    style={{
                      maxHeight: "calc(100vh - 48px - 170px)",
                      overflow: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="feedbackQuestions">
                      {feedbackSections &&
                        feedbackSections.map((section, sectionIndex) => (
                          <div key={sectionIndex}>
                            <p className="sectionLabel">
                              {/* {sectionIndex + 1 + ". "}{" "} */}
                              {ReactHtmlParser(section.name)}
                            </p>
                            {section.ques &&
                              section.ques.map((ques, quesIndex) => (
                                <div
                                  key={quesIndex}
                                  style={{ marginTop: "24px" }}
                                >
                                  <p className="quesLabel">
                                    {quesIndex + 1 + ". "}
                                    {ReactHtmlParser(ques.question.text)}
                                    {ques.question.compulsory ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "18px",
                                        }}
                                      >
                                        *
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  {ques.question.type === 3 && (
                                    <div
                                      // style={{ width: "85.3%" }}
                                      className="question-width"
                                    >
                                      <textarea
                                        // style={{ margin: "0 8%", width: "84%" }}
                                        className="subjective-ques"
                                        placeholder="Type your answer here"
                                        value={
                                          feedbackAnswerMap[ques.question._id]
                                        }
                                        onChange={(e) =>
                                          handleSubjective(e, ques.question)
                                        }
                                      ></textarea>
                                      <br />
                                      <br />
                                    </div>
                                  )}
                                  {ques.question.type === 6 && (
                                    <div className="question-width">
                                      <select
                                        onChange={(e) =>
                                          handleSubjective(e, ques.question, 0)
                                        }
                                        className="dync-dropdwn"
                                        // style={{
                                        //   fontSize: "18px",
                                        //   margin: "12px 8%",
                                        //   width: "490px",
                                        //   border: "0.001rem solid black",
                                        //   height: "36px",
                                        //   backgroundColor: "transparent",
                                        // }}
                                      >
                                        <option value="" disabled selected>
                                          Please select an option
                                        </option>
                                        {ques.question.dropdown &&
                                          ques.question.dropdown.map((opt) => {
                                            return (
                                              <option value={opt.aid}>
                                                {opt.nam}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {/* <div className="borderbtm" style={{ margin: "0 8%" }}></div> */}
                                    </div>
                                  )}
                                  {ques.question.type === 1 && (
                                    <div style={{ margin: "0 8%" }}>
                                      <form>
                                        {ques.question.ans_options &&
                                          ques.question.ans_options.map(
                                            (ans, ansIndex) => (
                                              <div
                                                key={ansIndex}
                                                className="answers"
                                              >
                                                <input
                                                  type="radio"
                                                  name={"answer" + ques._id}
                                                  onClick={() =>
                                                    handleSelectOption(
                                                      ques.question,
                                                      ans,
                                                      ansIndex
                                                    )
                                                  }
                                                />
                                                <label>{ans.text}</label>
                                              </div>
                                            )
                                          )}
                                        <br />
                                      </form>
                                      {/* <div className="borderbtm" style={{ margin: "0 8%" }}></div> */}
                                    </div>
                                  )}
                                  {ques.question.type === 4 && (
                                    <div className="rating-ques">
                                      <form>
                                        {/* {ques.question.ans_options &&
                                          ques.question.ans_options.map(
                                            (ans, ansIndex) => (
                                              <div
                                                key={ansIndex}
                                                className="answers"
                                              >
                                                <input
                                                  type="radio"
                                                  name={"answer" + ques._id}
                                                  onClick={() =>
                                                    handleSelectOption(
                                                      ques.question,
                                                      ans,
                                                      ansIndex
                                                    )
                                                  }
                                                />
                                                <label>
                                                  {ans.opt_txt
                                                    ? ans.opt_txt
                                                    : ans.opt_point}
                                                </label>
                                              </div>
                                            )
                                          )} */}
                                        <div>
                                          {ques.question &&
                                            ques.question.ans_template_name ==
                                              "Numeric rating Scale" && (
                                              <NumericRatingScale
                                                answerSetOptionList={
                                                  ques.question.answer_set_id
                                                    .ansoptions
                                                }
                                                handleSelectOption={
                                                  handleSelectOption
                                                }
                                                ques={ques.question}
                                              />
                                            )}
                                          {ques.question &&
                                            ques.question.ans_template_name ==
                                              "Numeric Scale" && (
                                              <NumericScale
                                                answerSetOptionList={
                                                  ques.question.answer_set_id
                                                    .ansoptions
                                                }
                                                handleSelectOption={
                                                  handleSelectOption
                                                }
                                                ques={ques.question}
                                              />
                                            )}
                                          {ques.question &&
                                            ques.question.ans_template_name ==
                                              "Star rating Scale" && (
                                              <StartRatingScale
                                                answerSetOptionList={
                                                  ques.question.answer_set_id
                                                    .ansoptions
                                                }
                                                handleSelectOption={
                                                  handleSelectOption
                                                }
                                                ques={ques.question}
                                                index={quesIndex}
                                              />
                                            )}
                                          {ques.question &&
                                            ques.question.ans_template_name ==
                                              "Emoji  rating Scale" && (
                                              <EmojiScaleRating
                                                answerSetOptionList={
                                                  ques.question.answer_set_id
                                                    .ansoptions
                                                }
                                                handleSelectOption={
                                                  handleSelectOption
                                                }
                                                ques={ques.question}
                                                index={quesIndex}
                                              />
                                            )}
                                          {ques.question &&
                                            ques.question.ans_template_name ==
                                              "Quality Scale" && (
                                              <QualityScale
                                                answerSetOptionList={
                                                  ques.question.answer_set_id
                                                    .ansoptions
                                                }
                                                handleSelectOption={
                                                  handleSelectOption
                                                }
                                                ques={ques.question}
                                              />
                                            )}
                                        </div>
                                        <br />
                                      </form>
                                      {/* <div className="borderbtm" style={{ margin: "0 8%" }}></div> */}
                                    </div>
                                  )}
                                  {section.ques.length - 1 != quesIndex && (
                                    <>
                                      {ques.question.type === 6 ||
                                      ques.question.type === 1 ? (
                                        <div className="ques-divider"></div>
                                      ) : (
                                        <div
                                          className="ques-divider"
                                          style={{ marginTop: 0 }}
                                        ></div>
                                      )}
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        ))}

                      <div
                        className="submitBar"
                        style={{
                          display: "flex",
                          gap: "12px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="feedback-no"
                          style={{
                            color: "#212A39",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                          }}
                        >
                          Feedbacks - {currentOpenFeedbackIndex}/
                          {pendingfFeedback}
                        </div>

                        <button
                          className="submit"
                          disabled={isDisabled}
                          onClick={submitFeedback}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>

          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            feedbackOpen &&
            feedbackQuizSubmissions[currentFeedbackQuiz._id] && (
              <div
                id="feedbacksContainer2"
                style={{
                  padding: 0,
                  maxWidth: "312px",
                  left: "calc(55vw - 175px)",
                }}
              >
                <div
                  style={{
                    width: "312px",
                    height: "197px",
                    background:
                      "linear-gradient(333deg, #006F7E 0%, #40B8B4 100%)",
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://cdn.pegasus.imarticus.org/feedback/done_learner.svg"
                    alt="success gif"
                    style={{
                      maxWidth: "140px",
                      minHeight: "165px",
                    }}
                  />
                </div>

                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: "28px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#212a39",
                    marginTop: "40px",
                  }}
                >
                  Thank you!
                </h4>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "28px",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  You have successfully submitted your feedback
                </p>
                <div className="feedbackNavigation">
                  <button
                    style={{
                      width: "181px",
                      height: "48px",
                      textAlign: "center",
                      marginBottom: "40px",
                    }}
                    onClick={() => {
                      nextFeedbackWithoutSubmitting();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            feedbackOpen &&
            feedbackError && (
              <div id="feedbacksContainer2">
                <img
                  src="https://cdn.pegasus.imarticus.org/images/close_icon.svg"
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "1rem",
                    width: "1rem",
                    stroke: "black",
                  }}
                  onClick={() => {
                    setFeedbackOpen(false);
                  }}
                />
                <img
                  src="https://cdn.pegasus.imarticus.org/feedback/feedback_img.png"
                  alt="success gif"
                  style={{ maxWidth: "314px", minHeight: "227px" }}
                />
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "28px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  Sorry!
                </h4>
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  Unfortunately, the feedback you requested is no longer
                  available as it has been unpublished.
                </p>
                <div class="feedbackNavigation">
                  <button
                    onClick={() => {
                      setFeedbackOpen(false);
                    }}
                  >
                    Go To Next Feedback
                  </button>
                </div>
              </div>
            )}

          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            showAttempted && (
              <div
                id="feedbacks"
                onClick={() => {
                  setShowAttempted(false);
                }}
              ></div>
            )}
          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            showAttempted && (
              <div id="feedbacksContainer2">
                <img
                  src="https://cdn.pegasus.imarticus.org/images/close_icon.svg"
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "1rem",
                    width: "1rem",
                    stroke: "black",
                  }}
                  onClick={() => {
                    setShowAttempted(false);
                  }}
                  alt=""
                />
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/done.svg"
                  alt="success gif"
                  style={{ maxWidth: "140px", minHeight: "165px" }}
                />
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "28px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  Feedback already given!
                </h4>
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  You’ve already given your feedback for this module, kindly
                  move to next module.
                </p>
              </div>
            )}

          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            showFeedbackLocked && (
              <div
                id="feedbacks"
                onClick={() => {
                  setShowFeedbackLocked(false);
                }}
              ></div>
            )}
          {feedbackLoaded &&
            feedback &&
            feedback.length > 0 &&
            showFeedbackLocked && (
              <div id="feedbacksContainer2" style={{ maxHeight: "380px" }}>
                <img
                  src="https://cdn.pegasus.imarticus.org/images/close_icon.svg"
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "1rem",
                    width: "1rem",
                    stroke: "black",
                  }}
                  onClick={() => {
                    setShowFeedbackLocked(false);
                  }}
                />
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/locked.svg"
                  alt="success gif"
                  style={{ maxWidth: "140px", minHeight: "165px" }}
                />
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "28px",
                    lineHeight: "0",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  Its locked!
                </h4>
                <p
                  style={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    textAlign: "center",
                    color: "#212a39",
                  }}
                >
                  You can give feedback on module only after you complete all
                  the content in the module.
                </p>
              </div>
            )}
        </div>

        <Modal
          isOpen={openProfileModal && !snoozePopup && !nsdcEnabled}
          //   toggle={toggleShowSettingsHandler}
          size={"xl"}
          className="prof-dtl-modal"
          centered
        >
          <ModalBody style={{ paddingBottom: "0px", marginBottom: "48px" }}>
            <div className="prof-remind-header">
              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                alt="cross"
                onClick={() => {
                  changeProfileSnooze();
                  setOpenProfileModal(false);
                }}
              />
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2
                  className="prof-dtl-header"
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Welcome to Pegasus by Imarticus Learning!
                </h2>
              </div>
              <div className="prof-dtl-img ">
                <img
                  src="https://webcdn.imarticus.org/Demo/prof_dtl.svg"
                  alt="cross"
                  // onClick={closeRefer}
                />
              </div>
              <p className="prof-dtl-txt">
                To make the most of your learning experience, please take a
                moment to complete your profile.
              </p>
            </div>
            <div className="btn-section-2">
              <Link
                to={`/dashboard/edit`}
                className="crs-resume-btn-2"
                onClick={() => changeProfileSnooze()}
              >
                Start Creating your Profile &nbsp;
                <img src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"></img>
              </Link>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={nsdcEnabled && !nsdcVerified}
          //   toggle={toggleShowSettingsHandler}
          size={"xl"}
          className="prof-dtl-modal"
          centered
          style={{ maxWidth: "640px" }}
        >
          <ModalBody style={{ padding: "48px" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2
                  className="nsdc-dtl-header"
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Please link your NSDC profile!
                </h2>
              </div>
              <div className="nsdc-logo-img">
                <img
                  src="https://webcdn.imarticus.org/Placement/NSDC-Logo-Vector-cdr1.png"
                  alt="cross"
                  style={{ width: "300px", height: "100px" }}
                  // onClick={closeRefer}
                />
              </div>
              <p className="nsdc-dtl-txt">
                Since you've enrolled for a Job Assured/Assistance Program, it
                is mandatory to create a NSDC profile and link it to Pegasus.
                Please note that access to Pegasus will be restricted until this
                is completed.
              </p>
            </div>
            <div className="row nsdc-btn-gap">
              <div className="btn-section-2">
                <a
                  href="https://www.skillindiadigital.gov.in/home"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nsdc-create-btn"
                >
                  Create NSDC Profile &nbsp;
                  <img
                    src="https://webcdn.imarticus.org/Placement/majesticons_open.svg"
                    alt=""
                  ></img>
                </a>
              </div>
              <div className="btn-section-2">
                <Button
                  className="nsdc-update-btn"
                  onClick={() => {
                    setNsdcEnabled(false);
                    setOpenNSDCModal(true);
                  }}
                >
                  I already have a NSDC Profile &nbsp;
                </Button>
              </div>
            </div>
            <div>
              <p className="nsdc-dtl-txt-2">
                If you don’t know how to create your profile
              </p>
              <div className="btn-section-2">
                <Button
                  className="nsdc-tutorial-btn"
                  onClick={() => {
                    window.open(
                      "https://cdn.pegasus.imarticus.org/pdf/nsdc_profile_guide.pdf",
                      "_blank"
                    );
                  }}
                >
                  Click Here
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={openNSDCModal}
          //   toggle={toggleShowSettingsHandler}
          size={"xl"}
          className="prof-dtl-modal"
          centered
          style={{ maxWidth: "520px" }}
        >
          <ModalBody style={{ padding: "32px" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <h2
                  className="nsdc-dtl-header"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Enter your NSDC profile details here
                </h2>
              </div>
            </div>
            <div>
              <div className="nsdc-profile-link-heading">
                Enter your 10-digit mobile number linked to NSDC:
              </div>
              <div className="nsdc-mobile-box">
                <div className="nsdc-ccod-box">
                  <img src="https://webcdn.imarticus.org/Placement/twemoji_flag-india.svg"></img>
                  <span className="nsdc-ccod-txt">+91</span>
                </div>
                <div className="nsdc-number-box">
                  <Input
                    type="text"
                    name="text"
                    id="name"
                    value={nsdcMobile}
                    onChange={(event) => {
                      const value = event.target.value;
                      console.log({ value });
                      if (value.length <= 10 && /^[0-9]*$/.test(value)) {
                        setNsdcMobile(value);
                      }
                    }}
                    placeholder="X X X X X X X X X X"
                    style={{ width: "98%" }}
                    className={
                      nsdcMobileError != ""
                        ? "nsdc-number-error-box-txt"
                        : "nsdc-number-box-txt"
                    }
                  />
                </div>
              </div>
              {nsdcMobileError != "" && (
                <p className="nsdc-mobile-error">{nsdcMobileError}</p>
              )}
            </div>
            <div>
              <div className="nsdc-profile-link-heading">
                Enter your NSDC profile link:
              </div>
              <div className="nsdc-profile-format-box">
                <p className="nsdc-profile-format-heading">
                  The profile link should be in the following format:
                </p>
                <p className="nsdc-profile-format-txt">
                  https://www.skillindiadigital.gov.in/user/profile/public/detail/...
                </p>
              </div>
              <div className="nsdc-url-box">
                <Input
                  type="text"
                  name="text"
                  id="name"
                  value={nsdcUrl}
                  onChange={(event) => {
                    setNsdcUrl(event.target.value);
                  }}
                  placeholder="https://www.skillindiadigital.gov.in/user/..."
                  style={{ width: "98%" }}
                  className={
                    nsdcUrlError != ""
                      ? "nsdc-number-error-box-txt"
                      : "nsdc-number-box-txt"
                  }
                />
              </div>
              {nsdcUrlError != "" && (
                <p className="nsdc-mobile-error">{nsdcUrlError}.</p>
              )}
            </div>

            <div>
              <div className="nsdc-reminder-box">
                <p className="nsdc-reminder-txt">
                  Please make sure the mobile number you entered here and the
                  link you copied are correct before submitting. If you enter
                  inaccurate information, you may lose access to Pegasus and the
                  course materials.
                </p>
              </div>
              <div className="btn-section-2">
                <Button
                  className="nsdc-submit-btn"
                  onClick={() => {
                    handleNSDCConfirm();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={openConfirmModal}
          //   toggle={toggleShowSettingsHandler}
          size={"xl"}
          className="prof-dtl-modal"
          centered
          style={{ maxWidth: "560px" }}
        >
          <ModalBody style={{ padding: "48px", paddingTop: "60px" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <h2
                  className="nsdc-dtl-header-2"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  ARE YOU SURE YOU WANT TO SUBMIT?
                </h2>
              </div>
              <div className="nsdc-confirm-txt">
                <span className="nsdc-span-txt">
                  Please make sure the mobile number you entered here and the
                  link you copied are correct before submitting. If you enter
                  inaccurate information, you may lose access to Pegasus and the
                  course materials.
                </span>
              </div>
            </div>
            <div>
              <div className="nsdc-profile-link-heading">
                Mobile number linked to your NSDC Profile (should be 10 digits):
              </div>
              <div className="nsdc-mobile-box">
                <div className="nsdc-ccod-box">
                  <img src="https://webcdn.imarticus.org/Placement/twemoji_flag-india.svg"></img>
                  <span className="nsdc-ccod-txt">+91</span>
                </div>
                <div className="nsdc-number-box">
                  <Input
                    type="text"
                    name="text"
                    id="name"
                    value={nsdcMobile}
                    disabled
                    placeholder="X X X X X X X X X X"
                    style={{ width: "98%" }}
                    className="nsdc-number-box-txt"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="nsdc-profile-link-heading">
                Your NSDC profile link:
              </div>
              <div className="nsdc-url-box">
                <Input
                  type="text"
                  name="text"
                  id="name"
                  value={nsdcUrl}
                  disabled
                  placeholder="https://www.skillindiadigital.gov.in/user/..."
                  style={{ width: "98%" }}
                  className="nsdc-number-box-txt"
                />
              </div>
            </div>

            <div className="row nsdc-btn-gap">
              <div className="btn-section-2">
                <Button
                  className="nsdc-sure-btn"
                  onClick={() => {
                    handleNSDCSubmit();
                  }}
                >
                  Yes, I'm sure
                </Button>
              </div>
              <div className="btn-section-2">
                <Button
                  className="nsdc-unsure-btn"
                  onClick={() => {
                    setOpenNSDCModal(true);
                    setOpenConfirmModal(false);
                  }}
                >
                  No, I want to change
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={openSuccessModal}
          //   toggle={toggleShowSettingsHandler}
          size={"xl"}
          className="prof-dtl-modal"
          centered
          style={{ maxWidth: "640px" }}
        >
          <ModalBody style={{ padding: "48px" }}>
            <div>
              <div className="prof-remind-header" style={{ cursor: "pointer" }}>
                <img
                  src="https://webcdn.imarticus.org/Placement/Group926.svg"
                  alt="cross"
                  onClick={() => {
                    setOpenSuccessModal(false);
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2
                  className="nsdc-dtl-header-2"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textTransform: "uppercase",
                  }}
                >
                  Your response has been successfully recorded!
                </h2>
              </div>
              {isPlacementEnabled ? (
                <p className="nsdc-dtl-txt">
                  You can view your entered NSDC details in the profile section
                  later. Meanwhile, you can complete your profile to start
                  applying for jobs when Career Services will be activated.
                </p>
              ) : (
                <p className="nsdc-dtl-txt">
                  You can view your entered NSDC details in your profile section
                  later.
                </p>
              )}
              <div className="nsdc-logo-img">
                <img
                  src="https://webcdn.imarticus.org/Placement/Ljg0Cv.tif_1_1.svg"
                  alt="cross"
                  // onClick={closeRefer}
                />
              </div>
            </div>
            <div className="btn-section-2">
              {isPlacementEnabled ? (
                <Link to={`/dashboard/edit`} className="crs-resume-btn-2">
                  Complete my profile &nbsp;
                  <img
                    src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"
                    alt=""
                  ></img>
                </Link>
              ) : (
                <Link to={`/view?cid=${crsPgId}`} className="crs-resume-btn-2">
                  Go to My Course &nbsp;
                  <img
                    src="https://webcdn.imarticus.org/Placement/ph_arrow-up-bold.svg"
                    alt=""
                  ></img>
                </Link>
              )}
            </div>
          </ModalBody>
        </Modal>

        {blockCrs && !showAgreements && <BlockCourse />}

        {/* {blockPlacement && !showAgreements && (
          <BlockPlacement
            blockPlacement={blockPlacement}
            setBlockPlacement={setBlockPlacement}
          />
        )} */}

        {showAgreements && (
          <Agreement
            name={props.profile.p_name}
            crs_id={cid}
            setShowAgreements={setShowAgreements}
            showAgreements={showAgreements}
          />
        )}
      </div>
    )
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setAttendance,
  getAllNotifications,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Course));

import axios from "axios";
import cookie from "react-cookies";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
import { CONFIGS, MAIL_BODY } from "../constants/constants";
import { BACKEND_ERRORS, BACKEND_ERROR_CODES } from "../constants/errors";
import { utils } from "../utility/utils";
import { renewAccessToken } from "./utilAction";

import { showPopover } from "../actions/utilAction";
import { POPOVER_TYPE } from "../constants/constants";
const cookies = new Cookies();

export const atCrs = ({ pid, gid, cid, levelId, dispatchIndividualBrand }) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.AUTHENTICATE_COURSE, {
        crs_id: cid,
        p_id: pid,
        g_id: gid,
        tokn: cookies.get("at"),
        a_id: cookies.get("aid"),
        cl: "W",
        d_id: window.fingerprint.md5hash,
      })
      .then(async (response) => {
        console.log({ "response.data": response.data });
        let brand;
        if (!window?.location.href.includes("imarticus.org")) {
          let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);

          if (res.data.success) {
            let brandData = res.data?.data?.brand;
            console.log({ brandData });
            if (brandData) brand = brandData;
          }
        } else {
          brand = response.data?.data?.brand;
        }
        console.log({ brand });
        dispatchIndividualBrand(brand);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const levelContent = (pid, gid, cid, levelId) => {
  return new Promise((resolve, reject) => {
    let query = "?";
    let aid = cookies.get("aid");
    if (cid) query += "&cid=" + cid;
    if (levelId) query += "&levelId=" + levelId;
    if (pid) query += "&pid=" + pid;
    if (aid) query += "&aid=" + aid;
    query += "&cl=W";
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .get(ROUTES.LEVELS_DATA + query, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const courseContent = (pid, gid, cid, levelId) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.G_CONTENT_V5,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          levelId: levelId,
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const courseStatus = (pid, gid, cid, levelId) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.G_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const pluginIdentifier = (quiz, gid, batchObj) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.PLUGIN_IDNT, {
        p_id: cookies.get("pid"),
        g_id: gid ? gid : quiz.gid,
        pl_id: batchObj.plugin_id,
        tokn: cookies.get("at"),
        type: 1,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const registerUserQuizIdnt = (quiz, uIdntFeedback, idnt_id) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.REGISTER_USER_QUIZ, {
        user_idnt: uIdntFeedback,
        testId: quiz.tid,
        idnt_id,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const initializeUserQuiz = (
  quiz,
  uIdntFeedback,
  srvId,
  quizUserId,
  feedbackGCode,
  batchObj,
  idnt_id
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.INIT_USER_QUIZ, {
        g_id: feedbackGCode,
        pl_id: batchObj.plugin_id,
        user_id: quizUserId,
        user_idnt: uIdntFeedback,
        srv_id: srvId,
        testId: quiz.tid,
        idnt_id,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const testToken = (
  quiz,
  uIdntFeedback2,
  quizUserId,
  feedbackGCode,
  batchObj,
  idnt_id
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.START_F_USER_QUIZ, {
        g_id: feedbackGCode,
        pl_id: batchObj.plugin_id,
        user_id: quizUserId,
        tokn: uIdntFeedback2,
        test_id: quiz.tid,
        idnt_id,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const testQuestion = (
  quiz,
  feedbackGCode,
  quizToken,
  uIdntFeedback2,
  quizUserId,
  userTestId,
  batchObj,
  idnt_id,
  cid
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.GET_TEST_QUESTION, {
        g_id: feedbackGCode,
        ipp: "200",
        start: 0,
        pl_id: batchObj.plugin_id,
        t_tokn: quizToken,
        test_id: quiz.tid,
        tokn: uIdntFeedback2,
        user_id: quizUserId,
        user_test_id: userTestId,
        idnt_id,
        cid: cid,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const certStatus = (pid, gid, cid, levelId) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.CERTIFICATE_STATUS,
        {
          crs_id: cid,
          a_id: cookies.get("aid"),
          p_id: pid,
          levelId: levelId,
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const downloadCert = (pid, gid, cid, levelId, elec_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.APPLY_CERTIFICATE,
        {
          crs_id: cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
          elec_id: elec_id ? elec_id : undefined,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const impartusUserLogin = (cid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.IMPARTUS_LOGIN,
        {
          a_id: cookies.get("aid"),
          c_id: cid,
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const userLogout = () => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.LOGOUT,
        {
          a_id: cookies.get("aid"),
          d_id: window.fingerprint.md5hash,
          at: cookies.get("at"),
          cl: "W",
          rt: cookies.get("rt"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const zoomSignature = (liv_lec_id, pid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.ZOOM_SIGNATURE_V3,
        {
          liv_lec_id: liv_lec_id,
          // crs_id : $stateParams.cid,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const placementRedirect = (cid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-token"] = cookies.get("at");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_REDIRECT,
        {
          c_id: cid,
          a_id: cookies.get("aid"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const chapStatus = (pid, cid, chapter) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.CHAPTER_STATUS,
        {
          crs_id: cid,
          cp_id: chapter._id,
          p_id: pid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const endUserQuiz = (
  answers,
  feedbackGCode,
  batchObj,
  quizToken,
  feedbackQuiz,
  uIdntFeedback2,
  quizUserId,
  userTestId,
  idnt_id
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.END_V2, {
        answers: answers,
        g_id: feedbackGCode,
        pl_id: batchObj.plugin_id,
        t_tokn: quizToken,
        test_id: feedbackQuiz.tid,
        tokn: uIdntFeedback2,
        user_id: quizUserId,
        user_test_id: userTestId,
        idnt_id,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const feedbackUserStatus = (
  quiz,
  cid,
  chapter,
  quizUserId,
  userTestId
) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    axios
      .post(
        ROUTES.FEEDBACK_STATUS,
        {
          aid: cookies.get("aid"),
          pid: cookies.get("pid"),
          feedbackId: quiz._id,
          cid: cid,
          chpId: chapter._id,
          user_id: quizUserId,
          user_test_id: userTestId,
          cl: "W",
          is_survey_fdbk: !chapter._id ? true : false,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

import { useState, useEffect, useReducer, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import SidebarContainer from "../sidebars/SidebarContainer";
import { CONFIGS, TABS } from "../../constants/constants.js";
import { Pagination } from "semantic-ui-react";
import { atCrs, brandDataCourse } from "../../actions/gradebookAction.js";
import {
  setSelectedCourse,
  setAttendance,
  authenticateCourse,
  getParam,
} from "../../actions/courseAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import {
  placementProfileData,
  placementJobData,
  changeSaveStatus,
  addLearnerRemarks,
  getProfileInfoOnly,
} from "../../actions/profileAction.js";
import CourseHeader from "../headers/CourseHeader.jsx";
import "./attendance.scss";
import Axios from "axios";
import { ROUTES } from "../../constants/routes.js";
import Loader from "../utils/Loader.js";
import { resetLoadingData, setCurrentTab } from "../../actions/utilAction.js";

const cookies = new Cookies();

function useDebounce(cb, delay) {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

const Attendance = (props) => {
  console.log("This is my props: ", props);
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const [cid, setCid] = useState(query[6]);
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  console.log("This is my cid ::: ", { query, cid });

  const [brand, setBrand] = useState();
  const [selfPaced, setSelfPaced] = useState();
  const [joining, setJoining] = useState();
  const [uid, setUid] = useState();
  const [courseName, setCourseName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [impartusEnabled, setImpartusEnabled] = useState();
  const [batchName, setBatchName] = useState();
  const [courseLocation, setCourseLocation] = useState();
  const [dp, setDp] = useState();
  const [userName, setUserName] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();

  const [oppShared, setOppShared] = useState(0);
  const [showWeightage, setShowWeightage] = useState(false);
  const [showPassMarks, setShowPassMarks] = useState(false);
  const [showPlcStatus, setShowPlcStatus] = useState(false);
  const [placementEnable, setPlacementEnable] = useState(false);
  const [gradebookType, setGradebookType] = useState(1);
  const [breadCrumbsTemp, setBreadCrumbsTemp] = useState([]);
  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    "Congratulations! You are eligible for Placement Criteria"
  );
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    "You are not eligible for the Placement.  "
  );
  const [plcPassStatusText, setPlcPassStatusText] = useState("PASS");
  const [plcFailStatusText, setPlcFailStatusText] = useState("FAIL");
  const [showContentStatus, setShowContentStatus] = useState(false);

  const [metrics, setMetrics] = useState({});
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    type: "",
  });
  const [searchVal, setSearchVal] = useState("");
  const [debounceVal, setDebounceVal] = useState("");
  const debounceValue = useDebounce(searchVal, 1500);
  let ROWS_PER_PAGE = 10;

  useEffect(() => {
    (async () => {
      await authenticatCourse();
    })();
  }, [cid]);
  useEffect(() => {
    console.log("\n\n\n\n\n\n In main use effect\n");
    setCurrentTab(TABS.ATTENDANCE);
    (async () => {
      if (!cid) {
        await authenticatCourse();
      }
      await props.getProfileInfoOnly();
      await fetchBrandDataCrs();
      setDp(props.ppic);
      setUserName(props.profileName);
      setPrimaryEmail(props.primaryEmail);
      await getAttendanceDataAndMetricsForLearner(props?.selected_course?._id);
      resetLoadingData();
      // await getAttendanceDataForLearner(props?.selected_course?._id)
    })();
  }, []);

  const authenticatCourse = async () => {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    console.log("Calling atCrs!");
    atCrs({ pid, gid, cid })
      .then(function (res) {
        console.log("atCrs res :: ", res);
        var expirydate = new Date();
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("crs_tkn", res.data.data.at, {
          expires: expirydate,
          domain: CONFIGS.DOMAIN,
          path: "/",
        });
        console.log({ dataData: res.data.data });
        if (res.data.data.joined) {
          setJoining(new Date(res.data.data.joined));
        }
        if (res.data.data.uid) {
          setUid(res.data.data.uid);
        }
        console.log("This is my cid course", res.data.data.course.name);
        if (res.data.data.course.name) {
          setCourseName(res.data.data.course.name);
          let cname = res.data.data.course.name;
          setBreadCrumbsTemp([
            { cname, link: "/view/?cid=" + getParam("cid") },
          ]);
          console.log({ cname: res.data.data.name });
          console.log({ breadCrumbsTemp });
        }
        if (res.data.data && res.data.data.phoneno) {
          setPhoneNo(res.data.data.phoneno);
        }
        if (res.data.data.selfPaced) {
          setSelfPaced(res.data.data.selfPaced);
        }
        setImpartusEnabled(res.data.data.course.impartus_enabled);
        setShowPassMarks(res.data.data.course.show_pass_marks);
        setShowWeightage(res.data.data.course.show_weightage);
        if ("show_content_status" in res.data.data.course) {
          setShowContentStatus(res.data.data.course["show_content_status"]);
        }

        let passStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);

        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        let grdtype = res.data.data.course.gradebook_type
          ? res.data.data.course.gradebook_type
          : 1;
        setGradebookType(grdtype);
        setPlacementEnable(res.data.data.course.plcmnt_enable);

        let showplc = res.data.data.course.plac_type
          ? res.data.data.course.plac_type.gradebook_show_plcmnt_stts
          : false;
        setShowPlcStatus(showplc);
        setBatchName(res.data.data.course.btch_name);
        setCourseLocation(res.data.data.location);

        let courseFound = res.data.data.course;
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
        props.setAttendance(res.data.data.showAttendance);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const fetchBrandDataCrs = async () => {
    brandDataCourse(cid)
      .then((response) => {
        var brand = response.data.data.brand;
        if (brand) {
          setBrand(brand);
          props.dispatchIndividualBrand(brand);
          if (
            !cookies.get("loaderColor") ||
            cookies.get("loaderColor") != brand.loaderColorIndex
          ) {
            if (cookies.get("loaderColor") != brand.loaderColorIndex) {
              cookies.remove("loaderColor", {
                path: "/",
                domain: CONFIGS.DOMAIN,
              });
            }
            var expirydate = new Date();
            expirydate.setDate(expirydate.getDate() + 180);
            cookies.set("loaderColor", brand.loaderColorIndex, {
              expires: expirydate,
              domain: CONFIGS.DOMAIN,
              path: "/",
            });
          }
        } else {
          cookies.remove("loaderColor", {
            path: "/",
            domain: CONFIGS.DOMAIN,
          });
        }
      })
      .catch((err) => {
        props.dispatchIndividualBrand();
        console.log({ err });
      });
  };

  const getAttendanceDataAndMetricsForLearner = async () => {
    setLoading(true);
    console.log("This is getAttendanceMetricsForLearner");
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    console.log(
      "\n\n\n\n\n props.selected_course ",
      props.selected_course,
      " \n\n\n\n"
    );
    try {
      let response = await Axios.post(
        ROUTES.GET_ATTENDANCE_METRICS,
        { aid: cookies.get("aid"), cid: cid },
        configs
      );
      if (response.data.success) {
        console.log("Successfull!", response.data.data);
        setMetrics({
          totalConducted: response.data.data.totalConducted,
          totalLiveLectures: response.data.data.totalLiveLectures,
          totalPresent: response.data.data.totalPresent,
          totalAbsent: response.data.data.totalAbsent,
        });
        setData(response.data.data.finalArray);
        setTotalPages(Math.ceil(response.totalConducted / ROWS_PER_PAGE));
      } else {
        console.log("UnSuccessful!");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const getAttendanceDataForLearner = async (toSearch) => {
    setLoading(true);
    console.log("This is getAttendanceDataForLearner");
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    console.log(
      "\n\n\n\n\n props.selected_course ",
      props.selected_course,
      " \n\n\n\n"
    );
    try {
      let response = await Axios.post(
        ROUTES.GET_ATTENDANCE_DATA,
        {
          aid: cookies.get("aid"),
          cid: cid,
          type: filters.type,
          searched: toSearch,
        },
        configs
      );
      if (response.data.success) {
        console.log("\n\n\n\n\n\n\n api response: ", {
          response,
          finalArray: response.data.data.finalArray,
        });
        setData(response.data.data.finalArray);
        // setTotalPages(response.data.data.totalPages);
        console.log("Successfull!");
      } else {
        console.log("UnSuccessful!");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const clickOnPageHandler = (e, data) => {
    console.log("clickOnPageHandler: ", { data });
    setCurrentPage(data.activePage);
  };

  useEffect(() => {
    if (filters.type !== "") {
      (async () => {
        await getAttendanceDataForLearner();
      })();
    }
    if (filters.type === "") {
      (async () => {
        await getAttendanceDataAndMetricsForLearner();
      })();
    }
  }, [filters.type]);

  // Search variables
  useEffect(() => {
    console.log("\n\n\nDebounced:", { debounceVal, searchVal });
    let toSearch = searchVal;
    // if(filteredBatch){
    if (debounceVal !== "" && toSearch == "") {
      console.log("toSearch :: ", toSearch);
      toSearch = "empty";
    }
    console.log({ toSearch });
    if (toSearch.trim() !== "") {
      console.log("\n\n =============== Calling search API!");
      const fetchData = async () => {
        await getAttendanceDataForLearner(toSearch);
      };
      fetchData();
    }
    // }
    setDebounceVal(searchVal);
  }, [debounceValue]);

  const handleChange = (e) => {
    setSearchVal(e.target.value);
  };
  console.log({ debounceVal });

  const handleFilters = (e) => {
    setFilters({
      [e.target.name]: e.target.value,
    });
  };
  console.log("ON RENDER DATA :: ", { data, loading });
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="course-page gradebookPage">
        <CourseHeader
          breadCrumbs={[{ name: courseName, link: `/view?cid=${cid}` }]}
          individualBrand={""}
        />
        <SidebarContainer crs_pg_id={props.selected_course.crs_pg_id} />
        <div
          className="col-xs-12 rvm-v1"
          id="content"
          style={{ marginTop: "55px" }}
        >
          <div className="breadCrumbsContainer">
            <div className="rvm-v1-header-brdsc-ctnr breadCrumbs-row">
              <div className="breadCrumbs">
                <a href="/dashboard">
                  <span>My Courses</span>
                </a>
                <i
                  style={{ fontSize: "small", marginLeft: "10px" }}
                  className="fa fa-chevron-right"
                ></i>
                <a href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}view/?cid=${cid}`}>
                  {courseName}
                </a>
                <i
                  style={{
                    fontSize: "small",
                    marginLeft: "10px",
                  }}
                  className="fa fa-chevron-right"
                ></i>
                <a href={""} className="rvm-v1-header-brdsc-active">
                  <span>Attendance</span>
                </a>
              </div>
            </div>
          </div>
          <div className="myMainDiv attendanceContainer">
            <div className="metricsDiv">
              <div className="lectureMetricsCard">
                <p className="lectureMetricsP">Total Lecture Conducted</p>
                {/* <h3>{data ? (data["totPublishedCount"] || data["totPublishedcount"] || "0") : "0"}</h3> */}
                <h3>
                  {metrics.totalConducted && metrics.totalLiveLectures
                    ? `${metrics.totalConducted}/${metrics.totalLiveLectures}`
                    : "0/0"}
                </h3>
              </div>

              <div className="lectureMetricsCard">
                <p className="lectureMetricsP">Total Present</p>
                {/* <h3>{data ? (data["totPublishedCount"] || data["totPublishedcount"] || "0") : "0"}</h3> */}
                <h3>{metrics.totalPresent || 0}%</h3>
              </div>

              <div className="lectureMetricsCard">
                <p className="lectureMetricsP">Total Absent</p>
                {/* <h3>{data ? (data["totPublishedCount"] || data["totPublishedcount"] || "0") : "0"}</h3> */}
                <h3>{metrics.totalAbsent || 0}%</h3>
              </div>
            </div>
            <br />
            <br />
            <div className="dataDiv">
              <h4 style={{ fontWeight: "bolder" }}>My Attendance</h4>
              <br />
              <div className="containerDiv">
                <div className="row FirstDiv">
                  <div className="col-md-5 col-sm-12">
                    <input
                      value={searchVal}
                      onChange={handleChange}
                      type="text"
                      placeholder="Search Chapter/Content"
                      className="searchInput"
                    />
                  </div>
                  <div className="col-4"></div>
                  <div className="col-md-3 col-sm-12">
                    <select
                      value={filters.type}
                      name="type"
                      onChange={handleFilters}
                      className="selectInput"
                    >
                      <option value="">All</option>
                      <option value="not">Not Marked</option>
                      <option value="present">Present</option>
                      <option value="absent">Absent</option>
                    </select>
                  </div>
                </div>
                {data.length > 0 && (
                  <>
                    <div
                      className="SecondDiv"
                      style={{ height: "400px", overflowY: "auto" }}
                    >
                      <table className="myTable" style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Chapter</th>
                            <th>Content</th>
                            <th>Date/Time</th>
                            <th>Attendance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((d, idx) => (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>
                                {d.chapter.length > 50
                                  ? d.chapter.substring(0, 50) + "..."
                                  : d.chapter}
                              </td>
                              <td>
                                {d.content.length > 50
                                  ? d.content.substring(0, 50) + "..."
                                  : d.content}
                              </td>
                              <td>{d.startTime}</td>
                              <td
                                className={
                                  d.attendance === "Present"
                                    ? "present"
                                    : d.attendance === "Absent"
                                    ? "absent"
                                    : "notMarked"
                                }
                              >
                                {d.attendance}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <PaginationMaker
                      currentPage={currentPage}
                      clickOnPageHandler={clickOnPageHandler}
                      totalPages={totalPages}
                    /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
});

const mapDispatchToProps = {
  dispatchIndividualBrand,
  getProfileInfoOnly,
  setSelectedCourse,
  setAttendance,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attendance)
);

const PaginationMaker = ({
  currentPage = 1,
  clickOnPageHandler,
  totalPages = 5,
}) => {
  console.log("In Pagination :: ", {
    currentPage,
    clickOnPageHandler,
    totalPages,
  });
  return (
    <Pagination
      onPageChange={(e, data) => clickOnPageHandler(e, data)}
      defaultActivePage={0}
      totalPages={totalPages}
      activePage={currentPage}
    />
  );
};

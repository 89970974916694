import React, { useEffect, useState } from "react";
import SkillTagTooltipItem from "../utils/SkillTagToolTip";

// "https://cdn.pegasus.imarticus.org/feedback/trans_parent_star.svg"
// "https://cdn.pegasus.imarticus.org/feedback/yellow.svg"

export function StartRatingScale(props) {
  const [rating, setRating] = useState(0);
  const [answerIndex, setAnswerIndex] = useState(-1);
  const [ratingLength, setRatingLength] = useState(5);
  const [answerSetOptionList, setAnswerSetOptionList] = useState([]);

  useEffect(() => {
    if (props && props.answerSetOptionList) {
      setRatingLength(props.answerSetOptionList.length);
      setAnswerSetOptionList([...props.answerSetOptionList]);
    }
  }, []);
  let handleSelectRating = (index) => {
    setAnswerIndex(index);
    props.handleSelectOption(props.ques, index, index);
  };
  const star_rating_length = Array.from({ length: ratingLength });
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      {star_rating_length.map((_, index) => {
        return (
          <div
            style={{
              borderBottom: index <= answerIndex ? "4px solid #035642" : "none",
              margin: "2px",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "space-around",
              minWidth: "60px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%", // Adjust as needed
                width: "100%", // Adjust as needed
              }}
            >
              <img
                style={{
                  maxWidth: "100%", // Ensure responsiveness
                  height: "auto", // Maintain aspect ratio
                  cursor: "pointer",
                }}
                onClick={() => handleSelectRating(index)}
                src={
                  index > answerIndex
                    ? "https://cdn.pegasus.imarticus.org/feedback/white-transparent-star.png"
                    : "https://cdn.pegasus.imarticus.org/feedback/yellow.svg"
                }
                alt="png"
                className="rating-img"
              />
            </div>
            <br></br>
            <div>
              <p
                style={{
                  textAlign: "center",
                  color: index <= answerIndex ? "#035642" : "black",
                  fontSize: "14px",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => handleSelectRating(index)}
              >
                {answerSetOptionList[index]?.opt_txt ? (
                  answerSetOptionList[index].opt_txt.length > 10 ? (
                    <SkillTagTooltipItem
                      id={"skillnamr1" + index + props.index}
                      text={
                        answerSetOptionList[index]?.opt_txt
                          ? answerSetOptionList[index].opt_txt.substring(0, 7)
                          : "-"
                      }
                      batch={
                        answerSetOptionList[index]
                          ? answerSetOptionList[index].opt_txt
                          : "-"
                      }
                      contentFeedback={props.contentFeedback}
                    />
                  ) : (
                    answerSetOptionList[index].opt_txt
                  )
                ) : (
                  "-"
                )}{" "}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
